import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SharedModule, FormInputComponent } from '@vendure/admin-ui/core';
import { DefaultFormComponentConfig } from '@vendure/common/lib/shared-types';


@Component({
  template: `
    <vdr-affixed-input
        [suffix]="suffix"
        [prefix]="prefix"
    >
        <input type="text" [readonly]="readonly" [formControl]="formControl" />
    </vdr-affixed-input>

  `,
  standalone: true,
  imports: [SharedModule],
  styles: [`
  input {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 220px;
  }
  `]
})
export class FulfillmentTextFormInputComponent implements FormInputComponent {
  readonly: boolean;
  config: DefaultFormComponentConfig<'fulfillment-text-form-input'>;
  formControl: UntypedFormControl;

  get prefix() {
    return this.config.ui?.prefix || this.config.prefix;
  }

  get suffix() {
      return this.config.ui?.suffix || this.config.suffix;
  }
}
