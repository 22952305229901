import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

// Define the interface for navigation menu options
interface NavMenuOption {
  type: string;
  label: string;
  url: string;
}

@Component({
  selector: 'nav-menu-option-input',
  template: `
    <vdr-card>
      <div class="form-row">
        <!-- Navigation Menu Type Dropdown -->
        <vdr-form-field [label]="'Navigation Menu Type'" class="form-item">
          <select [formControl]="typeControl">
            <option value="" disabled selected>Select a type</option>
            <option *ngIf="type!=='product'" value="home-page">Home Page</option>
            <option *ngIf="type!=='product'" value="shopping-list">Shopping Page</option>
            <option *ngIf="type!=='product'" value="about-page">About Page</option>
            <option *ngIf="type!=='product'" value="faq-page">FAQ Page</option>
            <option *ngIf="type!=='product'" value="contact-page">Contact Page</option>
            <option value="other-page">Other Page</option>
            <option *ngIf="type==='product'" value="page-section">Section</option>
          </select>
        </vdr-form-field>

        <!-- Label Input -->
        <vdr-form-field [label]="'Label'" class="form-item">
          <input type="text" [formControl]="labelControl">
        </vdr-form-field>

        <!-- Conditional URL Input for 'Other Page' -->
        <div *ngIf="typeControl.value === 'other-page' || typeControl.value === 'page-section'" class="form-item">
          <vdr-form-field [label]="typeControl.value === 'page-section' ? 'Page Section' : 'Other Page Url'">
            <input type="text" [formControl]="urlControl">
          </vdr-form-field>
        </div>
      </div>
    </vdr-card>
  `,
  styleUrls: ['../styles.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class NavMenuOptionInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  navMenuOption: NavMenuOption | null;
  type: string;

  // Form controls
  typeControl = new FormControl();
  labelControl = new FormControl();
  urlControl = new FormControl();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.type = this.config.ui?.options?.type || '';
    const defaultNavMenuOption: NavMenuOption = {
      type: '',
      label: '',
      url: '',
    };
    this.navMenuOption = safeJSONParse<NavMenuOption>(
      this.formControl.value,
      this.notificationService,
      defaultNavMenuOption,
    );
  }

  ngAfterViewInit() {
    // Subscribe to form control value changes and update navMenuOption accordingly
    this.initializeFormControls();
    this.typeControl.valueChanges.subscribe(this.onTypeChange);
    this.labelControl.valueChanges.subscribe(this.onLabelChange);
    this.urlControl.valueChanges.subscribe(this.onUrlChange);
  }

  private initializeFormControls() {
    // Set form control values from the navMenuOption
    this.typeControl.setValue(this.navMenuOption?.type);
    this.labelControl.setValue(this.navMenuOption?.label);
    this.urlControl.setValue(this.navMenuOption?.url);
  }

  // Handlers for form control value changes
  private onTypeChange = (value: string) => {
    if (this.navMenuOption?.type !== value) {
      this.navMenuOption!.type = value;
      this.updateJsonString();
    }
  };

  private onLabelChange = (value: string) => {
    if (this.navMenuOption?.label !== value) {
      this.navMenuOption!.label = value;
      this.updateJsonString();
    }
  };

  private onUrlChange = (value: string) => {
    if (this.navMenuOption?.url !== value) {
      this.navMenuOption!.url = value;
      this.updateJsonString();
    }
  };

  // Update the JSON string and form control value
  private updateJsonString() {
    const jsonString = JSON.stringify(this.navMenuOption);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
