import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    SharedModule,
    StringCustomFieldConfig,
    FormInputComponent,
    DynamicFormInputComponent,
    NotificationService
} from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

interface NavigationItem {
    label: string;
    type: string;
    policyName?: string;
    url: string;
}

enum NavigationItemType {
    POLICY = 'policy',
    ORDER_STATUS = 'order-status',
    CONTACT_US = 'contact-us',
    PAGE = 'page',
    WEBSITE = 'website',
}

interface NavigationColumn {
    name: string;
    items: NavigationItem[];
}

@Component({
    selector: 'vdr-navigation-column-input',
    template: `
    <vdr-card>
        <vdr-form-field [label]="'Column Name'">
            <input type="text" [formControl]="nameControl" />
        </vdr-form-field>

        <vdr-form-field [label]="'Navigation Items'">
            <vdr-dynamic-form-input
                #itemsInput
                [control]="itemsControl"
                [def]="{
                    list: true,
                    type: 'string',
                    ui: {
                        component: 'navigation-item-input'
                    }
                }"
            >
            </vdr-dynamic-form-input>
        </vdr-form-field>
    </vdr-card>
    `,
    standalone: true,
    imports: [SharedModule],
})
export class NavigationColumnInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
    @ViewChild('itemsInput') itemsInput: DynamicFormInputComponent;

    readonly: boolean;
    config: StringCustomFieldConfig;
    formControl: FormControl;

    nameControl = new FormControl();
    itemsControl = new FormControl();

    navigationColumnData: NavigationColumn | null;

    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.navigationColumnData = safeJSONParse<NavigationColumn>(
            this.formControl.value,
            this.notificationService,
            { name: '', items: [] }
        );

        this.initializeFormControls();
    }

    ngAfterViewInit(): void {
        if (this.itemsInput) {
            this.itemsInput.registerOnChange(this.onItemsChange);
            const navigationItems = this.navigationColumnData?.items?.map((value) => JSON.stringify(value)) || [];
            this.itemsInput.writeValue(navigationItems);
        }
        this.nameControl.valueChanges.subscribe(this.onNameChange);
    }

    private initializeFormControls() {
        this.nameControl.setValue(this.navigationColumnData?.name || '');
        const itemsJsonArray = this.navigationColumnData?.items?.map(item => JSON.stringify(item), []);
        this.itemsControl.setValue(itemsJsonArray);
    }

    private onNameChange = (value: string | null) => {
        if (this.navigationColumnData && this.navigationColumnData.name !== value) {
            this.navigationColumnData.name = value || '';
            this.updateJsonString();
        }
    };

    private onItemsChange = (items: string[]) => {
        if (this.navigationColumnData && JSON.stringify(this.navigationColumnData.items) !== JSON.stringify(items)) {
            const parsedItems = items?.map(value => safeJSONParse<NavigationItem>(value, this.notificationService, {
                label: '',
                type: NavigationItemType.PAGE,
                policyName: '',
                url: ''
            })) || [];
            this.navigationColumnData.items = parsedItems as any;
            this.updateJsonString();
        }
    };

    private updateJsonString() {
        const jsonString = JSON.stringify(this.navigationColumnData);
        this.formControl.setValue(jsonString);
        this.formControl.markAsDirty();
    }
}
