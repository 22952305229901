import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild, ElementRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
    NotificationService,
    ModalService,
    DataService,
    TagFragment,
    BaseListComponent,
    GetAssetListQuery,
    GetAssetListQueryVariables,
    ItemOf,
    Asset,
    DeletionResult,
    SortOrder,
    LogicalOperator
} from '@vendure/admin-ui/core';
import { AddVimeoVideoComponent } from '../add-vimeo-video/add-vimeo-video.component';
import { SharedModule } from '@vendure/admin-ui/core';
import { VIMEO_UPLOAD_VIDEO , DELETE_ASSETS} from '../../ui-types';
import { Mutation, MutationVimeoUploadArgs , MutationDeleteAssetsArgs} from '../../../common/ui/generated-types';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { PaginationInstance } from 'ngx-pagination';

const VIMEO_MIMETYPE = 'video/vimeo';

@Component({
    selector: 'add-video-tab',
    templateUrl: './add-video-tab.component.html',
    styleUrls: ['./add-video-tab.component.scss'],
    standalone: true,
    imports: [SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVideoTabComponent extends BaseListComponent<
    GetAssetListQuery,
    ItemOf<GetAssetListQuery, 'assets'>,
    GetAssetListQueryVariables
>
    implements OnInit {
    @ViewChild('fileInput') fileInput: ElementRef;

    searchTerm$ = new BehaviorSubject<string | undefined>(undefined);
    filterByTags$ = new BehaviorSubject<TagFragment[] | undefined>(undefined);
    uploading = false;
    allTags$: Observable<TagFragment[]>;
    paginationConfig$: Observable<PaginationInstance>;

    constructor(
        private notificationService: NotificationService,
        private modalService: ModalService,
        private dataService: DataService,
        router: Router,
        route: ActivatedRoute,
    ) {
        super(router, route);
        super.setQueryFn(
            (...args: any[]) => this.dataService.product.getAssetList(...args).refetchOnChannelChange(),
            data => data.assets,
            (skip, take) => {
                const searchTerm = this.searchTerm$.value;
                const tags = this.filterByTags$.value?.map(t => t.value);
                let filters: any = {
                    mimeType: { eq: VIMEO_MIMETYPE }
                };
                if(searchTerm){
                    filters.name = { contains: searchTerm };
                }
                return {
                    options: {
                        skip,
                        take,
                        filter: filters,
                        sort: {
                            createdAt: SortOrder.DESC,
                        },
                        tags,
                        tagsOperator: LogicalOperator.AND,
                    },
                };
            },
            { take: 25, skip: 0 },
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.paginationConfig$ = combineLatest(this.itemsPerPage$, this.currentPage$, this.totalItems$).pipe(
            map(([itemsPerPage, currentPage, totalItems]) => ({ itemsPerPage, currentPage, totalItems })),
        );
        this.searchTerm$.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(() => this.refresh());

        this.filterByTags$.pipe(takeUntil(this.destroy$)).subscribe(() => this.refresh());
        this.allTags$ = this.dataService.product.getTagList().mapStream(data => data.tags.items);
    }

    uploadVideo(file: File) {
        if (file) {
            // Implement video upload logic here using this.selectedFile
            console.log('Uploading file:', file.name);
            this.dataService.mutate<
                Mutation,
                MutationVimeoUploadArgs
            >(
                VIMEO_UPLOAD_VIDEO, { file }
            ).subscribe({
                next: (response: Mutation) => {
                    console.log('Response:', response.vimeoUpload);
                    if (response.vimeoUpload.error) {
                        this.notificationService.error(response.vimeoUpload.error);
                    } else {
                        this.notificationService.success('Video uploaded successfully');
                    }
                },
                error: (error: any) => {
                    console.error('Error during file upload:', error);
                    this.notificationService.error(`Error during file upload ${error.message}`);
                },
                complete: () => {
                    this.refresh();
                    console.log('File upload complete');
                },
            });
            this.fileInput.nativeElement.value = '';
        } else {
            this.notificationService.error('Please select a video file first');
        }
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.uploadVideo(input.files[0]);
        }
    }

    addVimeoVideo() {
        this.modalService
            .fromComponent(AddVimeoVideoComponent)
            .subscribe(result => {
                if (result) {
                    this.refresh();
                    this.onVimeoVideoSave(result);
                }
            });
    }

    onVimeoVideoSave(videoData: { title: string; shareLink: string; }) {
        // Implement Vimeo video addition logic here
        console.log('Saving Vimeo video:', videoData);
    }

    deleteAssets(assets: Asset[]) {
        this.showModalAndDelete(assets.map(a => a.id))
            .pipe(
                switchMap(response => {
                    if (response.result === DeletionResult.DELETED) {
                        return [true];
                    } else {
                        return this.showModalAndDelete(
                            assets.map(a => a.id),
                            response.message || '',
                        ).pipe(map(r => r.result === DeletionResult.DELETED));
                    }
                }),
            )
            .subscribe(
                () => {
                    this.notificationService.success(_('common.notify-delete-success'), {
                        entity: 'Assets',
                    });
                    this.refresh();
                },
                err => {
                    this.notificationService.error(_('common.notify-delete-error'), {
                        entity: 'Assets',
                    });
                },
            );
    }

    private showModalAndDelete(assetIds: string[], message?: string) {
        return this.modalService
            .dialog({
                title: _('catalog.confirm-delete-assets'),
                translationVars: {
                    count: assetIds.length,
                },
                body: message,
                buttons: [
                    { type: 'secondary', label: _('common.cancel') },
                    { type: 'danger', label: _('common.delete'), returnValue: true },
                ],
            })
            .pipe(
                switchMap(res => (res ? this._deleteAssets(assetIds, !!message, true) : EMPTY)),
                map(res => res.deleteAssets),
            );
    }

    private _deleteAssets(ids: string[], force: boolean, deleteFromAllChannels: boolean) {
        return this.dataService.mutate<
            Mutation,
            MutationDeleteAssetsArgs
        >(DELETE_ASSETS, {
            input: {
                assetIds: ids,
                force,
                deleteFromAllChannels
            },
        });
    }

}
