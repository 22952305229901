import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../../common/ui/utils';

// Define the interface for the question and answer data
interface SnsInfo {
  instagram: string;
  facebook: string;
  xTwitter: string;
  youtube: string;
}

@Component({
  selector: 'sns-info-form',
  template: `
    <vdr-card>
      <vdr-form-field [label]="'Instagram'">
        <input type="text" [formControl]="instagramControl">
      </vdr-form-field>

      <vdr-form-field [label]="'Facebook'">
        <input type="text" [formControl]="facebookControl">
      </vdr-form-field>

      <vdr-form-field [label]="'X (Twitter)'">
        <input type="text" [formControl]="xTwitterControl">
      </vdr-form-field>

      <vdr-form-field [label]="'YouTube'">
        <input type="text" [formControl]="youtubeControl">
      </vdr-form-field>
    </vdr-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class SnsInfoFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  @Input() readonly: boolean;
  @Input() formControl: FormControl;
  @Input() config: any;

  // Form controls for each social media platform
  instagramControl = new FormControl();
  facebookControl = new FormControl();
  xTwitterControl = new FormControl();
  youtubeControl = new FormControl();
  snsInfo: SnsInfo | null;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    const defaultSnsInfo = {
      instagram: '',
      facebook: '',
      xTwitter: '',
      youtube: '',
    };
    this.snsInfo = safeJSONParse<SnsInfo>(
      this.formControl.value,
      this.notificationService,
      defaultSnsInfo,
    );
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    // Subscribe to value changes for each control
    this.instagramControl.valueChanges.subscribe(this.onInstagramChange);
    this.facebookControl.valueChanges.subscribe(this.onFacebookChange);
    this.xTwitterControl.valueChanges.subscribe(this.onXTwitterChange);
    this.youtubeControl.valueChanges.subscribe(this.onYoutubeChange);
  }

  // Initialize form controls with values from snsInfo
  private initializeFormControls() {
    this.instagramControl.setValue(this.snsInfo?.instagram);
    this.facebookControl.setValue(this.snsInfo?.facebook);
    this.xTwitterControl.setValue(this.snsInfo?.xTwitter);
    this.youtubeControl.setValue(this.snsInfo?.youtube);
  }

  // Value change handlers for each field
  private onInstagramChange = (value: string) => {
    if(this.snsInfo?.instagram !== value) {
      this.snsInfo!.instagram = value;
      this.updateJsonString();
    }
  };

  private onFacebookChange = (value: string) => {
    if(this.snsInfo?.facebook !== value) {
      this.snsInfo!.facebook = value;
      this.updateJsonString();
    }
  };

  private onXTwitterChange = (value: string) => {
    if(this.snsInfo?.xTwitter !== value) {
      this.snsInfo!.xTwitter = value;
      this.updateJsonString();
    }
  };

  private onYoutubeChange = (value: string) => {
    if(this.snsInfo?.youtube !== value) {
      this.snsInfo!.youtube = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.snsInfo);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
