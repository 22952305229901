import { registerFormInputComponent, registerCustomDetailComponent, registerPageTab } from '@vendure/admin-ui/core';
import { JsonDisplayFormComponent } from './components/json-display-form.component';
import { FlexibleKeyValueFormComponent } from './components/flexible-key-value-form.component';
import { KeySellingPointFormComponent } from './components/key-selling-point-form.component';
import { MarketingBannerInputComponent } from './components/marketing-banner-form.component';
import { ProductFeaturesFormComponent } from './components/product-features-form.component';
import { TaxCodeInputComponent } from './components/stripe-tax-code-form';
import { ProductVariantChannelsComponent } from './components/product-variant-channel.component';
import { HeroSectionInputComponent } from './components/hero-section-form.component';
import { RelationCollectionSelectorComponent } from './components/relation-collection-selector.component';
import { EmailSubscriptionFormComponent } from './components/email-subscription-form.component';
import { LogoSectionFormComponent } from './components/logo-section-form';
import { BannerButtonInputComponent } from './components/banner-button-form.component';
import { FulfillmentSelectFormInputComponent } from './components/fulfillment-select-form-input.component';
import { FulfillmentTextFormInputComponent } from './components/fulfillment-text-form-input.component';
import { CollectionVariantOrderFormComponent } from './components/collection-vairnt-order-form.component';
import { FeaturedCollectionInputComponent } from './components/featured-collection-form.component';
import { NavigationColumnInputComponent } from './components/navigation-column-form.component';
import { NavigationItemInputComponent } from './components/navigation-item-form.component';
import { NavMenuOptionInputComponent } from './components/nav-menu-option-input.component';
import { LayoutOptionInputComponent } from './components/layout-option-input.component';
import { ExternalHtmlContentInputComponent } from './components/external-html-content-form.component';

export default [
    registerFormInputComponent('json-display-form', JsonDisplayFormComponent),
    registerFormInputComponent('flexible-key-value-form', FlexibleKeyValueFormComponent),
    registerFormInputComponent('key-selling-point-form', KeySellingPointFormComponent),
    registerFormInputComponent('marketing-banner-form', MarketingBannerInputComponent),
    registerFormInputComponent('product-features-form', ProductFeaturesFormComponent),
    registerFormInputComponent('tax-code-form', TaxCodeInputComponent),
    registerFormInputComponent('fulfillment-select-form-input', FulfillmentSelectFormInputComponent),
    registerFormInputComponent('fulfillment-text-form-input', FulfillmentTextFormInputComponent),
    registerCustomDetailComponent({locationId: 'product-variant-detail',
        component: ProductVariantChannelsComponent,
    }),
    registerFormInputComponent('hero-section-input-form', HeroSectionInputComponent),
    registerFormInputComponent('banner-button-form', BannerButtonInputComponent),
    registerFormInputComponent('relation-collection-selector', RelationCollectionSelectorComponent),
    registerFormInputComponent('logo-section-form', LogoSectionFormComponent),
    registerFormInputComponent('email-subscription-form', EmailSubscriptionFormComponent),
    registerCustomDetailComponent({locationId: 'collection-detail',
        component: CollectionVariantOrderFormComponent,
    }),
    registerFormInputComponent('featrued-collection-form', FeaturedCollectionInputComponent),
    registerFormInputComponent('navigation-item-input', NavigationItemInputComponent),
    registerFormInputComponent('navigation-column-input', NavigationColumnInputComponent),
    registerFormInputComponent('nav-menu-option-input', NavMenuOptionInputComponent),
    registerFormInputComponent('layout-option-input', LayoutOptionInputComponent),
    registerFormInputComponent('external-html-content-input', ExternalHtmlContentInputComponent),
];

