import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  StringCustomFieldConfig,
  SharedModule,
  FormInputComponent,
  DataService,
  NotificationService,
} from '@vendure/admin-ui/core';
import { EmailSubscriptionData, EmailSubscriptionType, defaultEmailSubscriptionData } from '../ui-types';
import { safeJSONParse } from '../utils';
import { Asset } from '../generated-types';
import { ID } from '@vendure/core';

@Component({
  template: `
    <vdr-card>
      <!-- Type Field -->
      <vdr-form-field [label]="'Type'">
        <select [formControl]="typeControl">
          <option value="raw">Raw</option>
          <option value="omnisend">Omnisend</option>
        </select>
      </vdr-form-field>

      <!-- Background Config -->
      <vdr-form-field [label]="'Background Color'">
        <input type="color" [formControl]="backgroundColorControl">
      </vdr-form-field>

      <div *ngIf="typeControl.value === 'omnisend'">
        <vdr-form-field [label]="'Omnisend Form ID'">
          <input type="text" [formControl]="omnisendFormIdControl">
        </vdr-form-field>
      </div>

      <div *ngIf="typeControl.value === 'raw'">
        <vdr-form-field [label]="'Title'">
          <input type="text" [formControl]="titleControl">
        </vdr-form-field>
        <vdr-form-field [label]="'Subtitle'">
          <input type="text" [formControl]="subtitleControl">
        </vdr-form-field>
        <vdr-form-field [label]="'Background Image'">
          <vdr-dynamic-form-input
            [control]="backgroundImageControl"
            [def]="{
              type: 'relation',
              entity: 'Asset',
              ui: {
                component: 'relation-form-input',
              }
            }"
          >
          </vdr-dynamic-form-input>
        </vdr-form-field>
        <vdr-form-field [label]="'Shadow Transparency'">
          <input type="number" min="0" max="1" step="0.01" [formControl]="shadowTransparencyControl">
        </vdr-form-field>
      </div>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class EmailSubscriptionFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  emailSubscriptionData: EmailSubscriptionData | null;

  typeControl = new FormControl();
  backgroundColorControl = new FormControl();
  // Row FormControls
  titleControl = new FormControl();
  subtitleControl = new FormControl();
  backgroundImageControl = new UntypedFormControl();
  shadowTransparencyControl = new FormControl();
  // Omnisend FormControls
  omnisendFormIdControl = new FormControl();

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.emailSubscriptionData = safeJSONParse<EmailSubscriptionData>(
      this.formControl.value,
      this.notificationService,
      defaultEmailSubscriptionData
    );
    this.initializeFormControls();

    if (this.emailSubscriptionData?.backgroundImageId) {
      this.dataService.product
        .getAsset(this.emailSubscriptionData?.backgroundImageId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.backgroundImageControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    this.typeControl.valueChanges.subscribe(this.onTypeChange);
    this.backgroundColorControl.valueChanges.subscribe(this.onBackgroundColorChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.subtitleControl.valueChanges.subscribe(this.onSubtitleChange);
    this.backgroundImageControl.valueChanges.subscribe(this.onBackgroundImageChange);
    this.shadowTransparencyControl.valueChanges.subscribe(this.onShadowTransparencyChange);
    this.omnisendFormIdControl.valueChanges.subscribe(this.onOmnisendFormIdChange);
  }

  private initializeFormControls() {
    this.typeControl.setValue(this.emailSubscriptionData?.type || 'raw');
    this.backgroundColorControl.setValue(this.emailSubscriptionData?.backgroundColor || '#FFFFFF');
    this.titleControl.setValue(this.emailSubscriptionData?.title);
    this.subtitleControl.setValue(this.emailSubscriptionData?.subtitle);
    this.shadowTransparencyControl.setValue(this.emailSubscriptionData?.shadowTransparency);
    this.omnisendFormIdControl.setValue(this.emailSubscriptionData?.omnisendFormId || '');
  }

  private onTypeChange = (value: EmailSubscriptionType) => {
    if (this.emailSubscriptionData?.type !== value) {
      this.emailSubscriptionData!.type = value;
      this.updateJsonString();
    }
  };

  private onBackgroundColorChange = (value: string) => {
    if (this.emailSubscriptionData?.backgroundColor !== value) {
      this.emailSubscriptionData!.backgroundColor = value;
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.emailSubscriptionData?.title !== value) {
      this.emailSubscriptionData!.title = value;
      this.updateJsonString();
    }
  };

  private onSubtitleChange = (value: string) => {
    if (this.emailSubscriptionData?.subtitle !== value) {
      this.emailSubscriptionData!.subtitle = value;
      this.updateJsonString();
    }
  };

  private onBackgroundImageChange = (value: Asset) => {
    const backgroundImageId = value ? value.id : null;
    if (this.emailSubscriptionData?.backgroundImageId !== backgroundImageId) {
      this.emailSubscriptionData!.backgroundImageId = backgroundImageId;
      this.updateJsonString();
    }
  };

  private onShadowTransparencyChange = (value: number) => {
    if (this.emailSubscriptionData?.shadowTransparency !== value) {
      this.emailSubscriptionData!.shadowTransparency = value;
      this.updateJsonString();
    }
  };

  private onOmnisendFormIdChange = (value: string) => {
    if (this.emailSubscriptionData?.omnisendFormId !== value) {
      this.emailSubscriptionData!.omnisendFormId = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.emailSubscriptionData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
