import gql from 'graphql-tag';

export const GET_COLLECTION_ITEM_ORDER_DATA = gql`
    query GetCollectionWithCustomFields($id: ID!, $maxVariants: Int!) {
        collection(id: $id) {
            id
            name
            customFields {
                itemOrderData
            }
            productVariants(options: { take: $maxVariants }) {
                items {
                    id
                    name
                    sku
                    featuredAsset {
                        id
                        preview
                    }
                    product {
                        id
                        name
                        slug
                        featuredAsset {
                            id
                            preview
                        }
                    }
                }
            }
        }
    }
`;

export type ID = string | number;
export enum HeroLayoutType {
    LeftTextFullMedia = 'left-text-full-media',
    RightTextFullMedia = 'right-text-full-media',
    MiddleTextFullMedia = 'middle-text-full-media',
}

export enum ButtonActionType {
    NavigateTo = 'navigate-to',
    AddVariantToCart = 'add-variant-to-cart',
}

export enum ButtonPositionType {
    BelowText = 'below-text',
    Custom = 'custom',
}

export enum ButtonNavigationType {
    NavigateToProduct = 'navigate-to-product',
    NavigateToCollection = 'navigate-to-collection',
    NavigateToPage = 'navigate-to-page',
    NavigateToWebsite = 'navigate-to-website',
}

export enum MarketingBannerType {
    LeftMediaRightText = 'left-media-right-text',
    LeftTextRightMedia = 'left-text-right-media',
    TopTextBelowMedia = 'top-text-below-media',
    MediaOnly = 'media-only',
    LeftMediaSlideWithBackground = 'left-media-slide-with-background',
    RightMediaSlideWithBackground = 'right-media-slide-with-background',
    TitledMedia = 'titled-media',
    KeyPointList = 'key-point-list',
    HeroSection = 'hero-section',
    ExternalHtmlContent = 'external-html-content',
}

export enum MobileMediaPosition {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

export enum EmailSubscriptionType {
    Raw = 'raw',
    Omnisend = 'omnisend',
}

export enum TextAlignment {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum CollectionItemType {
    Product = 'Product',
    ProductVariant = 'ProductVariant',
}

export enum ProductCardType {
    Regular = 'regular',
    LeftAssetSmall = 'left-asset-small',
    RightAssetSmall = 'right-asset-small',
    LeftAssetLarge = 'left-asset-large',
    RightAssetLarge = 'right-asset-large',
    TopAssetSmall = 'top-asset-small',
    TopAssetLarge = 'top-asset-large',
    FullAsset = 'full-asset',
}

export interface BannerButtonData {
    enabled: boolean;
    text: string;
    textColor: string;
    backgroundColor: string;
    actionType: ButtonActionType;
    selectedVariantId: ID | null;
    navigateType: ButtonNavigationType;
    targetProductId: ID | null;
    targetCollectionId: ID | null;
    targetPage: string;
    targetPageSectionId: string;
}

export interface HeroSectionData {
    layoutType: HeroLayoutType;
    mediaIds: ID[];
    mobileMediaIds: ID[];
    title: string;
    subtitle: string;
    titleFontSize: number;
    textColor: string;
    shadowTransparency: number;
    button: BannerButtonData | null;
    buttonPosition: ButtonPositionType;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: ButtonPositionType;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
}

export interface ExternalHtmlContentData {
    title: string;
    subtitle: string;
    htmlContent: string;
    mediaIds: ID[];
    mobileMediaIds: ID[];
}

export interface KeySellingPointData {
    iconId: ID | null;
    title: string;
    value: string;
}

export interface QaData {
    question: string;
    answer: string;
}

export interface MarketingBannerData {
    type: MarketingBannerType;
    mediaIds: ID[];
    mobileMediaIds: ID[];
    mobileMediaPosition: MobileMediaPosition;
    title: string;
    text: string;
    leftRightPartRatio: number;
    shadowTransparency: number;
    backgroundMediaId: ID | null;
    backgroundMobileMediaId: ID | null;
    backgroundColor: string;
    textColor: string;
    textAlignment: TextAlignment;
    textWidth: number;
    textIndentation: number;
    button: BannerButtonData | null;
    buttonPosition: ButtonPositionType;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: ButtonPositionType;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
    keyPointsLayoutType: string;
    keyPoints: (KeySellingPointData | null)[];
    heroSection: HeroSectionData | null;
    externalHtmlContent: ExternalHtmlContentData | null;
}

export interface LogoSectionData {
    pageTitle: string;
    displayHeaderMenu: boolean;
    displayFooterMenu: boolean;
    normalLogoId: ID | null;
    smallLogoId: ID | null;
    nameLogoId: ID | null;
    browserIconId: ID | null;
    displayName: boolean;
}

export interface AboutPageData {
  posterAssetId: ID | null;
  title: string;
  text: string;
  citation: string;
  featuredAssetId: ID | null;
}

export interface FaqPageData {
    posterAssetId: ID | null;
    title: string;
    qaList: (QaData|null)[];
}

export interface ContactPageData {
    posterAssetId: ID | null;
    title: string;
    contactFormType: string;
    featuredAssetId: ID | null;
}

export interface FeaturedCollectionData {
    title: string;
    subtitle: string;
    collectionId: ID | null;
    displayType: CollectionItemType;
    productCardType: ProductCardType;
    cardsPerRow: number;
    displayFields?: string[];
}

export interface CollectionGroupData {
    groupName: string;
    title: string;
    subtitle: string;
    description: string;
    backgroundAssetId: ID | null;
    featuredAssetIds: ID[];
    collectionIds: ID[];
}

export interface EmailSubscriptionData {
    type: EmailSubscriptionType;
    omnisendFormId: string;
    backgroundColor: string;
    title: string;
    subtitle: string;
    backgroundImageId: ID | null;
    shadowTransparency: number;
}

export interface CollectionItemOrderData {
    collectionId: ID | null;
    enabled: boolean;
    orderType: CollectionItemType;
    variantIdsInOrder: ID[];
    productIdsInOrder: ID[];
}

export const defaultBannerButtonData: BannerButtonData = {
    enabled: false,
    text: '',
    textColor: '#000000',
    backgroundColor: '#ffffff',
    actionType: ButtonActionType.NavigateTo,
    selectedVariantId: null,
    navigateType: ButtonNavigationType.NavigateToProduct,
    targetProductId: null,
    targetCollectionId: null,
    targetPage: '',
    targetPageSectionId: '',
};

// Default value for HeroSectionData
export const defaultHeroSectionData: HeroSectionData = {
    layoutType: HeroLayoutType.LeftTextFullMedia,
    mediaIds: [],
    mobileMediaIds: [],
    title: '',
    subtitle: '',
    textColor: '#000000',
    titleFontSize: 40,
    shadowTransparency: 1,
    button: { ...defaultBannerButtonData },
    buttonPosition: ButtonPositionType.BelowText,
    buttonLocationX: 0.5,
    buttonLocationY: 0.5,
    buttonPositionMobile: ButtonPositionType.BelowText,
    buttonLocationXMobile: 0.5,
    buttonLocationYMobile: 0.5,
};

// Default value for ExternalHtmlContentData
export const defaultExternalHtmlContentData: ExternalHtmlContentData = {
    title: '',
    subtitle: '',
    htmlContent: '',
    mediaIds: [],
    mobileMediaIds: [],
};

// Default value for KeySellingPointData
export const defaultKeySellingPointData: KeySellingPointData = {
    iconId: null,
    title: '',
    value: '',
};

// Default value for QaData
export const defaultQaData: QaData = {
    question: '',
    answer: '',
};

// Default value for MarketingBannerData
export const defaultMarketingBannerData: MarketingBannerData = {
    type: MarketingBannerType.LeftMediaRightText,
    mediaIds: [],
    mobileMediaIds: [],
    mobileMediaPosition: MobileMediaPosition.Top,
    title: '',
    text: '',
    leftRightPartRatio: 0.5,
    shadowTransparency: 1,
    backgroundMediaId: null,
    backgroundMobileMediaId: null,
    backgroundColor: '#ffffff',
    textColor: '#000000',
    textAlignment: TextAlignment.Center,
    textWidth: 1,
    textIndentation: 0,
    button: { ...defaultBannerButtonData },
    buttonPosition: ButtonPositionType.BelowText,
    buttonLocationX: 0.5,
    buttonLocationY: 0.5,
    buttonPositionMobile: ButtonPositionType.BelowText,
    buttonLocationXMobile: 0.5,
    buttonLocationYMobile: 0.5,
    keyPointsLayoutType: '',
    keyPoints: [],
    heroSection: { ...defaultHeroSectionData },
    externalHtmlContent: { ...defaultExternalHtmlContentData },
};

// Default value for LogoSectionData
export const defaultLogoSectionData: LogoSectionData = {
    pageTitle: '',
    displayHeaderMenu: true,
    displayFooterMenu: true,
    normalLogoId: null,
    smallLogoId: null,
    nameLogoId: null,
    browserIconId: null,
    displayName: true,
};

// Default value for AboutPageData
export const defaultAboutPageData: AboutPageData = {
    posterAssetId: null,
    title: '',
    text: '',
    citation: '',
    featuredAssetId: null,
};

// Default value for FaqPageData
export const defaultFaqPageData: FaqPageData = {
    posterAssetId: null,
    title: '',
    qaList: [],
};

// Default value for ContactPageData
export const defaultContactPageData: ContactPageData = {
    posterAssetId: null,
    title: '',
    contactFormType: '',
    featuredAssetId: null,
};

export const defaultCollectionItemOrderData: CollectionItemOrderData = {
    collectionId: null,
    enabled: false,
    orderType: CollectionItemType.Product,
    variantIdsInOrder: [],
    productIdsInOrder: [],
}

// Default value for CollectionGroupData
export const defaultCollectionGroupData: CollectionGroupData = {
    groupName: '',
    title: '',
    subtitle: '',
    description: '',
    backgroundAssetId: null,
    featuredAssetIds: [],
    collectionIds: [],
};

// Default value for EmailSubscriptionData
export const defaultEmailSubscriptionData: EmailSubscriptionData = {
    type: EmailSubscriptionType.Raw,
    backgroundColor: '#ffffff',
    title: '',
    subtitle: '',
    backgroundImageId: null,
    shadowTransparency: 1,
    omnisendFormId: '',
};

export const defaultFeaturedCollectionData: FeaturedCollectionData = {
    title: '',
    subtitle: '',
    collectionId: null,
    displayType: CollectionItemType.Product,
    productCardType: ProductCardType.Regular,
    cardsPerRow: 2,
    displayFields: [],
}
