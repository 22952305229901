import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

interface ProductFeatureData {
  title: string;
  text: string;
}

@Component({
  selector: 'vdr-product-features-input',
  template: `
    <vdr-card>
      <vdr-form-field [label]="'Product Feature Title'">
        <input type="text" [formControl]="titleControl">
      </vdr-form-field>
      <vdr-rich-text-editor
        [label]="'Product Feature Content'"
        [formControl]="textControl">
      </vdr-rich-text-editor>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class ProductFeaturesFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  titleControl = new FormControl();
  textControl = new FormControl();

  productFeatureData: ProductFeatureData | null;

  constructor(private notificationService: NotificationService ) {}
  ngOnInit() {
    const defaultProductFeatureData = {
      title: '',
      text: '',
    };
    this.productFeatureData = safeJSONParse<ProductFeatureData>(
      this.formControl.value,
      this.notificationService,
      defaultProductFeatureData,
    );
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.textControl.valueChanges.subscribe(this.onTextChange);
  }

  private initializeFormControls() {
    this.titleControl.setValue(this.productFeatureData?.title);
    this.textControl.setValue(this.productFeatureData?.text);
  }

  private onTitleChange = (value: string) => {
    if (this.productFeatureData?.title !== value) {
      this.productFeatureData!.title = value;
      this.updateJsonString();
    }
  };

  private onTextChange = (value: string) => {
    if (this.productFeatureData?.text !== value) {
      this.productFeatureData!.text = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.productFeatureData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
