import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent } from '@vendure/admin-ui/core';

export interface ReturnCancelPolicyData {
  enableCancel: boolean;
  cancellableTimeInHours: number;
  cancelExpiredNotification: string;
  enableReturn: boolean;
  returnableTimeInDays: number;
  returnExpiredNotification: string;
  disabledPolicyNotification: string;
}

@Component({
  selector: 'return-cancel-policy-form',
  template: `
    <vdr-card>
      <!-- Enable Cancel -->
      <vdr-form-field [label]="'Enable Cancel'">
        <clr-toggle-wrapper>
          <input
            type="checkbox"
            clrToggle
            name="enableCancel"
            [formControl]="enableCancelControl"
          />
          <label>{{ 'Enable Cancel' }}</label>
        </clr-toggle-wrapper>
      </vdr-form-field>
      <div *ngIf="enableCancelControl.value">
        <!-- Cancellable Time -->
        <vdr-form-field [label]="'Cancellable Time (hours)'">
          <input type="number" [formControl]="cancellableTimeControl" min="0" />
        </vdr-form-field>
        <!-- Cancel Expire Notification -->
        <vdr-form-field [label]="'Cancel Expire Notification'">
          <vdr-rich-text-editor
            [formControl]="cancelExpiredNotificationControl">
          </vdr-rich-text-editor>
        </vdr-form-field>
      </div>

      <!-- Enable Return -->
      <vdr-form-field [label]="'Enable Return'">
        <clr-toggle-wrapper>
          <input
            type="checkbox"
            clrToggle
            name="enableReturn"
            [formControl]="enableReturnControl"
          />
          <label>{{ 'Enable Return' }}</label>
        </clr-toggle-wrapper>
      </vdr-form-field>
      <div *ngIf="enableReturnControl.value">
        <!-- Returnable Time -->
        <vdr-form-field [label]="'Returnable Time (days)'">
          <input type="number" [formControl]="returnableTimeControl" min="0" />
        </vdr-form-field>
        <!-- Return Expired Notification -->
        <vdr-form-field [label]="'Return Expired Notification'">
          <vdr-rich-text-editor
            [formControl]="returnExpiredNotificationControl">
          </vdr-rich-text-editor>
        </vdr-form-field>
      </div>

      <!-- Disabled Policy Notification -->
      <div *ngIf="!enableCancelControl.value && !enableReturnControl.value">
        <vdr-form-field [label]="'No Policy Notification Message'">
          <vdr-rich-text-editor
            [formControl]="disabledPolicyNotificationControl">
          </vdr-rich-text-editor>
        </vdr-form-field>
      </div>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class ReturnCancelPolicyInputComponent
  implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit
{
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // Form Controls
  enableCancelControl = new FormControl();
  cancellableTimeControl = new FormControl();
  cancelExpiredNotificationControl = new FormControl();
  enableReturnControl = new FormControl();
  returnableTimeControl = new FormControl();
  returnExpiredNotificationControl = new FormControl();
  disabledPolicyNotificationControl = new FormControl();

  jsonString: string;
  policyData: ReturnCancelPolicyData;

  ngOnInit() {
    this.jsonString = this.formControl.value;
    if (this.jsonString) {
      try {
        this.policyData = JSON.parse(this.jsonString);
      } catch {
        console.error(
          '[ReturnCancelPolicyInputComponent] Invalid JSON string',
          this.jsonString
        );
        this.initializeDefaultPolicyData();
      }
    } else {
      this.initializeDefaultPolicyData();
    }

    this.initializeFormControls();
  }

  ngAfterViewInit() {
    this.enableCancelControl.valueChanges.subscribe(this.onEnableCancelChange);
    this.cancellableTimeControl.valueChanges.subscribe(this.oncancellableTimeChange);
    this.cancelExpiredNotificationControl.valueChanges.subscribe(
      this.oncancelExpiredNotificationChange
    );
    this.enableReturnControl.valueChanges.subscribe(this.onEnableReturnChange);
    this.returnableTimeControl.valueChanges.subscribe(this.onreturnableTimeChange);
    this.returnExpiredNotificationControl.valueChanges.subscribe(
      this.onreturnExpiredNotificationChange
    );
    this.disabledPolicyNotificationControl.valueChanges.subscribe(
      this.onDisabledPolicyNotificationChange
    );
    this.enableCancelControl.valueChanges.subscribe(this.updateNotificationFieldVisibility);
    this.enableReturnControl.valueChanges.subscribe(this.updateNotificationFieldVisibility);

    this.updateNotificationFieldVisibility();
  }

  private initializeDefaultPolicyData() {
    this.policyData = {
      enableCancel: true,
      cancellableTimeInHours: 6,
      cancelExpiredNotification:
        'Your order is outside the cancellation timeframe. Please email our support team(support@dealomega.com) for further help.',
      enableReturn: true,
      returnableTimeInDays: 15,
      returnExpiredNotification: '',
      disabledPolicyNotification: '',
    };
  }

  private initializeFormControls() {
    this.enableCancelControl.setValue(this.policyData.enableCancel);
    this.cancellableTimeControl.setValue(this.policyData.cancellableTimeInHours);
    this.cancelExpiredNotificationControl.setValue(this.policyData.cancelExpiredNotification);
    this.enableReturnControl.setValue(this.policyData.enableReturn);
    this.returnableTimeControl.setValue(this.policyData.returnableTimeInDays);
    this.returnExpiredNotificationControl.setValue(this.policyData.returnExpiredNotification);
    this.disabledPolicyNotificationControl.setValue(this.policyData.disabledPolicyNotification);
  }

  // Handlers for form control changes
  private onEnableCancelChange = (value: boolean) => {
    if (this.policyData && this.policyData.enableCancel !== value) {
      this.policyData.enableCancel = value;
      this.updateJsonString();
      this.updateNotificationFieldVisibility();
    }
  };

  private oncancellableTimeChange = (value: number) => {
    if (this.policyData && this.policyData.cancellableTimeInHours !== value) {
      this.policyData.cancellableTimeInHours = value;
      this.updateJsonString();
    }
  };

  private oncancelExpiredNotificationChange = (value: string) => {
    if (this.policyData && this.policyData.cancelExpiredNotification !== value) {
      this.policyData.cancelExpiredNotification = value;
      this.updateJsonString();
    }
  };

  private onEnableReturnChange = (value: boolean) => {
    if (this.policyData && this.policyData.enableReturn !== value) {
      this.policyData.enableReturn = value;
      this.updateJsonString();
      this.updateNotificationFieldVisibility();
    }
  };

  private onreturnableTimeChange = (value: number) => {
    if (this.policyData && this.policyData.returnableTimeInDays !== value) {
      this.policyData.returnableTimeInDays = value;
      this.updateJsonString();
    }
  };

  private onreturnExpiredNotificationChange = (value: string) => {
    if (this.policyData && this.policyData.returnExpiredNotification !== value) {
      this.policyData.returnExpiredNotification = value;
      this.updateJsonString();
    }
  };

  private onDisabledPolicyNotificationChange = (value: string) => {
    if (this.policyData && this.policyData.disabledPolicyNotification !== value) {
      this.policyData.disabledPolicyNotification = value;
      this.updateJsonString();
    }
  };

  private updateNotificationFieldVisibility = () => {
    const shouldShowNotification =
      !this.enableCancelControl.value && !this.enableReturnControl.value;
    if (!shouldShowNotification) {
      this.disabledPolicyNotificationControl.setValue('');
    }
  };

  private updateJsonString() {
    this.jsonString = JSON.stringify(this.policyData);
    this.formControl.setValue(this.jsonString);
    this.formControl.markAsDirty();
  }
}
