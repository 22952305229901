import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import {
  SharedModule,
  StringCustomFieldConfig,
  DynamicFormInputComponent,
  FormInputComponent,
  DataService,
  NotificationService
} from '@vendure/admin-ui/core';
import { ID } from '@vendure/core';
import { Asset } from '../generated-types';
import { forkJoin, Observable } from 'rxjs';
import { safeJSONParse } from '../utils';
import { ExternalHtmlContentData, defaultExternalHtmlContentData } from '../ui-types';

@Component({
  selector: 'vdr-external-html-content-input',
  template: `
    <vdr-card>
      <!-- Title -->
      <vdr-form-field [label]="'Title'">
        <input type="text" [formControl]="titleControl" />
      </vdr-form-field>

      <!-- Subtitle -->
      <vdr-form-field [label]="'Subtitle'">
        <input type="text" [formControl]="subtitleControl" />
      </vdr-form-field>

      <!-- HTML Content -->
      <vdr-form-field [label]="'Html Content'">
        <textarea
            [formControl]="htmlContentControl">
        </textarea>
      </vdr-form-field>

      <!-- Medias -->
      <vdr-form-field [label]="'Medias'">
        <vdr-dynamic-form-input
          #mediasInput
          [control]="mediasControl"
          [def]="{
            list: true,
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input'
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Mobile Medias -->
      <vdr-form-field [label]="'Mobile Medias'">
        <vdr-dynamic-form-input
          #mobileMediasInput
          [control]="mobileMediasControl"
          [def]="{
            list: true,
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input'
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class ExternalHtmlContentInputComponent
  implements OnInit, AfterViewInit, FormInputComponent<StringCustomFieldConfig>
{
  @ViewChild('mediasInput') mediasInput: DynamicFormInputComponent;
  @ViewChild('mobileMediasInput') mobileMediasInput: DynamicFormInputComponent;

  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // Form Controls
  titleControl = new FormControl();
  subtitleControl = new FormControl();
  htmlContentControl = new FormControl();
  mediasControl = new UntypedFormControl();
  mobileMediasControl = new UntypedFormControl();

  // Parsed data structure
  externalHtmlContentData: ExternalHtmlContentData | null;

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.externalHtmlContentData = safeJSONParse<ExternalHtmlContentData>(
      this.formControl.value,
      this.notificationService,
      defaultExternalHtmlContentData
    );
    this.initializeFormControls();

    if (this.externalHtmlContentData && this.externalHtmlContentData.mediaIds?.length) {
      this.loadAssetsByIds(this.externalHtmlContentData.mediaIds).subscribe(assets => {
        this.mediasControl.setValue(assets);
        if (this.mediasInput) {
          this.mediasInput.writeValue(assets);
        }
      });
    }
    if (this.externalHtmlContentData && this.externalHtmlContentData.mobileMediaIds?.length) {
      this.loadAssetsByIds(this.externalHtmlContentData.mobileMediaIds).subscribe(assets => {
        this.mobileMediasControl.setValue(assets);
        if (this.mobileMediasInput) {
          this.mobileMediasInput.writeValue(assets);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.mediasInput) {
      this.mediasInput.registerOnChange(this.onMediasChange);
    }
    if (this.mobileMediasInput) {
      this.mobileMediasInput.registerOnChange(this.onMobileMediasChange);
    }
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.subtitleControl.valueChanges.subscribe(this.onSubtitleChange);
    this.htmlContentControl.valueChanges.subscribe(this.onHtmlContentChange);
  }

  private initializeFormControls(): void {
    this.titleControl.setValue(this.externalHtmlContentData?.title);
    this.subtitleControl.setValue(this.externalHtmlContentData?.subtitle);
    this.htmlContentControl.setValue(this.externalHtmlContentData?.htmlContent);
  }

  private loadAssetsByIds(ids: ID[]): Observable<Asset[]> {
    return forkJoin(
      ids.map((id) =>
        this.dataService.product
          .getAsset(id.toString())
          .mapSingle((res) => res.asset)
      )
    ) as Observable<Asset[]>;
  }

  private onTitleChange = (value: string) => {
    if (this.externalHtmlContentData && this.externalHtmlContentData.title !== value) {
      this.externalHtmlContentData.title = value;
      this.updateJsonString();
    }
  };

  private onSubtitleChange = (value: string) => {
    if (this.externalHtmlContentData && this.externalHtmlContentData.subtitle !== value) {
      this.externalHtmlContentData.subtitle = value;
      this.updateJsonString();
    }
  };

  private onHtmlContentChange = (value: string) => {
    if (this.externalHtmlContentData && this.externalHtmlContentData.htmlContent !== value) {
      this.externalHtmlContentData.htmlContent = value;
      this.updateJsonString();
    }
  };

  private onMediasChange = (medias: Asset[]) => {
    const changedAssetIds = medias.map(asset => asset.id);
    if (this.externalHtmlContentData && !this.areArraysEqual(this.externalHtmlContentData.mediaIds, changedAssetIds)) {
      this.externalHtmlContentData.mediaIds = changedAssetIds;
      this.updateJsonString();
    }
  };

  private onMobileMediasChange = (medias: Asset[]) => {
    const changedAssetIds = medias.map(asset => asset.id);
    if (this.externalHtmlContentData && !this.areArraysEqual(this.externalHtmlContentData.mobileMediaIds, changedAssetIds)) {
      this.externalHtmlContentData.mobileMediaIds = changedAssetIds;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.externalHtmlContentData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }

  private areArraysEqual(array1: ID[], array2: ID[]): boolean {
    if (!array1?.length && !array2?.length) {
      return true;
    }
    return (
      array1?.length === array2?.length &&
      array1.every(value => array2.includes(value))
    );
  }
}
