import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  StringCustomFieldConfig,
  SharedModule,
  FormInputComponent,
  DataService,
  NotificationService
} from '@vendure/admin-ui/core';
import { KeySellingPointData, defaultKeySellingPointData } from '../ui-types';
import { safeJSONParse } from '../utils';
import { Asset } from '../generated-types';

@Component({
  template: `
    <vdr-card>
      <vdr-form-field [label]="'Icon'">
        <vdr-relation-asset-input
          [parentFormControl]="iconControl"
          [config]="config"
          [readonly]="readonly"
        ></vdr-relation-asset-input>
      </vdr-form-field>
      <vdr-form-field [label]="'Title'">
        <input type="text" [formControl]="titleControl" />
      </vdr-form-field>
      <vdr-form-field [label]="'Value'">
        <input type="text" [formControl]="valueControl" />
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class KeySellingPointFormComponent
  implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit
{
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  keySellingPointData: KeySellingPointData | null;
  iconControl = new FormControl();
  titleControl = new FormControl();
  valueControl = new FormControl();

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.keySellingPointData = safeJSONParse<KeySellingPointData>(
      this.formControl.value,
      this.notificationService,
      defaultKeySellingPointData,
    );
    this.initializeFormControls();
    // Load the Asset based on the stored ID
    if (this.keySellingPointData?.iconId) {
      this.dataService.product
        .getAsset(this.keySellingPointData.iconId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.iconControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    this.iconControl.valueChanges.subscribe(this.onIconChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.valueControl.valueChanges.subscribe(this.onValueChange);
  }

  private initializeFormControls() {
    // iconControl is set after loading the Asset
    this.titleControl.setValue(this.keySellingPointData?.title);
    this.valueControl.setValue(this.keySellingPointData?.value);
  }

  private onIconChange = (value: Asset | null) => {
    const iconId = value ? value.id : null;
    if (this.keySellingPointData?.iconId !== iconId) {
      this.keySellingPointData!.iconId = iconId;
      this.updateFormControlValue();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.keySellingPointData?.title !== value) {
      this.keySellingPointData!.title = value;
      this.updateFormControlValue();
    }
  };

  private onValueChange = (value: string) => {
    if (this.keySellingPointData?.value !== value) {
      this.keySellingPointData!.value = value;
      this.updateFormControlValue();
    }
  };

  private updateFormControlValue() {
    this.formControl.setValue(JSON.stringify(this.keySellingPointData));
    this.formControl.markAsDirty();
  }
}
