import { addNavMenuItem } from '@vendure/admin-ui/core';
import { Permission } from './gql/generated'

export default [
    addNavMenuItem({
        id: 'vendors',
        label: 'Vendors',
        routerLink: ['/extensions/vendors'],
        icon: 'bundle',
        requiresPermission: Permission.SuperAdmin
    },
    'settings' , 'channels'),
];