import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
    SharedModule,
    CustomDetailComponent,
    GetSellerDetailQuery,
    DataService,
    ModalService,
    NotificationService,
} from '@vendure/admin-ui/core';
import { CREATE_STORE_SITE } from '../common/ui-types';
import { CreateStoreSiteDialogComponent } from './create-store-site-dialog.component';


@Component({
    template: `
    <div *ngIf="entity$ | async as entity">
        <vdr-card *ngIf="!(entity.customFields?.storeSite)" class="store-card">
            <button
                (click)="openCreateDialog()"
                [disabled]="inFlight">
                Create Store Site
            </button>
        </vdr-card>
    </div>
    `,
    standalone: true,
    imports: [SharedModule, CreateStoreSiteDialogComponent],
    styles: [`
        .store-card {
            margin-bottom: 10px;
        }
    `]
})

export class SellerStoreSiteDetailComponent implements CustomDetailComponent {
    entity$: Observable<NonNullable<GetSellerDetailQuery['seller']>>;
    detailForm: FormGroup;
    showCreateDialog = false;
    inFlight = false;
    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private router: Router,
        private modalService: ModalService
    ) {}

    openCreateDialog() {
        if (this.inFlight) {
            return;
        }
        this.inFlight = true;

        try {
            this.modalService
            .fromComponent(CreateStoreSiteDialogComponent)
            .subscribe(result => {
                if (result) {
                    this.createStoreSite(result.name, result.identifier);
                } else {
                    this.inFlight = false;
                }
            });
        } catch (error) {
            this.notificationService.error(`Error: ${error}`);
            this.inFlight = false;
        }
    }

    private createStoreSite(name: string, identifier: string) {
        this.entity$.pipe(
            switchMap(seller => this.dataService.mutate(CREATE_STORE_SITE, {
                input: {
                    sellerId: seller.id,
                    name: name,
                    identifier: identifier
                }
            }))
        ).subscribe(
            (result:any) => {
                console.log('[Create Store Site]result:', result, result?.createStoreSite, result?.createStoreSite?.id);
                this.notificationService.success('Created new store site');
                this.inFlight = false;
                this.router.navigate(['/extensions', 'store-sites', result?.createStoreSite?.id]);
            },
            (err:any) => {
                this.inFlight = false;
                this.notificationService.error('Failed to create store site');
            }
        );
    }
}
