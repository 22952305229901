import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../../common/ui/utils';
import { QaData } from '../../common/ui/ui-types';

@Component({
  selector: 'question-answer-input',
  template: `
    <vdr-card>
      <!-- Question Input -->
      <vdr-form-field [label]="'Question'">
        <input type="text" [formControl]="questionControl">
      </vdr-form-field>

      <!-- Answer Input (Rich-text Editor) -->
      <vdr-form-field>
        <vdr-rich-text-editor
          [label]="'Answer'"
          [formControl]="answerControl">
        </vdr-rich-text-editor>
      </vdr-form-field>
    </vdr-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
})
export class QAInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  @Input() readonly: boolean;
  @Input() formControl: FormControl;
  @Input() config: any;

  // Form controls for question and answer
  questionControl = new FormControl();
  answerControl = new FormControl();
  qaData: QaData | null;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    const defaultQaData = {
      question: '',
      answer: '',
    };
    this.qaData = safeJSONParse<QaData>(
      this.formControl.value,
      this.notificationService,
      defaultQaData,
    );
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    // Subscribe to value changes for the question and answer controls
    this.questionControl.valueChanges.subscribe(this.onQuestionChange);
    this.answerControl.valueChanges.subscribe(this.onAnswerChange);
  }

  // Initialize form controls with values from qaData
  private initializeFormControls() {
    this.questionControl.setValue(this.qaData?.question);
    this.answerControl.setValue(this.qaData?.answer);
  }

  // Value change handler for the question field
  private onQuestionChange = (value: string) => {
    if (this.qaData?.question !== value) {
      this.qaData!.question = value;
      this.updateJsonString();
    }
  }

  // Value change handler for the answer field
  private onAnswerChange = (value: string) => {
    if (this.qaData?.answer !== value) {
      this.qaData!.answer = value;
      this.updateJsonString();
    }
  }

  // Update the JSON string and form control value
  private updateJsonString() {
    const jsonString = JSON.stringify(this.qaData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
