import { addActionBarDropdownMenuItem, registerBulkAction, ModalService, DataService, NotificationService } from '@vendure/admin-ui/core';
import { MutationImportProductsFromCsvArgs, Mutation, MutationExportProductsToCsvArgs } from '../common/ui/generated-types';
import { gql } from "@apollo/client";

export const IMPORT_PRODUCTS_SUMMARY_FRAGMENT = gql`
  fragment ImportSummaryFragment on ImportProductsFromCsvResult {
    importedProducts
    importedProductVariants
    processedProducts
    processedProductVariants
    failedAssets
    error
  }
`;

export const IMPORT_PRODUCTS_FROM_CSV = gql`
  mutation ImportProductsFromCsv($file: Upload!) {
    importProductsFromCsv(file: $file) {
      ...ImportSummaryFragment
    }
  }
  ${IMPORT_PRODUCTS_SUMMARY_FRAGMENT}
`;


export const EXPORT_PRODUCTS_TO_CSV = gql`
  mutation ExportProductsToCsv($input: ExportProductInput!) {
    exportProductsToCsv(input: $input) {
      downloadURL
      error
    }
  }
`;


async function exportProducts(action: string, productIds: string[], dataService: DataService, notificationService: NotificationService) {
  // const dataService = context.injector.get(DataService);
  dataService.mutate<
    Mutation,
    MutationExportProductsToCsvArgs
  >(
    EXPORT_PRODUCTS_TO_CSV, { input: { action, productIds } }
  ).subscribe({
    next: (response: Mutation) => {
      console.log('Response:', response.exportProductsToCsv);
      if (response.exportProductsToCsv.error) {
        notificationService.error(response.exportProductsToCsv.error);
      } else {
        downloadFile(response.exportProductsToCsv.downloadURL)
        notificationService.info(`Export done`);
      }
    },
    error: (error: any) => {
      console.error('Error during export:', error);
      notificationService.error(`Error during export ${error.message}`);
    },
    complete: () => {
      console.log('File export complete');
    },
  });
}

function downloadFile(url: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = 'exported-products.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function importProducts(file: File, dataService: DataService, notificationService: NotificationService) {

  // const dataService = context.injector.get(DataService);
  dataService.mutate<
    Mutation,
    MutationImportProductsFromCsvArgs
  >(
    IMPORT_PRODUCTS_FROM_CSV, { file }
  ).subscribe({
    next: (response: Mutation) => {
      console.log('Response:', response.importProductsFromCsv);
      if (response.importProductsFromCsv.error) {
        notificationService.error(response.importProductsFromCsv.error);
      } else {
        notificationService.info(`Import done, processed: ${response.importProductsFromCsv.processedProducts}  imported ${response.importProductsFromCsv.importedProducts} product(s)`);
      }
    },
    error: (error: any) => {
      console.error('Error during file upload:', error);
      notificationService.error(`Error during file upload ${error.message}`);
    },
    complete: () => {
      console.log('File upload complete');
    },
  });
}

async function onProductImport(event: MouseEvent, dataService: DataService, notificationService: NotificationService) {
  // Create a hidden file input element programmatically
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.style.display = 'none';  // Ensure it stays hidden
  fileInput.accept = ".csv"

  // Append the input to the document body
  document.body.appendChild(fileInput);

  fileInput.addEventListener('change', async (event: Event) => {
    try {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const file = input.files[0];
        console.log('Selected file:', file);
        await importProducts(file, dataService, notificationService);
      }

    } catch (error: any) {
      notificationService.error('Error executing mutation: ' + error.message);
    }
  });

  // Programmatically click the file input to open the file dialog
  fileInput.click();

  // Remove the input after file selection to clean up
  fileInput.remove();
}


export default [
  addActionBarDropdownMenuItem({
    id: 'ch-import-product',
    label: 'Import',
    locationId: 'product-list',
    icon: 'import',
    onClick: async (event, context) => {
      await onProductImport(event, context.dataService, context.notificationService);
    },
  }),
  addActionBarDropdownMenuItem({
    id: 'ch-export-product',
    label: 'Export',
    locationId: 'product-list',
    icon: 'export',
    hasDivider: true,
    onClick: async (event, context) => {
      const modalService = context.injector.get(ModalService);
      modalService
        .dialog({
          title: `Do you want to export all products?`,
          buttons: [
            { type: 'secondary', label: 'No' },
            { type: 'primary', label: 'Yes', returnValue: true },
          ],
        })
        .subscribe(async (response) => {
          if (response) {
            await exportProducts('export-all', [], context.dataService, context.notificationService);
          }
        });
    },
  }),
  registerBulkAction({
    location: 'product-list',
    label: 'Export',
    icon: 'export',
    onClick: async (context) => {
      const modalService = context.injector.get(ModalService);
      modalService
        .dialog({
          title: `Export ${context.selection.length} products ?`,
          buttons: [
            { type: 'secondary', label: 'cancel' },
            { type: 'primary', label: 'OK', returnValue: true },
          ],
        })
        .subscribe(async (response) => {
          if (response) {
            const productIds = context.selection.map(item => item.id);
            const dataService = context.injector.get(DataService);
            const notificationService = context.injector.get(NotificationService);
            await exportProducts('export-bulk', productIds, dataService, notificationService);
          }
        });
    },
  }),

];