import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  StringCustomFieldConfig,
  SharedModule,
  FormInputComponent,
  NotificationService,
  DataService,
} from '@vendure/admin-ui/core';
import { Asset } from '../../common/ui/generated-types';
import { ContactPageData, defaultContactPageData } from '../../common/ui/ui-types';
import { safeJSONParse } from '../../common/ui/utils';

@Component({
  template: `
    <vdr-card>
      <!-- Poster Asset -->
      <vdr-form-field [label]="'Poster'">
        <vdr-dynamic-form-input
          [control]="posterAssetControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Title -->
      <vdr-form-field [label]="'Title'">
        <input type="text" [formControl]="titleControl" />
      </vdr-form-field>

      <!-- Contact Form Type -->
      <vdr-form-field [label]="'Contact Form Type'">
        <select [formControl]="contactFormTypeControl">
          <option value="email-form">Email</option>
        </select>
      </vdr-form-field>

      <!-- Featured Asset -->
      <vdr-form-field [label]="'Featured Asset'">
        <vdr-dynamic-form-input
          [control]="featuredAssetControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class ContactPageFormComponent
  implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit
{
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  contactPageData: ContactPageData | null;

  posterAssetControl = new UntypedFormControl();
  titleControl = new FormControl();
  contactFormTypeControl = new FormControl();
  featuredAssetControl = new UntypedFormControl();

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.contactPageData = safeJSONParse<ContactPageData>(
      this.formControl.value,
      this.notificationService,
      defaultContactPageData,
    );
    this.initializeFormControls();

    if (this.contactPageData?.posterAssetId) {
      this.dataService.product
        .getAsset(this.contactPageData.posterAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.posterAssetControl.setValue(asset);
        });
    }

    if (this.contactPageData?.featuredAssetId) {
      this.dataService.product
        .getAsset(this.contactPageData.featuredAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.featuredAssetControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    this.posterAssetControl.valueChanges.subscribe(this.onPosterAssetChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.contactFormTypeControl.valueChanges.subscribe(this.onContactFormTypeChange);
    this.featuredAssetControl.valueChanges.subscribe(this.onFeaturedAssetChange);
  }

  private initializeFormControls() {
    this.posterAssetControl.setValue(null);
    this.titleControl.setValue(this.contactPageData?.title);
    this.contactFormTypeControl.setValue(this.contactPageData?.contactFormType);
    this.featuredAssetControl.setValue(null);
  }

  private onPosterAssetChange = (value: Asset) => {
    const posterAssetId = value ? value.id : null;
    if (this.contactPageData?.posterAssetId !== posterAssetId) {
      this.contactPageData!.posterAssetId = posterAssetId;
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.contactPageData?.title !== value) {
      this.contactPageData!.title = value;
      this.updateJsonString();
    }
  };

  private onContactFormTypeChange = (value: string) => {
    if (this.contactPageData?.contactFormType !== value) {
      this.contactPageData!.contactFormType = value;
      this.updateJsonString();
    }
  };

  private onFeaturedAssetChange = (value: Asset) => {
    const featuredAssetId = value ? value.id : null;
    if (this.contactPageData?.featuredAssetId !== featuredAssetId) {
      this.contactPageData!.featuredAssetId = featuredAssetId;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.contactPageData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
