import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { CustomFieldControl, DataService, SharedModule, NotificationService } from '@vendure/admin-ui/core';
import { Observable, Subscription } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';
import { GET_PRODUCT_REVIEW, GET_REVIEWS_FOR_PRODUCT } from '../common/ui-types';
import { Product, ProductReview, Query } from '../../common/ui/generated-types';

interface GetProductReviewQuery {
    productReview: ProductReview;
}

@Component({
    selector: 'product-review-selector',
    template: `
        <div class="flex flex-row items-center">
            <div class="flex flex-col">
                <div>
                    <vdr-dynamic-form-input
                        [control]="productControl"
                        [def]="productConfig"
                    ></vdr-dynamic-form-input>
                </div>
                <div *ngIf="productReviews$ | async as reviews">
                    <select [formControl]="formControl">
                        <option [ngValue]="null">Select a review...</option>
                        <option *ngFor="let review of reviews" [ngValue]="review.id">
                            {{ review.summary }} <strong>{{ review.rating }}/5</strong>
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="selectedProductReview" class="mt-2 cursor-pointer">
                <a [routerLink]="['/extensions', 'product-reviews', selectedProductReview.id]">
                    <span>Selected Review</span><br>
                    <span><strong>{{ selectedProductReview.rating }}/5 </strong> {{ selectedProductReview.summary }}</span><br>
                    <span>Product: {{ selectedProduct?.name }}</span>
                </a>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class ProductReviewSelectorComponent implements OnInit, OnDestroy, CustomFieldControl {
    @Input() readonly: boolean;
    @Input() formControl: FormControl;
    @Input() config: any;

    productControl = new UntypedFormControl();
    productReviews$: Observable<ProductReview[] | undefined>;
    allProductReviews: ProductReview[];

    productConfig = {
        type: 'relation',
        list: false,
        entity: 'Product',
        ui: {
            component: 'relation-form-input',
        },
        label: 'Select a Product for reviews',
    };
    selectedProduct: Product | null = null;
    selectedProductReview: ProductReview | null = null;

    private subscriptions: Subscription[] = [];

    constructor(
        private dataService: DataService,
        private changeDetector: ChangeDetectorRef,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        // Subscribe to productControl.valueChanges
        const productControlSub = this.productControl.valueChanges
            .pipe(
                filter(product => !!product),
                switchMap(product => {
                    this.selectedProduct = product;
                    return this.loadProductReviews(product.id);
                })
            )
            .subscribe();
        this.subscriptions.push(productControlSub);

        // Subscribe to formControl.valueChanges to update selectedProductReview
        const formControlSub = this.formControl.valueChanges.subscribe(value => {
            if (value) {
                this.loadProductReviewAndProduct(value);
            } else {
                this.selectedProductReview = null;
                this.changeDetector.markForCheck();
            }
        });
        this.subscriptions.push(formControlSub);

        // Load initial data if formControl has a value
        if (this.formControl.value) {
            this.loadProductReviewAndProduct(this.formControl.value);
        }
    }

    ngOnDestroy() {
        // Clean up subscriptions to prevent memory leaks
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private loadProductReviewAndProduct(productReviewId: string) {
        this.dataService
            .query<GetProductReviewQuery>(GET_PRODUCT_REVIEW, { productReviewId })
            .mapSingle(data => data?.productReview)
            .pipe(take(1))
            .subscribe(
                async productReview => {
                    if (productReview) {
                        this.selectedProductReview = productReview;
                        this.selectedProduct = productReview.product;
                        this.productControl.setValue(this.selectedProduct);
                        this.changeDetector.markForCheck();
                    }
                },
                error => {
                    this.notificationService.error('Failed to load the selected product review');
                }
            );
    }

    private loadProductReviews(productId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.productReviews$ = this.dataService
                .query<Query['productReviews']>(GET_REVIEWS_FOR_PRODUCT, { productId })
                .mapSingle((data:any) => data?.product?.reviews.items)
                .pipe(take(1));
            this.productReviews$.subscribe(
                values => {
                    if (values) {
                        this.allProductReviews = values;
                        this.changeDetector.markForCheck();
                        resolve();
                    } else {
                        reject('No Product Reviews found');
                    }
                },
                error => {
                    this.notificationService.error('Failed to load product reviews');
                    reject(error);
                }
            );
        });
    }
}
