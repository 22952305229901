import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  StringCustomFieldConfig,
  SharedModule,
  FormInputComponent,
  NotificationService,
  DataService
} from '@vendure/admin-ui/core';
import { Asset } from '../../common/ui/generated-types';
import { AboutPageData, defaultAboutPageData } from '../../common/ui/ui-types';
import { safeJSONParse } from '../../common/ui/utils';

@Component({
  template: `
    <vdr-card>
      <!-- Poster Asset -->
      <vdr-form-field [label]="'Poster'">
        <vdr-dynamic-form-input
          [control]="posterAssetControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Title -->
      <vdr-form-field [label]="'Title'">
        <input type="text" [formControl]="titleControl">
      </vdr-form-field>

      <!-- Text (Rich-text Editor) -->
      <vdr-form-field [label]="'Text'">
        <vdr-rich-text-editor [formControl]="textControl">
        </vdr-rich-text-editor>
      </vdr-form-field>

      <!-- Citation (Rich-text Editor) -->
      <vdr-form-field [label]="'Citation'">
        <vdr-rich-text-editor [formControl]="citationControl">
        </vdr-rich-text-editor>
      </vdr-form-field>

      <!-- Featured Asset -->
      <vdr-form-field [label]="'Featured Asset'">
        <vdr-dynamic-form-input
          [control]="featuredAssetControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class AboutPageFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  aboutPageData: AboutPageData | null;

  posterAssetControl = new UntypedFormControl();
  titleControl = new FormControl();
  textControl = new FormControl();
  citationControl = new FormControl();
  featuredAssetControl = new UntypedFormControl();

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.aboutPageData = safeJSONParse<AboutPageData>(
      this.formControl.value,
      this.notificationService,
      defaultAboutPageData,
    );
    this.initializeFormControls();
    // Load posterAsset
    if (this.aboutPageData?.posterAssetId) {
      this.dataService.product
        .getAsset(this.aboutPageData.posterAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.posterAssetControl.setValue(asset);
        });
    } else {
      this.posterAssetControl.setValue(null);
    }
    // Load featuredAsset
    if (this.aboutPageData?.featuredAssetId) {
      this.dataService.product
        .getAsset(this.aboutPageData.featuredAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.featuredAssetControl.setValue(asset);
        });
    } else {
      this.featuredAssetControl.setValue(null);
    }
  }

  ngAfterViewInit() {
    this.posterAssetControl.valueChanges.subscribe(this.onPosterAssetChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.textControl.valueChanges.subscribe(this.onTextChange);
    this.citationControl.valueChanges.subscribe(this.onCitationChange);
    this.featuredAssetControl.valueChanges.subscribe(this.onFeaturedAssetChange);
  }

  private initializeFormControls() {
    this.titleControl.setValue(this.aboutPageData?.title);
    this.textControl.setValue(this.aboutPageData?.text);
    this.citationControl.setValue(this.aboutPageData?.citation);
  }

  private onPosterAssetChange = (value: Asset) => {
    const posterAssetId = value ? value.id : null;
    if (this.aboutPageData?.posterAssetId !== posterAssetId) {
      this.aboutPageData!.posterAssetId = posterAssetId;
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.aboutPageData?.title !== value) {
      this.aboutPageData!.title = value;
      this.updateJsonString();
    }
  };

  private onTextChange = (value: string) => {
    if (this.aboutPageData?.text !== value) {
      this.aboutPageData!.text = value;
      this.updateJsonString();
    }
  };

  private onCitationChange = (value: string) => {
    if (this.aboutPageData?.citation !== value) {
      this.aboutPageData!.citation = value;
      this.updateJsonString();
    }
  };

  private onFeaturedAssetChange = (value: Asset) => {
    const featuredAssetId = value ? value.id : null;
    if (this.aboutPageData?.featuredAssetId !== featuredAssetId) {
      this.aboutPageData!.featuredAssetId = featuredAssetId;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.aboutPageData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
