import gql from 'graphql-tag';



export const ASSET_FRAGMENT = gql`
    fragment Asset on Asset {
        id
        createdAt
        updatedAt
        name
        fileSize
        mimeType
        type
        preview
        source
        width
        height
        focalPoint {
            x
            y
        }
    }
`;

export const UPLOAD_RESULT_FRAGMENT = gql`
  fragment UploadResultFragment on VimeoUploadResult {
    asset {
      ...Asset
    }
    error
  }
  ${ASSET_FRAGMENT}
`;

export const VIMEO_UPLOAD_VIDEO = gql`
  mutation VimeoUploadVideo($file: Upload!) {
    vimeoUpload(file: $file) {
      ...UploadResultFragment
    }
  }
  ${UPLOAD_RESULT_FRAGMENT}
`;

export const CREATE_VIMEO_ASSET = gql`
  mutation CreateVimeoAsset($videoUrl: String!) {
    createVimeoAsset(videoUrl: $videoUrl) {
      ...Asset
    }
  }
  ${ASSET_FRAGMENT}
`;


export const DELETE_ASSETS = gql`
    mutation DeleteAssets($input: DeleteAssetsInput!) {
        deleteAssets(input: $input) {
            result
            message
        }
    }
`;
