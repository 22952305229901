export const extensionRoutes = [  {
    path: 'extensions/returns',
    loadChildren: () => import('./extensions/return-request-extension/routes'),
  },
  {
    path: 'extensions/vendors',
    loadChildren: () => import('./extensions/multivendor-extension/routes'),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/product-reviews/routes'),
  },
  {
    path: 'extensions/store-sites',
    loadChildren: () => import('./extensions/store-sites/routes'),
  }];
