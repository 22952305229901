import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import {
  SharedModule,
  StringCustomFieldConfig,
  DynamicFormInputComponent,
  DataService,
  NotificationService
} from '@vendure/admin-ui/core';
import { ID } from '@vendure/core';
import { forkJoin, Observable, of } from 'rxjs';
import {
  BannerButtonData,
  KeySellingPointData,
  HeroSectionData,
  MarketingBannerData,
  MarketingBannerType,
  MobileMediaPosition,
  defaultMarketingBannerData,
  TextAlignment,
  ButtonPositionType
} from '../ui-types';
import { safeJSONParse } from '../utils';
import { Asset } from '../generated-types';

@Component({
  selector: 'vdr-marketing-banner-input',
  template: `
  <vdr-card>
      <vdr-form-field [label]="'Type'">
        <select [formControl]="bannerTypeControl">
          <option value="left-media-right-text">Left Media Right Text</option>
          <option value="left-text-right-media">Left Text Right Media</option>
          <option value="top-text-below-media">Top Text Below Media</option>
          <option value="media-only">Media Only</option>
          <option value="left-media-slide-with-background">Left Media Slide with Background</option>
          <option value="right-media-slide-with-background">Right Media Slide with Background</option>
          <option value="titled-media">Titled Media</option>
          <option value="key-point-list">Key Point List</option>
          <option value="hero-section">Hero Section</option>
          <option value="external-html-content">External HTML Content</option>
        </select>
      </vdr-form-field>
      <div *ngIf="bannerTypeControl.value === 'left-media-right-text' || bannerTypeControl.value === 'left-text-right-media' || bannerTypeControl.value === 'key-point-list' || bannerTypeControl.value === 'top-text-below-media' || bannerTypeControl.value === 'titled-media'">
        <vdr-form-field [label]="'Background Color'">
          <input type="color" [formControl]="backgroundColorControl">
        </vdr-form-field>
        <vdr-form-field [label]="'Text Color'">
          <input type="color" [formControl]="textColorControl">
        </vdr-form-field>
      </div>
      <div *ngIf="bannerTypeControl.value === 'top-text-below-media' || bannerTypeControl.value === 'titled-media' || bannerTypeControl.value === 'left-media-right-text' || bannerTypeControl.value === 'left-text-right-media'">
        <vdr-form-field [label]="'Text Alignment'">
          <select [formControl]="textAlignmentControl">
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </vdr-form-field>
      </div>
      <div *ngIf="bannerTypeControl.value === 'top-text-below-media' || bannerTypeControl.value === 'left-media-right-text' || bannerTypeControl.value === 'left-text-right-media'">
        <vdr-form-field [label]="'Text Width (0-1)'">
          <input type="number"
                [formControl]="textWidthControl"
                min="0"
                max="1"
                step="0.01">
        </vdr-form-field>
        <vdr-form-field [label]="'Text Indentation (px)'">
          <input type="number"
                [formControl]="textIndentationControl"
                min="0">
        </vdr-form-field>
      </div>
      <div *ngIf="bannerTypeControl.value === 'key-point-list' || bannerTypeControl.value === 'hero-section' || bannerTypeControl.value === 'external-html-content'; else otherForm">
        <ng-container *ngIf="bannerTypeControl.value === 'key-point-list'">
          <vdr-form-field [label]="'Key Points Layout Type'">
            <select [formControl]="keyPointsLayoutTypeControl">
              <option value="horizontal">Horizontal</option>
              <option value="vertical">Vertical</option>
            </select>
          </vdr-form-field>
          <vdr-form-field [label]="'Key Points'">
            <vdr-dynamic-form-input #keyPointsInput
              [control]="keyPointsControl"
              [def]="{
                list: true,
                type: 'string',
                ui: {
                  component: 'key-selling-point-form'
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>
        </ng-container>
        <ng-container *ngIf="bannerTypeControl.value === 'hero-section'">
          <vdr-form-field [label]="'Hero Section'">
            <vdr-dynamic-form-input #heroSectionInput
              [control]="heroSectionControl"
              [def]="{
                type: 'string',
                ui: {
                  component: 'hero-section-input-form'
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>
        </ng-container>
        <ng-container *ngIf="bannerTypeControl.value === 'external-html-content'">
          <vdr-form-field [label]="'External HTML Content'">
              <vdr-dynamic-form-input #externalHtmlContentInput
                  [control]="externalHtmlContentControl"
                  [def]="{
                      type: 'string',
                      ui: {
                          component: 'external-html-content-input'
                      }
                  }"
              >
              </vdr-dynamic-form-input>
          </vdr-form-field>
        </ng-container>
      </div>
      <ng-template #otherForm>
        <vdr-form-field [label]="'Medias'">
          <vdr-dynamic-form-input #mediasInput
            [control]="mediasControl"
            [def]="{
              list: true,
              type: 'relation',
              entity: 'Asset',
              ui: {
                component: 'relation-form-input',
              }
            }"
          >
          </vdr-dynamic-form-input>
        </vdr-form-field>
        <vdr-form-field [label]="'Mobile Medias'">
          <vdr-dynamic-form-input #mobileMediasInput
            [control]="mobileMediasControl"
            [def]="{
              list: true,
              type: 'relation',
              entity: 'Asset',
              ui: {
                component: 'relation-form-input',
              }
            }"
          >
          </vdr-dynamic-form-input>
        </vdr-form-field>
        <vdr-form-field [label]="'Mobile Media Position'">
          <select [formControl]="mobileMediaPositionControl">
            <option value="top">Top</option>
            <option value="middle">Middle</option>
            <option value="bottom">Bottom</option>
          </select>
        </vdr-form-field>
        <div *ngIf="bannerTypeControl.value !== 'media-only' && bannerTypeControl.value !== 'left-media-slide-with-background' && bannerTypeControl.value !== 'right-media-slide-with-background'">
          <vdr-form-field [label]="'Title'">
            <input type="text" [formControl]="titleControl">
          </vdr-form-field>
        </div>
        <div *ngIf="bannerTypeControl.value !== 'media-only' && bannerTypeControl.value !== 'left-media-slide-with-background' && bannerTypeControl.value !== 'right-media-slide-with-background' && bannerTypeControl.value !== 'titled-media'">
          <vdr-rich-text-editor
            [label]="'Text'"
            [formControl]="textControl">
          </vdr-rich-text-editor>
        </div>
        <div *ngIf="bannerTypeControl.value === 'left-media-right-text' || bannerTypeControl.value === 'left-text-right-media' || bannerTypeControl.value === 'left-media-slide-with-background' || bannerTypeControl.value === 'right-media-slide-with-background'">
          <vdr-form-field [label]="'Left Right Part Ratio'">
            <input type="number" [formControl]="leftRightPartRatioControl">
          </vdr-form-field>
          <vdr-form-field [label]="'Shadow Transparency'">
            <input type="number" [formControl]="shadowTransparencyControl">
          </vdr-form-field>
          <vdr-form-field [label]="'Background Media'">
            <vdr-dynamic-form-input
              [control]="backgroundMediaControl"
              [def]="{
                type: 'relation',
                entity: 'Asset',
                ui: {
                  component: 'relation-form-input',
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>
          <vdr-form-field [label]="'Background Mobile Media'">
            <vdr-dynamic-form-input
              [control]="backgroundMobileMediaControl"
              [def]="{
                type: 'relation',
                entity: 'Asset',
                ui: {
                  component: 'relation-form-input',
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>
        </div>

        <!-- Button Config -->
        <div class="clr-row" *ngIf="marketingBannerData?.button?.enabled">
          <vdr-form-field [label]="'Position (Desktop)'">
            <select [formControl]="buttonPositionControl">
              <option *ngIf="bannerTypeControl.value === 'left-text-right-media' || bannerTypeControl.value === 'left-media-right-text'" value="below-text">Below Text</option>
              <option value="custom">Custom</option>
            </select>
          </vdr-form-field>
          <div class="clr-col-4" *ngIf="(bannerTypeControl.value !== 'left-text-right-media' && bannerTypeControl.value !== 'left-media-right-text') || buttonPositionControl.value === 'custom'">
            <vdr-form-field [label]="'Location X (Desktop)'">
              <input type="number" [formControl]="buttonLocationXControl" min="0" max="1" step="0.01">
            </vdr-form-field>
          </div>
          <div class="clr-col-4" *ngIf="(bannerTypeControl.value !== 'left-text-right-media' && bannerTypeControl.value !== 'left-media-right-text') || buttonPositionControl.value === 'custom'">
            <vdr-form-field [label]="'Location Y (Desktop)'">
              <input type="number" [formControl]="buttonLocationYControl" min="0" max="1" step="0.01">
            </vdr-form-field>
          </div>
        </div>

        <div class="clr-row" *ngIf="marketingBannerData?.button?.enabled">
          <vdr-form-field [label]="'Position (Mobile)'">
            <select [formControl]="buttonPositionMobileControl">
              <option *ngIf="bannerTypeControl.value === 'left-text-right-media' || bannerTypeControl.value === 'left-media-right-text'" value="below-text">Below Text</option>
              <option value="custom">Custom</option>
            </select>
          </vdr-form-field>
          <div class="clr-col-4" *ngIf="(bannerTypeControl.value !== 'left-text-right-media' && bannerTypeControl.value !== 'left-media-right-text') || buttonPositionMobileControl.value === 'custom'">
            <vdr-form-field [label]="'Location X (Mobile)'">
              <input type="number" [formControl]="buttonLocationXMobileControl" min="0" max="1" step="0.01">
            </vdr-form-field>
          </div>
          <div class="clr-col-4" *ngIf="(bannerTypeControl.value !== 'left-text-right-media' && bannerTypeControl.value !== 'left-media-right-text') || buttonPositionMobileControl.value === 'custom'">
            <vdr-form-field [label]="'Location Y (Mobile)'">
              <input type="number" [formControl]="buttonLocationYMobileControl" min="0" max="1" step="0.01">
            </vdr-form-field>
          </div>
        </div>

        <div>
          <vdr-form-field [label]="'Button Config'">
            <vdr-dynamic-form-input
              [control]="buttonControl"
              [def]="{
                type: 'string',
                ui: {
                  component: 'banner-button-form',
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>
        </div>
      </ng-template>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class MarketingBannerInputComponent implements OnInit, AfterViewInit {
  @ViewChild('mediasInput') mediasInput: DynamicFormInputComponent;
  @ViewChild('mobileMediasInput') mobileMediasInput: DynamicFormInputComponent;
  @ViewChild('keyPointsInput') keyPointsInput: DynamicFormInputComponent;

  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  bannerTypeControl = new FormControl();
  mediasControl = new UntypedFormControl();
  mobileMediasControl = new UntypedFormControl();
  mobileMediaPositionControl = new FormControl();
  titleControl = new FormControl();
  textControl = new FormControl();
  leftRightPartRatioControl = new FormControl();
  shadowTransparencyControl = new FormControl();
  backgroundMediaControl = new FormControl();
  backgroundMobileMediaControl = new FormControl();
  backgroundColorControl = new FormControl();
  textColorControl = new FormControl();
  textAlignmentControl = new FormControl();
  textWidthControl = new FormControl();
  textIndentationControl = new FormControl();
  buttonControl = new UntypedFormControl();
  buttonPositionControl = new FormControl();
  buttonLocationXControl = new FormControl();
  buttonLocationYControl = new FormControl();
  buttonPositionMobileControl = new FormControl();
  buttonLocationXMobileControl = new FormControl();
  buttonLocationYMobileControl = new FormControl();
  keyPointsLayoutTypeControl = new FormControl();
  keyPointsControl = new UntypedFormControl([]);
  heroSectionControl = new UntypedFormControl();
  externalHtmlContentControl = new UntypedFormControl();

  marketingBannerData: MarketingBannerData | null;

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService) {}

  ngOnInit() {
    this.marketingBannerData = safeJSONParse<MarketingBannerData>(
      this.formControl.value,
      this.notificationService,
      defaultMarketingBannerData,
    );
    this.initializeFormControls();

    // Load assets based on IDs
    if (this.marketingBannerData && this.marketingBannerData?.mediaIds?.length > 0) {
      this.loadAssetsByIds(this.marketingBannerData.mediaIds).subscribe((assets) => {
        this.mediasControl.setValue(assets);
        if (this.mediasInput) {
          this.mediasInput.writeValue(assets);
        }
      });
    } else {
      this.mediasControl.setValue([]);
    }

    if (this.marketingBannerData && this.marketingBannerData?.mobileMediaIds?.length > 0) {
      this.loadAssetsByIds(this.marketingBannerData.mobileMediaIds).subscribe((assets) => {
        this.mobileMediasControl.setValue(assets);
        if (this.mobileMediasInput) {
          this.mobileMediasInput.writeValue(assets);
        }
      });
    } else {
      this.mobileMediasControl.setValue([]);
    }

    if (this.marketingBannerData?.backgroundMediaId) {
      this.dataService.product
        .getAsset(this.marketingBannerData.backgroundMediaId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.backgroundMediaControl.setValue(asset);
        });
    }

    if (this.marketingBannerData?.backgroundMobileMediaId) {
      this.dataService.product
        .getAsset(this.marketingBannerData.backgroundMobileMediaId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.backgroundMobileMediaControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    if (this.mediasInput) {
      this.mediasInput.registerOnChange(this.onMediasChange);
    }
    if (this.mobileMediasInput) {
      this.mobileMediasInput.registerOnChange(this.onMobileMediasChange);
    }
    if (this.keyPointsInput) {
      this.keyPointsInput.registerOnChange(this.onKeyPointsChange);
      const keyPoints = this.marketingBannerData?.keyPoints?.map((value) => JSON.stringify(value)) || [];
      this.keyPointsInput.writeValue(keyPoints);
    }

    // Subscriptions for value changes
    this.bannerTypeControl.valueChanges.subscribe(this.onTypeChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.textControl.valueChanges.subscribe(this.onTextChange);
    this.leftRightPartRatioControl.valueChanges.subscribe(this.onLeftRightPartRatioChange);
    this.shadowTransparencyControl.valueChanges.subscribe(this.onShadowTransparencyChange);
    this.backgroundMediaControl.valueChanges.subscribe(this.onBackgroundMediaChange);
    this.backgroundMobileMediaControl.valueChanges.subscribe(this.onBackgroundMobileMediaChange);
    this.backgroundColorControl.valueChanges.subscribe(this.onBackgroundColorChange);
    this.textColorControl.valueChanges.subscribe(this.onTextColorChange);
    this.textAlignmentControl.valueChanges.subscribe(this.onTextAlignmentChange);
    this.textWidthControl.valueChanges.subscribe(this.onTextWidthChange);
    this.textIndentationControl.valueChanges.subscribe(this.onTextIndentationChange);
    this.buttonControl.valueChanges.subscribe(this.onButtonChange);
    this.heroSectionControl.valueChanges.subscribe(this.onHeroSectionChange);
    this.externalHtmlContentControl.valueChanges.subscribe(this.onExternalHtmlContentChange);
    this.keyPointsLayoutTypeControl.valueChanges.subscribe(this.onKeyPointsLayoutTypeChange);
    this.mobileMediaPositionControl.valueChanges.subscribe(this.onMobileMediaPositionChange);
    this.buttonPositionControl.valueChanges.subscribe(this.onButtonPositionChange);
    this.buttonLocationXControl.valueChanges.subscribe(this.onButtonLocationXChange);
    this.buttonLocationYControl.valueChanges.subscribe(this.onButtonLocationYChange);
    this.buttonPositionMobileControl.valueChanges.subscribe(this.onButtonPositionMobileChange);
    this.buttonLocationXMobileControl.valueChanges.subscribe(this.onButtonLocationXMobileChange);
    this.buttonLocationYMobileControl.valueChanges.subscribe(this.onButtonLocationYMobileChange);
  }

  private initializeFormControls() {
    this.bannerTypeControl.setValue(this.marketingBannerData?.type);
    // mediasControl and mobileMediasControl are set after loading assets
    this.mobileMediaPositionControl.setValue(this.marketingBannerData?.mobileMediaPosition);
    this.titleControl.setValue(this.marketingBannerData?.title);
    this.textControl.setValue(this.marketingBannerData?.text);
    this.leftRightPartRatioControl.setValue(this.marketingBannerData?.leftRightPartRatio);
    this.shadowTransparencyControl.setValue(this.marketingBannerData?.shadowTransparency);
    // backgroundMediaControl and backgroundMobileMediaControl are set after loading assets
    this.backgroundColorControl.setValue(this.marketingBannerData?.backgroundColor);
    this.textColorControl.setValue(this.marketingBannerData?.textColor);
    this.textAlignmentControl.setValue(this.marketingBannerData?.textAlignment);
    this.textWidthControl.setValue(this.marketingBannerData?.textWidth || 1);
    this.textIndentationControl.setValue(this.marketingBannerData?.textIndentation || 0);
    this.buttonControl.setValue(JSON.stringify(this.marketingBannerData?.button));
    this.buttonPositionControl.setValue(this.marketingBannerData?.buttonPosition);
    this.buttonLocationXControl.setValue(this.marketingBannerData?.buttonLocationX);
    this.buttonLocationYControl.setValue(this.marketingBannerData?.buttonLocationY);
    this.buttonPositionMobileControl.setValue(this.marketingBannerData?.buttonPositionMobile);
    this.buttonLocationXMobileControl.setValue(this.marketingBannerData?.buttonLocationXMobile);
    this.buttonLocationYMobileControl.setValue(this.marketingBannerData?.buttonLocationYMobile);
    this.keyPointsLayoutTypeControl.setValue(this.marketingBannerData?.keyPointsLayoutType);
    const keyPoints = this.marketingBannerData?.keyPoints?.map((value) => JSON.stringify(value), []);
    this.keyPointsControl.setValue(keyPoints);
    this.heroSectionControl.setValue(JSON.stringify(this.marketingBannerData?.heroSection || {}));
    this.externalHtmlContentControl.setValue(JSON.stringify(this.marketingBannerData?.externalHtmlContent || {}));
  }

  private loadAssetsByIds(ids: ID[]): Observable<Asset[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    }
    return forkJoin(ids?.map((id) => this.dataService.product.getAsset(id.toString())?.mapSingle((data) => data.asset))) as Observable<Asset[]>;
  }

  private areArraysEqual(array1: ID[], array2: ID[]): boolean {
    return (
      array1?.length === array2?.length && array1?.every((value) => array2?.includes(value))
    );
  }

  private onMediasChange = (assets: Asset[]) => {
    const assetIds = assets?.map((asset) => asset.id);
    if (!this.areArraysEqual(this.marketingBannerData?.mediaIds || [], assetIds)) {
      this.marketingBannerData!.mediaIds = assetIds;
      this.updateJsonString();
    }
  };

  private onMobileMediasChange = (assets: Asset[]) => {
    const assetIds = assets?.map((asset) => asset.id);
    if (!this.areArraysEqual(this.marketingBannerData?.mobileMediaIds ||[], assetIds)) {
      this.marketingBannerData!.mobileMediaIds = assetIds;
      this.updateJsonString();
    }
  };

  private onBackgroundMediaChange = (asset: Asset | null) => {
    const assetId = asset ? asset.id : null;
    if (this.marketingBannerData?.backgroundMediaId !== assetId) {
      this.marketingBannerData!.backgroundMediaId = assetId;
      this.updateJsonString();
    }
  };

  private onBackgroundMobileMediaChange = (asset: Asset | null) => {
    const assetId = asset ? asset.id : null;
    if (this.marketingBannerData?.backgroundMobileMediaId !== assetId) {
      this.marketingBannerData!.backgroundMobileMediaId = assetId;
      this.updateJsonString();
    }
  };

  private onTypeChange = (value: MarketingBannerType) => {
    if (this.marketingBannerData?.type !== value) {
      this.marketingBannerData!.type = value;
      if(value === MarketingBannerType.LeftMediaRightText || value === MarketingBannerType.LeftTextRightMedia) {
        this.marketingBannerData!.textAlignment = TextAlignment.Left;
        this.textAlignmentControl.setValue(TextAlignment.Left);
        this.marketingBannerData!.buttonPosition = ButtonPositionType.BelowText;
        this.buttonPositionControl.setValue(ButtonPositionType.BelowText);
        this.marketingBannerData!.buttonPositionMobile = ButtonPositionType.BelowText;
        this.buttonPositionMobileControl.setValue(ButtonPositionType.BelowText);
      } else if(value === MarketingBannerType.TopTextBelowMedia || value === MarketingBannerType.TitledMedia) {
        this.marketingBannerData!.textAlignment = TextAlignment.Center;
        this.textAlignmentControl.setValue(TextAlignment.Center);
        this.marketingBannerData!.buttonPosition = ButtonPositionType.Custom;
        this.buttonPositionControl.setValue(ButtonPositionType.Custom);
        this.marketingBannerData!.buttonPositionMobile = ButtonPositionType.Custom;
        this.buttonPositionMobileControl.setValue(ButtonPositionType.Custom);
      }
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.marketingBannerData?.title !== value) {
      this.marketingBannerData!.title = value;
      this.updateJsonString();
    }
  };

  private onTextChange = (value: string) => {
    if (this.marketingBannerData?.text !== value) {
      this.marketingBannerData!.text = value;
      this.updateJsonString();
    }
  };

  private onTextAlignmentChange = (value: TextAlignment) => {
    if (this.marketingBannerData?.textAlignment !== value) {
      this.marketingBannerData!.textAlignment = value;
      this.updateJsonString();
    }
  };

  private onLeftRightPartRatioChange = (value: number) => {
    if (this.marketingBannerData?.leftRightPartRatio !== value) {
      this.marketingBannerData!.leftRightPartRatio = value;
      this.updateJsonString();
    }
  };

  private onShadowTransparencyChange = (value: number) => {
    if (this.marketingBannerData?.shadowTransparency !== value) {
      this.marketingBannerData!.shadowTransparency = value;
      this.updateJsonString();
    }
  };

  private onBackgroundColorChange = (value: string) => {
    if (this.marketingBannerData?.backgroundColor !== value) {
      this.marketingBannerData!.backgroundColor = value;
      this.updateJsonString();
    }
  };

  private onTextColorChange = (value: string) => {
    if (this.marketingBannerData?.textColor !== value) {
      this.marketingBannerData!.textColor = value;
      this.updateJsonString();
    }
  };

  private onTextWidthChange = (value: number) => {
    if (this.marketingBannerData?.textWidth !== value) {
      this.marketingBannerData!.textWidth = value;
      this.updateJsonString();
    }
  };

  private onTextIndentationChange = (value: number) => {
    if (this.marketingBannerData?.textIndentation !== value) {
      this.marketingBannerData!.textIndentation = value;
      this.updateJsonString();
    }
  };

  private onButtonChange = (value: string) => {
    if (this.marketingBannerData && JSON.stringify(this.marketingBannerData.button) !== value) {
      this.marketingBannerData.button = safeJSONParse(value, this.notificationService);
      this.updateJsonString();
    }
  };

  private onButtonPositionChange = (value: ButtonPositionType) => {
    if (this.marketingBannerData?.buttonPosition !== value) {
      this.marketingBannerData!.buttonPosition = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationXChange = (value: number) => {
    if (this.marketingBannerData?.buttonLocationX !== value) {
      this.marketingBannerData!.buttonLocationX = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationYChange = (value: number) => {
    if (this.marketingBannerData?.buttonLocationY !== value) {
      this.marketingBannerData!.buttonLocationY = value;
      this.updateJsonString();
    }
  };

  private onButtonPositionMobileChange = (value: ButtonPositionType) => {
    if (this.marketingBannerData?.buttonPositionMobile !== value) {
      this.marketingBannerData!.buttonPositionMobile = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationXMobileChange = (value: number) => {
    if (this.marketingBannerData?.buttonLocationXMobile !== value) {
      this.marketingBannerData!.buttonLocationXMobile = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationYMobileChange = (value: number) => {
    if (this.marketingBannerData?.buttonLocationYMobile !== value) {
      this.marketingBannerData!.buttonLocationYMobile = value;
      this.updateJsonString();
    }
  };


  private onHeroSectionChange = (value: string) => {
    if (this.marketingBannerData && JSON.stringify(this.marketingBannerData.heroSection) !== value) {
      this.marketingBannerData.heroSection = safeJSONParse(value, this.notificationService);
      this.updateJsonString();
    }
  };

  private onExternalHtmlContentChange = (value: string) => {
    if (this.marketingBannerData && JSON.stringify(this.marketingBannerData.externalHtmlContent) !== value) {
      this.marketingBannerData.externalHtmlContent = safeJSONParse(value, this.notificationService);
      this.updateJsonString();
    }
  };
  private onKeyPointsLayoutTypeChange = (value: string) => {
    if (this.marketingBannerData?.keyPointsLayoutType !== value) {
      this.marketingBannerData!.keyPointsLayoutType = value;
      this.updateJsonString();
    }
  };

  private onKeyPointsChange = (values: string[]) => {
    if (this.marketingBannerData && JSON.stringify(this.marketingBannerData.keyPoints) !== JSON.stringify(values)) {
      this.marketingBannerData.keyPoints = values?.map(
        (value) => safeJSONParse(value, this.notificationService)
      );
      this.updateJsonString();
    }
  };

  private onMobileMediaPositionChange = (value: MobileMediaPosition) => {
    if (this.marketingBannerData?.mobileMediaPosition !== value) {
      this.marketingBannerData!.mobileMediaPosition = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.marketingBannerData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}