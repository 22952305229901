import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

// Define the interface for layout option data
interface LayoutOptionData {
  sectionName: string;
  layoutComponentType: string;
  seqNumberOfList: number;
  width: number;
  mobileWidth: number;
  topMargin: number;
  bottomMargin: number;
  mobileTopMargin: number;
  mobileBottomMargin: number;
}

@Component({
  selector: 'nav-menu-option-input',
  template: `
    <vdr-card>
      <div class="form-row">
        <!-- Section Name Input Field -->
        <vdr-form-field [label]="'Section Name'">
          <input
            type="text"
            [formControl]="sectionNameControl"
            placeholder="Enter section name"
          >
        </vdr-form-field>

        <!-- Layout Component Type Dropdown -->
        <vdr-form-field [label]="'Layout Component Type'">
          <select [formControl]="layoutComponentTypeControl">
            <option value="hero-section">Hero Section</option>
            <option value="customer-review-section">Customer Reviews</option>
            <option value="email-subscription-section">Email Subscription Section</option>
            <option value="unique-selling-point">Marketing Banners</option>
            <option value="featured-product-collection">Featured Product Collection</option>
            <option value="collection-list-for-shopby">Collection List For Shop By</option>
            <option value="collection-for-series">Collection For Series</option>
            <option value="product-info">Product Info Section</option>
          </select>
        </vdr-form-field>
        <!-- Seq Number Input, conditionally shown based on the selected component type -->
        <div *ngIf="shouldShowSeqNumber()">
          <vdr-form-field [label]="'Seq Number of List (start from 0)'">
            <input type="number" [formControl]="seqNumberOfListControl">
          </vdr-form-field>
        </div>
      </div>
      <div class="form-row">
        <!-- Width Input Field -->
        <vdr-form-field [label]="'Width (0-1)'">
          <input
            type="number"
            [formControl]="widthControl"
            min="0"
            max="1"
            step="0.01"
          >
        </vdr-form-field>

        <!-- Top Margin Input Field -->
        <vdr-form-field [label]="'Top Margin'">
          <input
            type="number"
            [formControl]="topMarginControl"
            min="0"
            step="1"
          >
        </vdr-form-field>

        <!-- Bottom Margin Input Field -->
        <vdr-form-field [label]="'Bottom Margin'">
          <input
            type="number"
            [formControl]="bottomMarginControl"
            min="0"
            step="1"
          >
        </vdr-form-field>
      </div>

      <!-- Second Row: Mobile Width, Mobile Top Margin, Mobile Bottom Margin -->
      <div class="form-row">
        <!-- Mobile Width Input Field -->
        <vdr-form-field [label]="'Mobile Width (0-1)'">
          <input
            type="number"
            [formControl]="mobileWidthControl"
            min="0"
            max="1"
            step="0.01"
          >
        </vdr-form-field>

        <!-- Mobile Top Margin Input Field -->
        <vdr-form-field [label]="'Mobile Top Margin'">
          <input
            type="number"
            [formControl]="mobileTopMarginControl"
            min="0"
            step="1"
          >
        </vdr-form-field>

        <!-- Mobile Bottom Margin Input Field -->
        <vdr-form-field [label]="'Mobile Bottom Margin'">
          <input
            type="number"
            [formControl]="mobileBottomMarginControl"
            min="0"
            step="1"
          >
        </vdr-form-field>
      </div>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
  styles: [`
    .form-row {
      display: flex;
      flex-wrap: wrap;
      gap: 16px; /* Adjust spacing between fields */
      margin-bottom: 16px; /* Optional: spacing between rows */
    }
    .form-row vdr-form-field {
      flex: 1;
      min-width: 200px; /* Adjust as needed */
    }
  `],
})
export class LayoutOptionInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  layoutOptionData: LayoutOptionData | null;

  // Form controls
  sectionNameControl = new FormControl();
  layoutComponentTypeControl = new FormControl();
  seqNumberOfListControl = new FormControl();
  widthControl = new FormControl();
  topMarginControl = new FormControl();
  bottomMarginControl = new FormControl();
  mobileWidthControl = new FormControl();
  mobileTopMarginControl = new FormControl();
  mobileBottomMarginControl = new FormControl();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    const defaultLayoutOptionData: LayoutOptionData = {
      sectionName: '',
      layoutComponentType: '',
      seqNumberOfList: 0,
      width: 1,
      topMargin: 0,
      bottomMargin: 0,
      mobileWidth: 1,
      mobileTopMargin: 0,
      mobileBottomMargin: 0,
    };
    this.layoutOptionData = safeJSONParse<LayoutOptionData>(
      this.formControl.value,
      this.notificationService,
      defaultLayoutOptionData,
    );
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    // Subscribe to form control value changes and update layoutOptionData accordingly
    this.sectionNameControl.valueChanges.subscribe(this.onSectionNameChange);
    this.layoutComponentTypeControl.valueChanges.subscribe(this.onLayoutComponentTypeChange);
    this.seqNumberOfListControl.valueChanges.subscribe(this.onSeqNumberOfListChange);
    this.widthControl.valueChanges.subscribe(this.onWidthChange);
    this.topMarginControl.valueChanges.subscribe(this.onTopMarginChange);
    this.bottomMarginControl.valueChanges.subscribe(this.onBottomMarginChange);
    this.mobileWidthControl.valueChanges.subscribe(this.onMobileWidthChange);
    this.mobileTopMarginControl.valueChanges.subscribe(this.onMobileTopMarginChange);
    this.mobileBottomMarginControl.valueChanges.subscribe(this.onMobileBottomMarginChange);
  }

  // Initialize form controls with values from layoutOptionData
  private initializeFormControls() {
    this.sectionNameControl.setValue(this.layoutOptionData?.sectionName);
    this.layoutComponentTypeControl.setValue(this.layoutOptionData?.layoutComponentType);
    this.seqNumberOfListControl.setValue(this.layoutOptionData?.seqNumberOfList);
    this.widthControl.setValue(this.layoutOptionData?.width);
    this.topMarginControl.setValue(this.layoutOptionData?.topMargin);
    this.bottomMarginControl.setValue(this.layoutOptionData?.bottomMargin);
    this.mobileWidthControl.setValue(this.layoutOptionData?.mobileWidth);
    this.mobileTopMarginControl.setValue(this.layoutOptionData?.mobileTopMargin);
    this.mobileBottomMarginControl.setValue(this.layoutOptionData?.mobileBottomMargin);
  }

  // Conditionally display the sequence number input field
  shouldShowSeqNumber(): boolean {
    const selectedType = this.layoutComponentTypeControl.value;
    return [
      'unique-selling-point',
      'featured-product-collection',
      'collection-list-for-shopby',
      'collection-for-series'
    ].includes(selectedType);
  }

  // Value change handlers for form controls
  private onSectionNameChange = (value: string) => {
    if (this.layoutOptionData?.sectionName !== value) {
      this.layoutOptionData!.sectionName = value;
      this.updateJsonString();
    }
  };

  private onLayoutComponentTypeChange = (value: string) => {
    if (this.layoutOptionData?.layoutComponentType !== value) {
      this.layoutOptionData!.layoutComponentType = value;
      this.updateJsonString();
    }
  };

  private onSeqNumberOfListChange = (value: number) => {
    if (this.layoutOptionData?.seqNumberOfList !== value) {
      this.layoutOptionData!.seqNumberOfList = value;
      this.updateJsonString();
    }
  };

  private onWidthChange = (value: number) => {
    if (this.layoutOptionData?.width !== value) {
      this.layoutOptionData!.width = value;
      this.updateJsonString();
    }
  };

  private onTopMarginChange = (value: number) => {
    if (this.layoutOptionData?.topMargin !== value) {
      this.layoutOptionData!.topMargin = value;
      this.updateJsonString();
    }
  };

  private onBottomMarginChange = (value: number) => {
    if (this.layoutOptionData?.bottomMargin !== value) {
      this.layoutOptionData!.bottomMargin = value;
      this.updateJsonString();
    }
  };

  private onMobileWidthChange = (value: number) => {
    if (this.layoutOptionData?.mobileWidth !== value) {
      this.layoutOptionData!.mobileWidth = value;
      this.updateJsonString();
    }
  };

  private onMobileTopMarginChange = (value: number) => {
    if (this.layoutOptionData?.mobileTopMargin !== value) {
      this.layoutOptionData!.mobileTopMargin = value;
      this.updateJsonString();
    }
  };

  private onMobileBottomMarginChange = (value: number) => {
    if (this.layoutOptionData?.mobileBottomMargin !== value) {
      this.layoutOptionData!.mobileBottomMargin = value;
      this.updateJsonString();
    }
  };

  // Update the JSON string and form control value
  private updateJsonString() {
    const jsonString = JSON.stringify(this.layoutOptionData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
