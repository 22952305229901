import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    SharedModule,
    StringCustomFieldConfig,
    FormInputComponent,
    NotificationService,
} from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

interface NavigationItem {
  label: string;
  type: string;
  policyName?: string;
  url: string;
}

enum NavigationItemType {
  POLICY = 'policy',
  ORDER_STATUS = 'order-status',
  CONTACT_US = 'contact-us',
  PAGE = 'page',
  WEBSITE = 'website'
}

enum PolicyName {
  SHIPPING_POLICY = 'shipping-policy',
  RETURN_POLICY = 'return-policy',
  PAYMENT_POLICY = 'payment-policy',
  TERM_OF_SERVICE = 'term-of-service',
  PRIVACY_POLICY = 'privacy-policy',
  COOKIE_POLICY = 'cookie-policy',
  CA_TRANSPARENCY = 'ca-transparency-supply-chains-act',
  ACCESSIBILITY = 'accessibility',
  Disclaimer = 'disclaimer',
  OTHER = 'other'
}


@Component({
  selector: 'vdr-navigation-item-input',
  template: `
    <vdr-card>
      <div class="form-row">
        <!-- Label -->
        <vdr-form-field [label]="'Label'" class="form-item">
          <input type="text" [formControl]="labelControl">
        </vdr-form-field>

        <!-- Type Selector -->
        <vdr-form-field [label]="'Type'" class="form-item">
          <select [formControl]="typeControl">
            <option *ngFor="let type of navigationItemTypes" [value]="type">{{ type }}</option>
          </select>
        </vdr-form-field>
      </div>
      <div class="form-row">
        <!-- Policy Name Selector -->
        <vdr-form-field *ngIf="isPolicyType" [label]="'Policy Name'" class="form-item">
          <select [formControl]="policyNameControl">
            <option *ngFor="let policy of policyNames" [value]="policy">{{ policy }}</option>
          </select>
        </vdr-form-field>

        <!-- URL -->
        <vdr-form-field [label]="urlLabel" *ngIf="!isOrderStatus" class="form-item">
          <input *ngIf="(policyNameControl.value === 'other' || !isPolicyType);else readonlyInput" type="text" [formControl]="urlControl">
          <ng-template #readonlyInput>
            <input type="text" [formControl]="urlControl" readonly>
          </ng-template>

        </vdr-form-field>
      </div>
    </vdr-card>
  `,
  styleUrls: ['../styles.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class NavigationItemInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  labelControl = new FormControl();
  typeControl = new FormControl();
  policyNameControl = new FormControl();
  urlControl = new FormControl();

  navigationItemData: NavigationItem | null;
  navigationItemTypes = Object.values(NavigationItemType);
  policyNames = Object.values(PolicyName);
  urlLabel = 'URL';

  get isPolicyType(): boolean {
    return this.typeControl.value === NavigationItemType.POLICY;
  }
  get isNormalPolicyType(): boolean {
    return (this.typeControl.value === NavigationItemType.POLICY && this.policyNameControl.value !== PolicyName.OTHER);
  }
  get isOrderStatus(): boolean {
    return this.typeControl.value === NavigationItemType.ORDER_STATUS;
  }

  constructor(
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef, ) {}

  ngOnInit() {
    this.navigationItemData = safeJSONParse<NavigationItem>(
      this.formControl.value,
      this.notificationService,
      {
        label: '',
        type: NavigationItemType.PAGE,
        policyName: '',
        url: '',
      }
    );
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    this.labelControl.valueChanges.subscribe(this.onLabelChange);
    this.typeControl.valueChanges.subscribe(this.onTypeChange);
    this.policyNameControl.valueChanges.subscribe(this.onPolicyNameChange);
    this.urlControl.valueChanges.subscribe(this.onUrlChange);
  }

  private initializeFormControls() {
    this.labelControl.setValue(this.navigationItemData?.label);
    this.typeControl.setValue(this.navigationItemData?.type);
    this.policyNameControl.setValue(this.navigationItemData?.policyName);
    this.urlControl.setValue(this.navigationItemData?.url);
    this.updateUrlLabel(this.navigationItemData?.type || 'URL');
  }

  private onLabelChange = (value: string) => {
    if (this.navigationItemData && this.navigationItemData.label !== value) {
      this.navigationItemData.label = value;
      this.updateJsonString();
    }
  };

  private onTypeChange = (value: NavigationItemType) => {
    if (this.navigationItemData && this.navigationItemData.type !== value) {
      this.navigationItemData.type = value;
      this.updateUrlLabel(value);
      this.updateJsonString();
    }
  };

  private updateUrlLabel(value: string) {
    if (value === NavigationItemType.POLICY) {
      this.urlLabel = 'Policy Path';
    } else if(value === NavigationItemType.CONTACT_US) {
      this.urlLabel = 'Contact Us Email';
    } else if(value === NavigationItemType.WEBSITE) {
      this.urlLabel = 'Website URL';
    } else if(value === NavigationItemType.PAGE) {
      this.urlLabel = 'Page URL';
    }
    this.changeDetector.detectChanges();
  }

  private onPolicyNameChange = (value: PolicyName) => {
    if (this.navigationItemData && this.navigationItemData.policyName !== value) {
      this.navigationItemData.policyName = value;
      if(this.navigationItemData.policyName !== PolicyName.OTHER) {
        if (value === 'return-policy') {
          this.urlControl.setValue('return');
        } else if (value === 'shipping-policy') {
            this.urlControl.setValue('shipping');
        } else if (value === 'payment-policy') {
            this.urlControl.setValue('payment');
        } else if (value === 'term-of-service') {
            this.urlControl.setValue('terms');
        } else if (value === 'privacy-policy') {
            this.urlControl.setValue('privacy');
        } else if (value === 'cookie-policy') {
            this.urlControl.setValue('cookie');
        } else if (value === 'ca-transparency-supply-chains-act') {
            this.urlControl.setValue('ca-transparency-supply-chains-act');
        } else if (value === 'accessibility') {
            this.urlControl.setValue('accessibility');
        }
      }
      this.updateJsonString();
    }
  };


  private onUrlChange = (value: string) => {
    if (this.navigationItemData && this.navigationItemData.url !== value) {
      this.navigationItemData.url = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.navigationItemData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
