import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  StringCustomFieldConfig,
  SharedModule,
  FormInputComponent,
  DynamicFormInputComponent,
  NotificationService,
  DataService,
} from '@vendure/admin-ui/core';
import { Asset } from '../../common/ui/generated-types';
import { defaultFaqPageData, FaqPageData, QaData } from '../../common/ui/ui-types';
import { safeJSONParse } from '../../common/ui/utils';

@Component({
  template: `
    <vdr-card>
      <!-- Poster Asset -->
      <vdr-form-field [label]="'Poster'">
        <vdr-dynamic-form-input
          [control]="posterAssetControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Title -->
      <vdr-form-field [label]="'Title'">
        <input type="text" [formControl]="titleControl" />
      </vdr-form-field>

      <!-- QA List -->
      <vdr-form-field [label]="'QA List'">
        <vdr-dynamic-form-input
          #qaListInput
          [control]="qaListControl"
          [def]="{
            type: 'string',
            list: true,
            ui: {
              component: 'question-answer-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class FaqPageFormComponent
  implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit
{
  @ViewChild('qaListInput') qaListInput: DynamicFormInputComponent;
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  faqPageData: FaqPageData | null;

  // Form controls
  posterAssetControl = new UntypedFormControl();
  titleControl = new FormControl();
  qaListControl = new UntypedFormControl();

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.faqPageData = safeJSONParse<FaqPageData>(
      this.formControl.value,
      this.notificationService,
      defaultFaqPageData,
    );
    this.initializeFormControls();

    // Fetch the Asset based on posterAssetId and set it to the form control
    if (this.faqPageData?.posterAssetId) {
      this.dataService.product
        .getAsset(this.faqPageData.posterAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.posterAssetControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    // Subscribe to form control value changes and update faqPageData accordingly
    this.posterAssetControl.valueChanges.subscribe(this.onPosterAssetChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);

    // Register dynamic input changes for QA list
    if (this.qaListInput) {
      this.qaListInput.registerOnChange(this.onQaListChange);
      const qaDataList = this.faqPageData?.qaList?.map((value) => JSON.stringify(value)) || [];
      this.qaListInput.writeValue(qaDataList);
    }
  }

  // Initialize form controls with values from faqPageData
  private initializeFormControls() {
    this.posterAssetControl.setValue(null);
    this.titleControl.setValue(this.faqPageData?.title);
    const qaDataList = this.faqPageData?.qaList?.map((value) => JSON.stringify(value)) || [];
    this.qaListControl.setValue(qaDataList);
  }

  // Value change handlers for form controls
  private onPosterAssetChange = (value: Asset) => {
    const posterAssetId = value ? value.id : null;
    if (this.faqPageData?.posterAssetId !== posterAssetId) {
      this.faqPageData!.posterAssetId = posterAssetId;
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.faqPageData?.title !== value) {
      this.faqPageData!.title = value;
      this.updateJsonString();
    }
  };

  private onQaListChange = (values: string[]) => {
    if (JSON.stringify(this.faqPageData?.qaList) !== JSON.stringify(values)) {
      this.faqPageData!.qaList = values?.map((qaData) => safeJSONParse(qaData, this.notificationService));
      this.updateJsonString();
    }
  };

  // Update the JSON string and form control value
  private updateJsonString() {
    const jsonString = JSON.stringify(this.faqPageData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
