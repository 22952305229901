import { Component } from '@angular/core';
import {
    CustomerFragment,
    CustomerHistoryEntryComponent,
    SharedModule,
    TimelineDisplayType,
    TimelineHistoryEntry,
} from '@vendure/admin-ui/core';

@Component({
    selector: 'return-request-entry',
    template: `
    <ng-container *ngIf="entry">
        <div class="title">
            Return Request ({{ entry.data.RMA }})
        </div>
        {{ entry.data.status }}
        <vdr-history-entry-detail *ngIf="entry.data">
            <vdr-object-tree [value]="entry.data"></vdr-object-tree>
        </vdr-history-entry-detail>
    </ng-container>
    `,
    standalone: true,
    imports: [SharedModule],
})
export class ReturnRequestEntryComponent implements CustomerHistoryEntryComponent {
    entry: TimelineHistoryEntry;
    customer: CustomerFragment;

    getDisplayType(entry: TimelineHistoryEntry): TimelineDisplayType {
        return entry.data.status != 'Rejected' ? 'success' : 'error';
    }

    getName(entry: TimelineHistoryEntry): string {
        return 'Return Request';
    }

    isFeatured(entry: TimelineHistoryEntry): boolean {
        return true;
    }

    getIconShape(entry: TimelineHistoryEntry) {
        return entry.data.status == 'Refunded' ? 'check-circle' : 'exclamation-circle';
    }
}