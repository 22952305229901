import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldControl, DataService, SharedModule } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { StoreSite } from '../../common/ui/generated-types';
import { GetStoreSiteDocument } from '../common/ui-types';

@Component({
    selector: 'relation-store-site-display',
    template: `
        <div class="flex items-center">
            <div *ngIf="formControl.value as storesite; else emptyStore" class="mr-2">
                <a class="button-ghost" [routerLink]="['/extensions', 'store-sites', storesite.id]">
                    <span>{{ storesite.name }}</span>
                    <clr-icon shape="arrow right"></clr-icon>
                </a>
            </div>
            <ng-template #emptyStore>
                <input
                    type="text" [formControl]="formControl"
                    placeholder="Create a Store Site first."
                    readonly
                />
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class RelationStoreSiteDisplayComponent implements OnInit, CustomFieldControl {
    @Input() readonly: boolean;
    @Input() formControl: FormControl;
    @Input() config: any;

    storesite$: Observable<StoreSite>;

    constructor(private dataService: DataService) {}

    ngOnInit() {
        this.storesite$ = this.dataService.query(GetStoreSiteDocument, {
            id: this.formControl.value?.id,
        }).mapSingle((data:any) => data.storeSite);
    }
}