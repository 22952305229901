<div class="add-vimeo-video-container">
  <h2>Add Vimeo Video</h2>
  <form [formGroup]="vimeoForm" (ngSubmit)="onSave()">
    <div class="form-group">
      <label for="videoUrl">Vimeo Url</label>
      <input type="text" id="videoUrl" formControlName="videoUrl" placeholder="Enter Vimeo video share URL">
    </div>
    <div class="button-container">
      <button type="button" class="button secondary" (click)="onCancel()">Cancel</button>
      <button type="submit" class="button primary" [disabled]="!vimeoForm.valid">Save</button>
    </div>
  </form>
</div>