import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import {
  SharedModule,
  StringCustomFieldConfig,
  DynamicFormInputComponent,
  FormInputComponent,
  DataService,
  NotificationService
} from '@vendure/admin-ui/core';
import {
  BannerButtonData,
  ButtonPositionType,
  HeroLayoutType,
  HeroSectionData,
  defaultHeroSectionData
} from '../ui-types';
import { ID } from '@vendure/core';
import { Asset } from '../generated-types';
import { forkJoin, Observable } from 'rxjs';
import { safeJSONParse } from '../utils';

@Component({
  selector: 'vdr-hero-section-input',
  template: `
    <vdr-card>
      <!-- Layout Type -->
      <vdr-form-field [label]="'Layout Type'">
        <select [formControl]="layoutTypeControl">
          <option value="left-text-full-media">Left Text Full Media</option>
          <option value="right-text-full-media">Right Text Full Media</option>
          <option value="middle-text-full-media">Middle Text Full Media</option>
        </select>
      </vdr-form-field>

      <!-- Medias -->
      <vdr-form-field [label]="'Medias'">
        <vdr-dynamic-form-input
          #mediasInput
          [control]="mediasControl"
          [def]="{
            list: true,
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input'
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Mobile Medias -->
      <vdr-form-field [label]="'Mobile Medias'">
        <vdr-dynamic-form-input
          #mobileMediasInput
          [control]="mobileMediasControl"
          [def]="{
            list: true,
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input'
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>

      <!-- Title -->
      <vdr-rich-text-editor [label]="'Title'" [formControl]="titleControl">
      </vdr-rich-text-editor>

      <!-- Subtitle -->
      <vdr-rich-text-editor [label]="'Subtitle'" [formControl]="subtitleControl">
      </vdr-rich-text-editor>

      <!-- Title Font Size -->
      <vdr-form-field [label]="'Title Font Size(px)'">
        <input type="number" [formControl]="titleFontSizeControl" min="1" />
      </vdr-form-field>

      <!-- Text Color -->
      <vdr-form-field [label]="'Text Color'">
        <input type="color" [formControl]="textColorControl" />
      </vdr-form-field>

      <!-- Shadow Transparency -->
      <vdr-form-field [label]="'Shadow Transparency'">
        <input type="number" [formControl]="shadowTransparencyControl" min="0" max="1" step="0.1" />
      </vdr-form-field>

      <!-- Button Config -->
      <div class="clr-row" *ngIf="heroSectionData?.button?.enabled">
        <vdr-form-field [label]="'Position (Desktop)'">
          <select [formControl]="buttonPositionControl">
            <option value="below-text">Below Text</option>
            <option value="custom">Custom</option>
          </select>
        </vdr-form-field>
        <div class="clr-col-4" *ngIf="buttonPositionControl.value === 'custom'">
          <vdr-form-field [label]="'Location X (Desktop)'">
            <input type="number" [formControl]="buttonLocationXControl" min="0" max="1" step="0.01">
          </vdr-form-field>
        </div>
        <div class="clr-col-4" *ngIf="buttonPositionControl.value === 'custom'">
          <vdr-form-field [label]="'Location Y (Desktop)'">
            <input type="number" [formControl]="buttonLocationYControl" min="0" max="1" step="0.01">
          </vdr-form-field>
        </div> 
      </div>

      <div class="clr-row" *ngIf="heroSectionData?.button?.enabled">
        <vdr-form-field [label]="'Position (Mobile)'">
          <select [formControl]="buttonPositionMobileControl">
            <option value="below-text">Below Text</option>
            <option value="custom">Custom</option>
          </select>
        </vdr-form-field>
        <div class="clr-col-4" *ngIf="buttonPositionMobileControl.value === 'custom'">
          <vdr-form-field [label]="'Location X (Mobile)'">
            <input type="number" [formControl]="buttonLocationXMobileControl" min="0" max="1" step="0.01">
          </vdr-form-field>
        </div>
        <div class="clr-col-4" *ngIf="buttonPositionMobileControl.value === 'custom'">
          <vdr-form-field [label]="'Location Y (Mobile)'">
            <input type="number" [formControl]="buttonLocationYMobileControl" min="0" max="1" step="0.01">
          </vdr-form-field>
        </div>
      </div>
      <vdr-form-field [label]="'Button Config'">
        <vdr-dynamic-form-input
          [control]="buttonControl"
          [def]="{
            type: 'string',
            ui: {
              component: 'banner-button-form'
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class HeroSectionInputComponent
  implements OnInit, AfterViewInit, FormInputComponent<StringCustomFieldConfig>
{
  @ViewChild('mediasInput') mediasInput: DynamicFormInputComponent;
  @ViewChild('mobileMediasInput') mobileMediasInput: DynamicFormInputComponent;

  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // Form controls
  layoutTypeControl = new FormControl();
  mediasControl = new UntypedFormControl();
  mobileMediasControl = new UntypedFormControl();
  titleControl = new FormControl();
  subtitleControl = new FormControl();
  titleFontSizeControl = new FormControl();
  textColorControl = new FormControl();
  shadowTransparencyControl = new FormControl();
  buttonControl = new UntypedFormControl();
  buttonPositionControl = new FormControl();
  buttonLocationXControl = new FormControl();
  buttonLocationYControl = new FormControl();
  buttonPositionMobileControl = new FormControl();
  buttonLocationXMobileControl = new FormControl();
  buttonLocationYMobileControl = new FormControl();

  heroSectionData: HeroSectionData | null;

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService) {}

  ngOnInit() {
    this.heroSectionData = safeJSONParse<HeroSectionData>(
      this.formControl.value,
      this.notificationService,
      defaultHeroSectionData,
    );
    this.initializeFormControls();

    if (this.heroSectionData && this.heroSectionData?.mediaIds?.length > 0) {
      this.loadAssetsByIds(this.heroSectionData.mediaIds).subscribe((assets) => {
        this.mediasControl.setValue(assets);
        if (this.mediasInput) {
          this.mediasInput.writeValue(assets);
        }
      });
    } else {
      this.mediasControl.setValue([]);
    }

    if (this.heroSectionData && this.heroSectionData?.mobileMediaIds?.length > 0) {
      this.loadAssetsByIds(this.heroSectionData.mobileMediaIds).subscribe((assets) => {
        this.mobileMediasControl.setValue(assets);
        if (this.mobileMediasInput) {
          this.mobileMediasInput.writeValue(assets);
        }
      });
    } else {
      this.mobileMediasControl.setValue([]);
    }
  }

  ngAfterViewInit() {
    if (this.mediasInput) {
      this.mediasInput.registerOnChange(this.onMediasChange);
    }
    if (this.mobileMediasInput) {
      this.mobileMediasInput.registerOnChange(this.onMobileMediasChange);
    }
    this.layoutTypeControl.valueChanges.subscribe(this.onLayoutTypeChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.subtitleControl.valueChanges.subscribe(this.onSubtitleChange);
    this.titleFontSizeControl.valueChanges.subscribe(this.onTitleFontSizeChange);
    this.textColorControl.valueChanges.subscribe(this.onTextColorChange);
    this.shadowTransparencyControl.valueChanges.subscribe(this.onShadowTransparencyChange);
    this.buttonControl.valueChanges.subscribe(this.onButtonChange);
    this.buttonPositionControl.valueChanges.subscribe(this.onButtonPositionChange);
    this.buttonLocationXControl.valueChanges.subscribe(this.onButtonLocationXChange);
    this.buttonLocationYControl.valueChanges.subscribe(this.onButtonLocationYChange);
    this.buttonPositionMobileControl.valueChanges.subscribe(this.onButtonPositionMobileChange);
    this.buttonLocationXMobileControl.valueChanges.subscribe(this.onButtonLocationXMobileChange);
    this.buttonLocationYMobileControl.valueChanges.subscribe(this.onButtonLocationYMobileChange);
  }

  private initializeFormControls() {
    this.layoutTypeControl.setValue(this.heroSectionData?.layoutType);
    // mediasControl and mobileMediasControl are set after loading assets
    this.titleControl.setValue(this.heroSectionData?.title);
    this.subtitleControl.setValue(this.heroSectionData?.subtitle);
    this.titleFontSizeControl.setValue(this.heroSectionData?.titleFontSize);
    this.textColorControl.setValue(this.heroSectionData?.textColor);
    this.shadowTransparencyControl.setValue(this.heroSectionData?.shadowTransparency);
    this.buttonControl.setValue(JSON.stringify(this.heroSectionData?.button));
    this.buttonPositionControl.setValue(this.heroSectionData?.buttonPosition);
    this.buttonLocationXControl.setValue(this.heroSectionData?.buttonLocationX);
    this.buttonLocationYControl.setValue(this.heroSectionData?.buttonLocationY);
    this.buttonPositionMobileControl.setValue(this.heroSectionData?.buttonPositionMobile);
    this.buttonLocationXMobileControl.setValue(this.heroSectionData?.buttonLocationXMobile);
    this.buttonLocationYMobileControl.setValue(this.heroSectionData?.buttonLocationYMobile);
  }

  private loadAssetsByIds(ids: ID[]): Observable<Asset[]> {
    return forkJoin(
      ids?.map((id) =>
        this.dataService.product.getAsset(id.toString())?.mapSingle((data) => data.asset)
      )
    ) as Observable<Asset[]>;
  }

  private onMediasChange = (medias: Asset[]) => {
    const changedAssetIds = medias?.map((asset) => asset.id);
    if (
      this.heroSectionData &&
      !this.areArraysEqual(this.heroSectionData.mediaIds, changedAssetIds)
    ) {
      this.heroSectionData.mediaIds = changedAssetIds;
      this.updateJsonString();
    }
  };

  private onMobileMediasChange = (medias: Asset[]) => {
    const changedAssetIds = medias?.map((asset) => asset.id);
    if (
      this.heroSectionData &&
      !this.areArraysEqual(this.heroSectionData.mobileMediaIds, changedAssetIds)
    ) {
      this.heroSectionData.mobileMediaIds = changedAssetIds;
      this.updateJsonString();
    }
  };

  private onLayoutTypeChange = (value: HeroLayoutType) => {
    if (this.heroSectionData?.layoutType !== value) {
      this.heroSectionData!.layoutType = value;
      this.updateJsonString();
    }
  };

  private onTitleChange = (value: string) => {
    if (this.heroSectionData?.title !== value) {
      this.heroSectionData!.title = value;
      this.updateJsonString();
    }
  };

  private onSubtitleChange = (value: string) => {
    if (this.heroSectionData?.subtitle !== value) {
      this.heroSectionData!.subtitle = value;
      this.updateJsonString();
    }
  };

  private onTitleFontSizeChange = (value: number) => {
    if (this.heroSectionData?.titleFontSize !== value) {
      this.heroSectionData!.titleFontSize = value;
      this.updateJsonString();
    }
  };

  private onTextColorChange = (value: string) => {
    if (this.heroSectionData?.textColor !== value) {
      this.heroSectionData!.textColor = value;
      this.updateJsonString();
    }
  };

  private onShadowTransparencyChange = (value: number) => {
    if (this.heroSectionData?.shadowTransparency !== value) {
      this.heroSectionData!.shadowTransparency = value;
      this.updateJsonString();
    }
  };

  private onButtonChange = (value: string) => {
    if (JSON.stringify(this.heroSectionData?.button) !== value) {
      this.heroSectionData!.button = safeJSONParse(value, this.notificationService);
      this.updateJsonString();
    }
  };

  private onButtonPositionChange = (value: ButtonPositionType) => {
    if (this.heroSectionData?.buttonPosition !== value) {
      this.heroSectionData!.buttonPosition = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationXChange = (value: number) => {
    if (this.heroSectionData?.buttonLocationX !== value) {
      this.heroSectionData!.buttonLocationX = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationYChange = (value: number) => {
    if (this.heroSectionData?.buttonLocationY !== value) {
      this.heroSectionData!.buttonLocationY = value;
      this.updateJsonString();
    }
  };

  private onButtonPositionMobileChange = (value: ButtonPositionType) => {
    if (this.heroSectionData?.buttonPositionMobile !== value) {
      this.heroSectionData!.buttonPositionMobile = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationXMobileChange = (value: number) => {
    if (this.heroSectionData?.buttonLocationXMobile !== value) {
      this.heroSectionData!.buttonLocationXMobile = value;
      this.updateJsonString();
    }
  };

  private onButtonLocationYMobileChange = (value: number) => {
    if (this.heroSectionData?.buttonLocationYMobile !== value) {
      this.heroSectionData!.buttonLocationYMobile = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    const jsonString = JSON.stringify(this.heroSectionData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }

  private areArraysEqual(array1: ID[], array2: ID[]) {
    return (
      array1?.length === array2?.length &&
      array1?.every((value) => array2?.includes(value))
    );
  }
}
