<vdr-page-block>
    <!-- Container using flexbox to align items in a row -->
    <div class="button-container" style="display: flex; align-items: center; gap: 20px;">
        <vdr-asset-search-input [tags]="allTags$ | async" (searchTermChange)="searchTerm$.next($event)"
            (tagsChange)="filterByTags$.next($event)">
        </vdr-asset-search-input>

        <!-- Hidden input for file selection -->
        <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept="video/*">

        <!-- Buttons for uploading and adding videos -->
        <button class="button primary" (click)="fileInput.click()">
            Upload Video
        </button>
        <button class="button primary" (click)="addVimeoVideo()">
            Add Vimeo URL
        </button>
    </div>
</vdr-page-block>
<vdr-page-block>
    <!-- Asset gallery component -->
    <vdr-asset-gallery [assets]="(items$ | async)! | paginate : (paginationConfig$ | async) || {}" [multiSelect]="true"
        [canDelete]="['DeleteCatalog', 'DeleteAsset'] | hasPermission" (deleteAssets)="deleteAssets($event)">
    </vdr-asset-gallery>

    <!-- Pagination controls -->
    <div class="paging-controls">
        <vdr-items-per-page-controls [itemsPerPage]="itemsPerPage$ | async"
            (itemsPerPageChange)="setItemsPerPage($event)">
        </vdr-items-per-page-controls>

        <vdr-pagination-controls [currentPage]="currentPage$ | async" [itemsPerPage]="itemsPerPage$ | async"
            [totalItems]="totalItems$ | async" (pageChange)="setPageNumber($event)">
        </vdr-pagination-controls>
    </div>
</vdr-page-block>