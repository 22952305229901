import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/return-request-extension/providers';
import SharedProviders_1_0 from './extensions/multivendor-extension/providers';
import SharedProviders_2_0 from './extensions/common/ui/providers';
import SharedProviders_3_0 from './extensions/product-reviews/providers';
import SharedProviders_4_0 from './extensions/products-import/providers';
import SharedProviders_5_0 from './extensions/store-sites/providers';
import SharedProviders_6_0 from './extensions/vimeo/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0, ...SharedProviders_3_0, ...SharedProviders_4_0, ...SharedProviders_5_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
