import gql from 'graphql-tag';

export const STORE_SITE_FRAGMENT = gql`
    fragment StoreSite on StoreSite {
        id
        createdAt
        updatedAt
        name
        identifier
        seller {
            id
            name
        }

        headerMessage
        colorConfig
        logoSection
        snsInfoSection

        navigationMenu
        aboutPageContent
        faqPageContent
        contactPageContent
        policyContentList
        footerNavigationColumns

        layoutOrder

        heroSection
        featuredProductReviewList
        emailSubscriptionSection

        uniqueSellingPointsList
        featuredProductCollectionList
        collectionListForShopByGroupList
        collectionForSeriesList
    }
`;
