import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IntCustomFieldConfig, SharedModule, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../utils';

@Component({
  template: `
    <form [formGroup]="form">
      <div *ngFor="let key of getKeys()" class="key-value-pair">
        <input type="text" [value]="key" (change)="updateKey(key, $event.target.value)" class="key-input" />
        <input type="text" [formControl]="getFormControl(key)" (input)="updateValue(key, $event.target.value)" class="value-input" />
        <button (click)="removeKeyValuePair(key)" class="remove-button">x</button>
      </div>
      <button (click)="addKeyValuePair()" class="add-button">+</button>
    </form>
  `,
  standalone: true,
  imports: [SharedModule],
  styles: [`
    .key-value-pair {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .key-input {
      flex: 1;
      margin-right: 10px;
    }
    .value-input {
      flex: 2;
      margin-right: 10px;
    }
    .remove-button {
      margin-left: 10px;
    }
    .add-button {
      margin-top: 10px;
    }
  `]
})
export class FlexibleKeyValueFormComponent implements FormInputComponent<IntCustomFieldConfig>, OnInit {
  readonly: boolean;
  config: IntCustomFieldConfig;
  formControl: FormControl;
  jsonString: string;
  jsonObject: any;
  form: FormGroup;

  constructor(
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.jsonString = this.formControl.value;
    this.jsonObject = safeJSONParse(this.jsonString, this.notificationService, {});
    this.form = new FormGroup(
      Object.keys(this.jsonObject).reduce((acc: Record<string, FormControl>, key) => {
        acc[key] = new FormControl(this.jsonObject[key]);
        return acc;
      }, {})
    );
  }

  getKeys(): string[] {
    return Object.keys(this.jsonObject);
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  removeKeyValuePair(key: string): void {
    delete this.jsonObject[key];
    this.form.removeControl(key);
    this.updateFormControlValue();
  }

  addKeyValuePair(): void {
    const newKey = `newKey${this.getKeys().length + 1}`;
    this.jsonObject[newKey] = '';
    this.form.addControl(newKey, new FormControl(''));
    this.updateFormControlValue();
  }

  updateKey(oldKey: string, newKey: string): void {
    if (oldKey !== newKey) {
      const value = this.jsonObject[oldKey];
      delete this.jsonObject[oldKey];
      this.jsonObject[newKey] = value;
      this.form.removeControl(oldKey);
      this.form.addControl(newKey, new FormControl(value));
      this.updateFormControlValue();
    }
  }

  updateValue(key: string, value: string): void {
    this.jsonObject[key] = value;
    this.form.get(key)?.setValue(value);
    this.updateFormControlValue();
  }

  updateFormControlValue(): void {
    this.formControl.setValue(JSON.stringify(this.jsonObject));
    this.formControl.markAsDirty();
  }
}