import gql from 'graphql-tag';
import { STORE_SITE_FRAGMENT } from './fragments.graphql';

export const CREATE_STORE_SITE = gql`
  mutation CreateStoreSite($input: CreateStoreSiteInput!) {
    createStoreSite(input: $input) {
      id
      name
      identifier
    }
  }
`;

export const GetCollectionDocument = gql`
  query GetCollection($id: ID!) {
    collection(id: $id) {
      id
      name
    }
  }
`;

export const GET_COLLECTION_BY_ID = gql`
    query GetCollectionById($id: ID!) {
        collection(id: $id) {
            id
            name
            slug
            parent {
                id
                name
                slug
            }
        }
    }
`;

export const GetAllStoreSitesDocument = gql`
  query GetStoreSites($options: StoreSiteListOptions) {
    storeSites(options: $options) {
      items {
        ...StoreSite
      }
      totalItems
    }
  }
  ${STORE_SITE_FRAGMENT}
`;

export const GetStoreSiteDocument = gql`
  query GetStoreSite($id: ID!) {
    storeSite(id: $id) {
      ...StoreSite
    }
  }
  ${STORE_SITE_FRAGMENT}
`;

export const updateStoreSiteDocument = gql`
  mutation UpdateStoreSite($input: UpdateStoreSiteInput!) {
    updateStoreSite(input: $input) {
      ...StoreSite
    }
  }
  ${STORE_SITE_FRAGMENT}
`;


export const GET_PRODUCTS = gql`
    query GetProducts($options: ProductListOptions) {
        products(options: $options) {
            items {
                id
                name
                slug
            }
            totalItems
        }
    }
`;

export const GET_REVIEWS_FOR_PRODUCT = gql`
    query GetReviewForProduct($productId: ID!, $options: ProductReviewListOptions) {
        product(id: $productId) {
            id
            reviews(options: $options) {
                items {
                    id
                    summary
                    body
                    rating
                    authorName
                    state
                }
                totalItems
            }
        }
    }
`;

export const GET_PRODUCT_REVIEW = gql`
    query GetProductReview($productReviewId: ID!) {
        productReview(id: $productReviewId) {
            id
            summary
            body
            rating
            authorName
            state
            product {
                id
                name
                slug
            }
        }
    }
`;
