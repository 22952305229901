import { registerCustomDetailComponent, registerFormInputComponent } from '@vendure/admin-ui/core';
import {
    addNavMenuItem,
} from '@vendure/admin-ui/core';
import { Permission } from '../common/ui/generated-types';
import { SellerStoreSiteDetailComponent } from './components/seller-store-site-detail.component';
import { RelationStoreSiteDisplayComponent } from './components/relation-store-site-display.component';
import { QAInputComponent } from './components/qa-input.component';
import { FaqPageFormComponent } from './components/faq-page-form.component';
import { AboutPageFormComponent } from './components/about-page-form.component';
import { ContactPageFormComponent } from './components/contact-page-form.component';
import { PolicyContentFormComponent } from './components/policy-content-form.component';
import { ColorConfigFormComponent } from './components/color-config-form.component';
import { ProductReviewSelectorComponent } from './components/relation-product-review-selector.component';
import { CollectionGroupInputComponent } from './components/collection-group-input.component';
import { SnsInfoFormComponent } from './components/sns-info-form-component';

export default [
    addNavMenuItem({
        id: 'store-sites',
        label: 'Store Sites',
        routerLink: ['/extensions/store-sites'],
        icon: 'node',
        requiresPermission: Permission.UpdateCatalog,
    },
    'marketing'),
    registerCustomDetailComponent({locationId: 'seller-detail',
        component: SellerStoreSiteDetailComponent,
    }),
    registerFormInputComponent('relation-store-site-display', RelationStoreSiteDisplayComponent),
    registerFormInputComponent('question-answer-input', QAInputComponent),
    registerFormInputComponent('faq-page-form', FaqPageFormComponent),
    registerFormInputComponent('about-page-form', AboutPageFormComponent),
    registerFormInputComponent('contact-page-form', ContactPageFormComponent),
    registerFormInputComponent('policy-content-form', PolicyContentFormComponent),
    registerFormInputComponent('collection-group-input', CollectionGroupInputComponent),
    registerFormInputComponent('product-review-selector', ProductReviewSelectorComponent),
    registerFormInputComponent('color-config-form', ColorConfigFormComponent),
    registerFormInputComponent('sns-info-form', SnsInfoFormComponent),
];
