import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule, NotificationService } from '@vendure/admin-ui/core';
import { Query } from '../generated-types';
import { GetCollectionContentsQuery } from '@vendure/admin-ui/core';
import {
    DataService,
    CustomDetailComponent
} from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { ID, ProductVariant, Product } from '@vendure/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { safeJSONParse } from '../utils';
import {
    GET_COLLECTION_ITEM_ORDER_DATA,
    CollectionItemOrderData,
    defaultCollectionItemOrderData,
    CollectionItemType
} from '../ui-types';

@Component({
    selector: 'collection-variant-order-form',
    template: `
    <vdr-card class="pb-4">
        <!-- Top line with label and save button -->
        <div class="header-row">
            <div>
                <p class="title">Product (Variant) Order</p>
                <p class="note-text">(*Better to order with less than 100 items)</p>
            </div>
            <button class="save-button" (click)="saveOrder()">Save</button>
        </div>

        <!-- Enable toggle button -->
        <div class="toggle-wrapper">
            <clr-toggle-wrapper>
                <input
                    type="checkbox"
                    clrToggle
                    name="enabled"
                    [formControl]="enabledControl"
                />
                <label>{{ 'common.enabled' | translate }}</label>
            </clr-toggle-wrapper>
        </div>

        <!-- Order Type Radio Buttons with Item Counts -->
        <div class="radio-group">
            <label>Order By:</label>
            <clr-radio-wrapper class="radio-item">
                <input
                    type="radio"
                    clrRadio
                    [formControl]="orderTypeControl"
                    value="Product"
                />
                <label>Product ({{ originalProducts?.length || 0 }} items)</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper class="radio-item">
                <input
                    type="radio"
                    clrRadio
                    [formControl]="orderTypeControl"
                    value="ProductVariant"
                />
                <label>Product Variant ({{ originalVariants?.length || 0 }} items)</label>
            </clr-radio-wrapper>
        </div>

        <!-- Drop list for reordering items -->
        <div *ngIf="enabledControl.value && originalVariants?.length > 0">
            <div *ngIf="orderTypeControl.value === 'ProductVariant'">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let variant of orderedVariants; let i = index" cdkDrag class="item-row">
                        <p class="item-index">{{ i + 1 }}</p>
                        <img [src]="variant.featuredAsset?.preview + '?preset=tiny'" [alt]="variant.name" class="item-image">
                        <div class="item-details">
                            <div class="product-name">{{ variant.product?.name }}</div>
                            <div>{{ variant.name }}</div>
                            <div class="item-sku">{{ variant.sku }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="orderTypeControl.value === 'Product'">
                <div cdkDropList (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let product of orderedProducts; let i = index" cdkDrag class="item-row">
                        <p class="item-index">{{ i + 1 }}</p>
                        <img [src]="product.featuredAsset?.preview + '?preset=tiny'" [alt]="product.name" class="item-image">
                        <div class="item-details">
                            <div class="product-name">{{ product?.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </vdr-card>
    `,
    styles: [`
        .header-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }
        .title {
            font-size: 1.2rem;
            font-weight: 600;
        }
        .note-text {
            font-size: 0.6rem;
            color: #888;
        }
        .save-button {
            font-size: 1rem;
            font-weight: 500;
            padding: 0.5rem 1rem;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 0.25rem;
            cursor: pointer;
        }
        .save-button:hover {
            background-color: #0056b3;
        }
        .toggle-wrapper {
            margin-bottom: 1rem;
        }
        .radio-group {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }
        .radio-group label {
            margin-right: 1rem;
            font-weight: 500;
        }
        .radio-item {
            margin-right: 1.5rem;
        }
        .item-row {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            border-bottom: 1px solid #ddd;
        }
        .item-index {
            font-weight: 600;
            margin-right: 0.5rem;
        }
        .item-image {
            width: 50px;
            height: auto;
            margin-right: 1rem;
        }
        .item-details {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
        .product-name {
            font-weight: 600;
        }
        .item-sku {
            color: #666;
        }
    `],
    standalone: true,
    imports: [SharedModule],
})
export class CollectionVariantOrderFormComponent implements CustomDetailComponent {
    entity$: Observable<NonNullable<GetCollectionContentsQuery['collection']>>;
    detailForm: FormGroup;
    enabledControl = new FormControl(false);
    orderTypeControl = new FormControl(CollectionItemType.Product);
    originalVariants: ProductVariant[] = [];
    orderedVariants: ProductVariant[] = [];
    variantMap = new Map<ID, ProductVariant>();
    originalProducts: Product[] = [];
    orderedProducts: Product[] = [];
    productMap = new Map<ID, Product>();
    collectionItemOrderData: CollectionItemOrderData | null;

    static readonly MAX_ITEMS = 150;

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
    ) {}

    ngOnInit() {
        this.entity$.subscribe((collection: any) => {
            this.collectionItemOrderData = collection?.customFields?.itemOrderData;
            this.dataService.query<Query['collection']>(GET_COLLECTION_ITEM_ORDER_DATA,
                {
                    id: collection?.id,
                    maxVariants: CollectionVariantOrderFormComponent.MAX_ITEMS
                }
            )
            .mapSingle((data: any) => data?.collection)
            .subscribe((collection: any) => {
                if (collection) {
                    this.originalVariants = collection.productVariants?.items;
                    this.variantMap = new Map(this.originalVariants.map(v => [v.id, v]));

                    this.originalProducts = Array.from(
                        new Map(this.originalVariants.map(v => [v.product.id, v.product])).values()
                    );
                    this.productMap = new Map(this.originalProducts.map(p => [p.id, p]));
                    this.collectionItemOrderData = safeJSONParse(collection.customFields?.itemOrderData, this.notificationService, defaultCollectionItemOrderData);
                    this.collectionItemOrderData!.collectionId = collection.id;
                    this.enabledControl.setValue(this.collectionItemOrderData?.enabled || false);
                    this.orderTypeControl.setValue(this.collectionItemOrderData?.orderType || CollectionItemType.Product);
                    if (this.orderTypeControl.value === CollectionItemType.ProductVariant) {
                        this.updateOrderedVariants();
                    } else {
                        this.updateOrderedProducts();
                    }
                }
            });

            this.enabledControl.valueChanges.subscribe(() => {
                if(this.collectionItemOrderData?.enabled !== this.enabledControl.value) {
                    this.collectionItemOrderData!.enabled = this.enabledControl.value || false;
                }
            });

            this.orderTypeControl.valueChanges.subscribe(() => {
                if(this.collectionItemOrderData?.orderType !== this.orderTypeControl.value) {
                    this.collectionItemOrderData!.orderType = this.orderTypeControl.value as CollectionItemType;
                    if (this.orderTypeControl.value === CollectionItemType.Product) {
                        this.updateOrderedProducts();
                    } else {
                        this.updateOrderedVariants();
                    }
                }
            });
        });
    }

    updateOrderedVariants() {
        this.orderedVariants = this.collectionItemOrderData?.variantIdsInOrder
            .map(id => this.variantMap?.get(id))
            .filter((v): v is ProductVariant => !!v) || [];
        const unorderedVariants = this.originalVariants.filter(
            v => !this.collectionItemOrderData?.variantIdsInOrder.includes(v.id),
        );
        this.orderedVariants.push(...unorderedVariants);
        this.collectionItemOrderData!.variantIdsInOrder = this.orderedVariants.map(v => v.id);
    }

    updateOrderedProducts() {
        this.orderedProducts = this.collectionItemOrderData?.productIdsInOrder
            .map(id => this.productMap?.get(id))
            .filter((p): p is Product => !!p) || [];
        const unorderedProducts = this.originalProducts.filter(
            p => !this.collectionItemOrderData?.productIdsInOrder.includes(p.id),
        );
        this.orderedProducts.push(...unorderedProducts);
        this.collectionItemOrderData!.productIdsInOrder = this.orderedProducts.map(p => p.id);
    }

    drop(event: CdkDragDrop<ID[]>) {
        if (this.orderTypeControl.value === CollectionItemType.ProductVariant) {
            moveItemInArray(this.orderedVariants, event.previousIndex, event.currentIndex);
            this.collectionItemOrderData!.variantIdsInOrder = this.orderedVariants.map(v => v.id);
        } else {
            moveItemInArray(this.orderedProducts, event.previousIndex, event.currentIndex);
            this.collectionItemOrderData!.productIdsInOrder = this.orderedProducts.map(p => p.id);
        }
    }

    saveOrder() {
        const itemOrderData = JSON.stringify(this.collectionItemOrderData);
        this.dataService.collection.updateCollection({
            id: this.collectionItemOrderData?.collectionId?.toString() || '',
            customFields: { itemOrderData },
        }).subscribe(() => {
            this.notificationService.success(`Collection ${this.collectionItemOrderData?.orderType} Order saved successfully`);
        });
    }
}