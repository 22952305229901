import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormControl, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import {
    SharedModule,
    StringCustomFieldConfig,
    FormInputComponent,
    NotificationService,
    DataService
} from '@vendure/admin-ui/core';
import { Collection } from '../generated-types';
import { safeJSONParse } from '../utils';
import { CollectionItemType, defaultFeaturedCollectionData, FeaturedCollectionData, ProductCardType } from '../ui-types';

@Component({
  selector: 'vdr-featured-collection-input',
  template: `
    <vdr-card>
      <div>
        <!-- Title -->
        <vdr-form-field [label]="'Title'">
          <input type="text" [formControl]="titleControl">
        </vdr-form-field>

        <!-- Subtitle -->
        <vdr-form-field [label]="'Subtitle'">
          <input type="text" [formControl]="subtitleControl">
        </vdr-form-field>

        <!-- Compact Row -->
        <div class="form-row">
          <!-- Collection Selector -->
          <vdr-form-field [label]="'Collection'" class="form-item">
            <vdr-dynamic-form-input
              [control]="collectionControl"
              [def]="{
                type: 'relation',
                entity: 'Collection',
                ui: {
                  component: 'relation-collection-selector',
                }
              }"
            >
            </vdr-dynamic-form-input>
          </vdr-form-field>

          <!-- Display Type -->
          <vdr-form-field [label]="'Display Type'" class="form-item">
            <select [formControl]="displayTypeControl">
              <option value="Product">Product</option>
              <option value="ProductVariant">Product Variant</option>
            </select>
          </vdr-form-field>

          <!-- Product Card Type -->
          <vdr-form-field [label]="'Product Card Type'" class="form-item">
            <select [formControl]="productCardTypeControl">
              <option value="regular">Regular</option>
              <option value="left-asset-small">Left Asset Small</option>
              <option value="right-asset-small">Right Asset Small</option>
              <option value="left-asset-large">Left Asset Large</option>
              <option value="right-asset-large">Right Asset Large</option>
              <option value="top-asset-small">Top Asset Small</option>
              <option value="top-asset-large">Top Asset Large</option>
              <option value="full-asset">Full Asset</option>
            </select>
          </vdr-form-field>

          <!-- Cards Per Row -->
          <vdr-form-field [label]="'Cards Per Row'" class="form-item">
            <select [formControl]="cardsPerRowControl">
              <option *ngFor="let num of [1, 2, 3, 4]" [value]="num">{{ num }}</option>
            </select>
          </vdr-form-field>
        </div>

        <!-- Display Fields Checkboxes -->
        <div class="form-row">
          <span style="font-weight:bold;">Select Display Fields:</span>
          <div class="checkbox-group">
            <label><input type="checkbox" [formControl]="displayFieldsMap.viewers"> Viewers </label>
            <label><input type="checkbox" [formControl]="displayFieldsMap.likes"> Likes </label>
            <label><input type="checkbox" [formControl]="displayFieldsMap.ranking"> Ranking </label>
            <label><input type="checkbox" [formControl]="displayFieldsMap.sold"> Sold </label>
            <label><input type="checkbox" [formControl]="displayFieldsMap.price"> Price </label>
            <label><input type="checkbox" [formControl]="displayFieldsMap.buybutton"> Buy Button </label>
          </div>
        </div>
      </div>
    </vdr-card>
  `,
  styleUrls: ['../styles.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class FeaturedCollectionInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // Form controls
  titleControl = new FormControl();
  subtitleControl = new FormControl();
  collectionControl = new UntypedFormControl();
  displayTypeControl = new FormControl();
  productCardTypeControl = new FormControl();
  cardsPerRowControl = new FormControl();

  // Display fields controls
  displayFieldsMap = {
    viewers: new FormControl(),
    likes: new FormControl(),
    ranking: new FormControl(),
    sold: new FormControl(),
    price: new FormControl(),
    buybutton: new FormControl(),
  };

  featuredCollectionData: FeaturedCollectionData | null;

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    // Parse the initial value to FeaturedCollectionData
    this.featuredCollectionData = safeJSONParse<FeaturedCollectionData>(
        this.formControl.value,
        this.notificationService,
        defaultFeaturedCollectionData,
    );
    this.initializeFormControls();

    if (this.featuredCollectionData?.collectionId) {
      this.dataService.collection.getCollections({
        filter: {
          id: { eq: this.featuredCollectionData.collectionId.toString() }
        },
      }).mapSingle(data => data.collections?.items[0]).subscribe(collection => {
        this.collectionControl.setValue(collection);
      });
    }

    // Initialize checkboxes based on featuredCollectionData.displayFields
    const selectedFields = this.featuredCollectionData?.displayFields ?? [];
    this.displayFieldsMap.viewers.setValue(selectedFields.includes('viewers'));
    this.displayFieldsMap.likes.setValue(selectedFields.includes('likes'));
    this.displayFieldsMap.ranking.setValue(selectedFields.includes('ranking'));
    this.displayFieldsMap.sold.setValue(selectedFields.includes('sold'));
    this.displayFieldsMap.price.setValue(selectedFields.includes('price'));
    this.displayFieldsMap.buybutton.setValue(selectedFields.includes('buybutton'));
  }

  ngAfterViewInit() {
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.subtitleControl.valueChanges.subscribe(this.onSubtitleChange);
    this.collectionControl.valueChanges.subscribe(this.onCollectionChange);
    this.displayTypeControl.valueChanges.subscribe(this.onDisplayTypeChange);
    this.productCardTypeControl.valueChanges.subscribe(this.onProductCardTypeChange);
    this.cardsPerRowControl.valueChanges.subscribe(this.onCardsPerRowChange);

    // Subscribe to changes in display fields
    Object.entries(this.displayFieldsMap).forEach(([fieldName, control]) => {
      control.valueChanges.subscribe((checked: boolean) => this.onDisplayFieldChange(fieldName, checked));
    });
  }

  private initializeFormControls() {
    this.titleControl.setValue(this.featuredCollectionData?.title);
    this.subtitleControl.setValue(this.featuredCollectionData?.subtitle);
    this.displayTypeControl.setValue(this.featuredCollectionData?.displayType);
    this.productCardTypeControl.setValue(this.featuredCollectionData?.productCardType);
    this.cardsPerRowControl.setValue(this.featuredCollectionData?.cardsPerRow);
  }

  private onTitleChange = (value: string) => {
    if (this.featuredCollectionData?.title !== value) {
      this.featuredCollectionData!.title = value;
      this.updateJsonString();
    }
  }

  private onSubtitleChange = (value: string) => {
    if (this.featuredCollectionData?.subtitle !== value) {
      this.featuredCollectionData!.subtitle = value;
      this.updateJsonString();
    }
  }

  private onCollectionChange = (collection: Collection) => {
    const collectionId = collection ? collection.id : null;
    if (this.featuredCollectionData?.collectionId !== collectionId) {
      this.featuredCollectionData!.collectionId = collectionId;
      this.updateJsonString();
    }
  };

  private onDisplayTypeChange = (value: CollectionItemType) => {
    if (this.featuredCollectionData?.displayType !== value) {
      this.featuredCollectionData!.displayType = value;
      this.updateJsonString();
    }
  }

  private onProductCardTypeChange = (value: ProductCardType) => {
    if (this.featuredCollectionData?.productCardType !== value) {
      this.featuredCollectionData!.productCardType = value;
      this.updateJsonString();
    }
  }

  private onCardsPerRowChange = (value: number) => {
    if (this.featuredCollectionData?.cardsPerRow !== value) {
      this.featuredCollectionData!.cardsPerRow = value;
      this.updateJsonString();
    }
  }

  private onDisplayFieldChange(fieldName: string, checked: boolean) {
    const fields = new Set(this.featuredCollectionData?.displayFields ?? []);
    if (checked) {
      fields.add(fieldName);
    } else {
      fields.delete(fieldName);
    }
    this.featuredCollectionData!.displayFields = Array.from(fields);
    this.updateJsonString();
  }

  private updateJsonString() {
    const jsonString = JSON.stringify(this.featuredCollectionData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
