export const TAX_CODES = [
  {
    id: 'txcd_99999999',
    type: 'physical',
    description: 'A physical good that can be moved or touched. Also known as tangible personal property.',
    name: 'General - Tangible Goods'
  },
  {
    id: 'txcd_20030000',
    type: 'services',
    description: 'General category for services. It should be used only when there is no more specific services category. In the European Union, the default rule for business-to-consumer sales (B2C) is the location of the seller, whereas for business-to-business sales (B2B) - the location of the buyer.',
    name: 'General - Services'
  },
  {
    id: 'txcd_10000000',
    type: 'digital',
    description: 'A digital service provided mainly through the internet with minimal human involvement, relying on information technology. Consider more specific categories like software, digital goods, cloud services, or website services for your product (especially if you sell in the US). If you stay with this category, taxes will be similar to those for a generic digital item like downloaded music.',
    name: 'General - Electronically Supplied Services'
  },
  {
    id: 'txcd_00000000',
    type: 'physical',
    description: 'Any nontaxable good or service which can be used to ensure no tax is applied, even for jurisdictions that impose a tax.',
    name: 'Nontaxable'
  },
  {
    id: 'txcd_10010001',
    type: 'digital',
    description: 'Cloud service offering infrastructure resources (specifically server storage, RAM, and CPU usage) over the internet. This offering is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Infrastructure as a service (IaaS) - personal use'
  },
  {
    id: 'txcd_10101000',
    type: 'digital',
    description: 'Cloud service offering infrastructure resources (specifically server storage, RAM, and CPU usage) over the internet. This offering is intended for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Infrastructure as a service (IaaS) - business use'
  },
  {
    id: 'txcd_10102000',
    type: 'digital',
    description: 'Cloud service providing a platform for users to develop, run, and manage applications. This offering is intended for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Platform as a service (PaaS) - business use'
  },
  {
    id: 'txcd_10102001',
    type: 'digital',
    description: 'Cloud service providing a platform for users to develop, run, and manage applications. This offering is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Platform as a Service (PaaS) - personal use'
  },
  {
    id: 'txcd_10103000',
    type: 'digital',
    description: 'Cloud services software delivered over the internet. The software isn\'t customized for a specific buyer and they don\'t download anything. The software is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Software as a service (SaaS) - personal use'
  },
  {
    id: 'txcd_10103001',
    type: 'digital',
    description: 'Cloud services software delivered over the internet. The software isn\'t customized for a specific buyer and they don\'t download anything. The software is intended for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Software as a service (SaaS) - business use'
  },
  {
    id: 'txcd_10103100',
    type: 'digital',
    description: 'Cloud services software delivered over the internet. The software isn\'t customized for a specific buyer and this model assumes an electronic transfer to the buyer, such as an app download.  The software is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Software as a service (SaaS) - electronic download - personal use'
  },
  {
    id: 'txcd_10103101',
    type: 'digital',
    description: 'Cloud services software delivered over the internet. The software isn\'t customized for a specific buyer and this model assumes an electronic transfer to the buyer, such as an app download.  The software is intended for use by a commercial enterprise. Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Software as a service (SaaS) - electronic download - business use'
  },
  {
    id: 'txcd_10104001',
    type: 'digital',
    description: 'Cloud service providing business process outsourcing services over the internet.',
    name: 'Cloud-based business process as a service'
  },
  {
    id: 'txcd_10201000',
    type: 'digital',
    description: 'Video or electronic games in the common sense that are transferred electronically. These goods are downloaded to a device with permanent access granted. This does not include games that are considered betting, gambling, lottery, etc.',
    name: 'Video Games - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10201001',
    type: 'digital',
    description: 'Video or electronic games in the common sense that are transferred electronically. These goods are downloaded to a device with access that expires after a stated period of time. This does not include games that are considered betting, gambling, lottery, etc.',
    name: 'Video Games - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10201002',
    type: 'digital',
    description: 'Video or electronic games in the common sense that are transferred electronically. These goods are downloaded to a device with access that is conditioned upon continued subscription payment. This does not include games that are considered betting, gambling, lottery, etc.',
    name: 'Video Games - downloaded - subscription - with conditional rights'
  },
  {
    id: 'txcd_10201003',
    type: 'digital',
    description: 'Video or electronic games in the common sense that are transferred electronically. These goods are streamed to a device with access that expires after a stated period of time. This does not include games that are considered betting, gambling, lottery, etc.',
    name: 'Video Games - streamed - non subscription - with limited rights'
  },
  {
    id: 'txcd_10201004',
    type: 'digital',
    description: 'Video or electronic games in the common sense that are transferred electronically. These goods are streamed to a device with access that is conditioned upon continued subscription payment. This does not include games that are considered betting, gambling, lottery, etc.',
    name: 'Video Games - streamed - subscription - with conditional rights'
  },
  {
    id: 'txcd_10202000',
    type: 'digital',
    description: 'Prewritten ("canned") software that the buyer downloads. The software is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Downloadable Software - personal use'
  },
  {
    id: 'txcd_10202001',
    type: 'digital',
    description: 'Prewritten ("canned") software that the buyer downloads used for non-recreational purposes, such as antivirus, database, educational, financial, word processing, etc. The software is intended for personal use, rather than for consumption in a commercial enterprise.  Note: The distinction between business use and personal use for this tax code is relevant only if you are transacting business in the US.',
    name: 'Downloadable Software - non-recreational - personal use'
  },
  {
    id: 'txcd_10202003',
    type: 'digital',
    description: 'Prewritten ("canned") software that the buyer downloads. The software is intended for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Downloadable Software - business use'
  },
  {
    id: 'txcd_10203000',
    type: 'digital',
    description: 'Custom software that the buyer downloads. The software is intended for personal use, rather than for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Downloadable Software - custom - personal use'
  },
  {
    id: 'txcd_10203001',
    type: 'digital',
    description: 'Custom software that the buyer downloads. The software is intended for use by a commercial enterprise.  Note: The distinction between business use and personal use for this product tax category is relevant only if you have sales in the US.',
    name: 'Downloadable Software - custom - business use'
  },
  {
    id: 'txcd_10301000',
    type: 'digital',
    description: 'The recording of a book read aloud and sold with unlimited usage (e.g., a downloaded audio copy of The High Growth Handbook).',
    name: 'Audiobook'
  },
  {
    id: 'txcd_10302000',
    type: 'digital',
    description: 'Works that are generally recognized in the ordinary and usual sense as books and are transferred electronically. These goods are downloaded to a device with permanent access granted. These goods include novels, autobiographies, encyclopedias, dictionaries, repair manuals, phone directories, business directories, zip code directories, cookbooks, etc.',
    name: 'Digital Books - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10302001',
    type: 'digital',
    description: 'Works that are generally recognized in the ordinary and usual sense as books and are transferred electronically.  These goods are downloaded to a device with access that expires after a stated period of time.  These goods include novels, autobiographies, encyclopedias, dictionaries, repair manuals, phone directories, business directories, zip code directories, cookbooks, etc.',
    name: 'Digital Books - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10302002',
    type: 'digital',
    description: 'Works that are generally recognized in the ordinary and usual sense as books and are transferred electronically. These goods are downloaded to a device with access that is conditioned upon continued subscription payment. These goods include novels, autobiographies, encyclopedias, dictionaries, repair manuals, phone directories, business directories, zip code directories, cookbooks, etc.',
    name: 'Digital Books - downloaded - subscription - with conditional rights'
  },
  {
    id: 'txcd_10302003',
    type: 'digital',
    description: 'Works that are generally recognized in the ordinary and usual sense as books and are transferred electronically. These goods are viewable (but not downloadable) on a device with access that is conditioned upon continued subscription payment. These goods include novels, autobiographies, encyclopedias, dictionaries, repair manuals, phone directories, business directories, zip code directories, cookbooks, etc.',
    name: 'Digital Books - viewable only - subscription - with conditional rights'
  },
  {
    id: 'txcd_10303000',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles downloaded to a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital Magazines/Periodicals - downloadable - subscription - with conditional rights'
  },
  {
    id: 'txcd_10303001',
    type: 'digital',
    description: 'A digital version of a traditional magazine published at regular intervals. The publication is accessed via a subscription which also entitles the purchaser to physical copies of the media.',
    name: 'Digital Magazines/Periodicals - subscription tangible and digital'
  },
  {
    id: 'txcd_10303002',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital Magazines/Periodicals - viewable only - subscription - with conditional rights'
  },
  {
    id: 'txcd_10303100',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles downloaded to a device with permanent access granted. The publication is accessed without a subscription.',
    name: 'Digital Magazines/Periodicals - downloadable - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10303101',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with access that expires after a stated period of time. The publication is accessed without a subscription.',
    name: 'Digital Magazines/Periodicals - viewable only - non subscription - with limited rights'
  },
  {
    id: 'txcd_10303102',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with permanent access granted. The publication is accessed without a subscription.',
    name: 'Digital Magazines/Periodicals - viewable only - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10303104',
    type: 'digital',
    description: 'A digital version of a traditional periodical published at regular intervals with the entire publication or individual articles downloaded to a device with access that expires after a stated period of time. The publication is accessed without a subscription.',
    name: 'Digital Magazines/Periodicals - downloadable - non subscription - with limited rights'
  },
  {
    id: 'txcd_10304000',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles downloaded to a device with permanent access granted. The publication is accessed without a subscription.',
    name: 'Digital Newspapers - downloadable - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10304001',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with access that expires after a stated period of time. The publication is accessed without a subscription.',
    name: 'Digital Newspapers - viewable only - non subscription - with limited rights'
  },
  {
    id: 'txcd_10304002',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with permanent access granted.  The publication is accessed without a subscription.',
    name: 'Digital Newspapers - viewable only - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10304003',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles downloaded to a device with access that expires after a stated period of time.  The publication is accessed without a subscription.',
    name: 'Digital Newspapers - downloadable - non subscription - with limited rights'
  },
  {
    id: 'txcd_10304100',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles downloaded to a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital Newspapers - downloadable - subscription - with conditional rights'
  },
  {
    id: 'txcd_10304101',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals. The publication is accessed via a subscription which also entitles the purchaser to physical copies of the media.',
    name: 'Digital Newspapers - subscription tangible and digital'
  },
  {
    id: 'txcd_10304102',
    type: 'digital',
    description: 'A digital version of a traditional newspaper published at regular intervals with the entire publication or individual articles viewable (but not downloadable) on a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital Newspapers - viewable only - subscription - with conditional rights'
  },
  {
    id: 'txcd_10305000',
    type: 'digital',
    description: 'Works that are required as part of a formal academic education program and are transferred electronically. These goods are downloaded to a device with access that expires after a stated period of time.',
    name: 'Digital School Textbooks - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10305001',
    type: 'digital',
    description: 'Works that are required as part of a formal academic education program and are transferred electronically. These goods are downloaded to a device with permanent access granted.',
    name: 'Digital School Textbooks - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10401000',
    type: 'digital',
    description: 'Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically.  These goods are streamed to a device with access that expires after a stated period of time.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.',
    name: 'Digital Audio Works - streamed - non subscription - with limited rights'
  },
  {
    id: 'txcd_10401001',
    type: 'digital',
    description: 'Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically.  These goods are downloaded to a device with access that expires after a stated period of time.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.',
    name: 'Digital Audio Works - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10401100',
    type: 'digital',
    description: 'Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are downloaded to a device with permanent access granted. These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.',
    name: 'Digital Audio Works - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10401200',
    type: 'digital',
    description: 'Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are streamed to a device with access that is conditioned upon continued subscription payment. These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.',
    name: 'Digital Audio Works - streamed - subscription - with conditional rights'
  },
  {
    id: 'txcd_10402000',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any.  These goods are streamed to a device with access that expires after a stated period of time.  These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - streamed - non subscription - with limited rights'
  },
  {
    id: 'txcd_10402100',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any.  These goods are downloaded to a device with permanent access granted.  These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10402110',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods are downloaded to a device with access that expires after a stated period of time. These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10402200',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any.  These goods are streamed to a device with access that is conditioned upon continued subscription payment.  These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - streamed - subscription - with conditional rights'
  },
  {
    id: 'txcd_10402300',
    type: 'digital',
    description: 'An audio visual work of a live performance streamed over the internet that you can only access for a limited time during the performance (e.g., a live concert stream).',
    name: 'Digital Video Streaming - live events - limited use'
  },
  {
    id: 'txcd_10501000',
    type: 'digital',
    description: 'Digital images that are downloaded to a device with permanent access granted.',
    name: 'Digital Photographs/Images - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10502000',
    type: 'digital',
    description: 'Gift card or gift certificate that you purchase and receive electronically and assumed to be multi-purpose.',
    name: 'Gift Card'
  },
  {
    id: 'txcd_10503000',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents.  These goods are downloaded to a device with permanent access granted.  These publications are accessed without a subscription.',
    name: 'Digital other news or documents - downloadable - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10503001',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents. These goods are downloaded to a device with access that expires after a stated period of time.',
    name: 'Digital other news or documents - downloadable - non subscription - with limited rights'
  },
  {
    id: 'txcd_10503002',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents. These goods are downloaded to a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital other news or documents - downloadable - subscription - with conditional rights'
  },
  {
    id: 'txcd_10503003',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with access that expires after a stated period of time.',
    name: 'Digital other news or documents - viewable only - non subscription - with limited rights'
  },
  {
    id: 'txcd_10503004',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with permanent access granted.',
    name: 'Digital other news or documents - viewable only - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10503005',
    type: 'digital',
    description: 'Individual digital news articles, newsletters, and other stand-alone documents. These goods are viewable (but not downloadable) on a device with access that is conditioned upon continued subscription payment.',
    name: 'Digital other news or documents - viewable only - subscription - with conditional rights'
  },
  {
    id: 'txcd_10504000',
    type: 'digital',
    description: 'Electronic software documentation or user manuals - For custom software & delivered electronically.',
    name: 'Electronic software documentation or user manuals - Custom, electronic delivery'
  },
  {
    id: 'txcd_10504003',
    type: 'digital',
    description: 'Electronic software documentation or user manuals - For prewritten software & delivered electronically.',
    name: 'Electronic software documentation or user manuals - Prewritten, electronic delivery'
  },
  {
    id: 'txcd_10505000',
    type: 'digital',
    description: 'The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with access that expires after a stated period of time. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.',
    name: 'Digital Finished Artwork - downloaded - non subscription - with limited rights'
  },
  {
    id: 'txcd_10505001',
    type: 'digital',
    description: 'The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with permanent access granted. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.',
    name: 'Digital Finished Artwork - downloaded - non subscription - with permanent rights'
  },
  {
    id: 'txcd_10505002',
    type: 'digital',
    description: 'The final art used for actual reproduction by photomechanical or other processes or for display purposes, but does not include website or home page design, and that is transferred electronically. These goods are downloaded to a device with access that is conditioned upon continued subscription payment. These goods include drawings, paintings, designs, photographs, lettering, paste-ups, mechanicals, assemblies, charts, graphs, illustrative materials, etc.',
    name: 'Digital Finished Artwork - downloaded - subscription - with conditional rights'
  },
  {
    id: 'txcd_10506000',
    type: 'digital',
    description: 'An electronic greeting "card" typically sent via email that contains an audio only message.',
    name: 'Digital Greeting Cards - Audio Only'
  },
  {
    id: 'txcd_10506001',
    type: 'digital',
    description: 'An electronic greeting "card" typically sent via email that contains a series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any.',
    name: 'Digital Greeting Cards - Audio Visual'
  },
  {
    id: 'txcd_10506002',
    type: 'digital',
    description: 'An electronic greeting "card" typically sent via email that contains only static images or text, rather than an audio visual or audio only experience.',
    name: 'Digital Greeting Cards - Static text and/or images only'
  },
  {
    id: 'txcd_10701000',
    type: 'digital',
    description: 'Online advertising services such as creating and uploading advertisements on the internet. This is a standalone service that doesn\'t involve the sale of tangible personal property.',
    name: 'Website Advertising'
  },
  {
    id: 'txcd_10701100',
    type: 'digital',
    description: 'A service to enable a customer\'s website to be accessible on the internet.',
    name: 'Website Hosting'
  },
  {
    id: 'txcd_10701200',
    type: 'services',
    description: 'A service to design a website or webpage.',
    name: 'Website Design'
  },
  {
    id: 'txcd_10701300',
    type: 'digital',
    description: 'An online service that allows a customer to create, transform, process or access data electronically.',
    name: 'Website Data Processing'
  },
  {
    id: 'txcd_10701400',
    type: 'digital',
    description: 'An online service furnishing information to customers, including online search and data comparison.',
    name: 'Website Information Services'
  },
  {
    id: 'txcd_10702000',
    type: 'digital',
    description: 'A subscription service for membership to an online dating platform.',
    name: 'Online Dating Services'
  },
  {
    id: 'txcd_10804001',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods are streamed and/or downloaded to a device with access that is conditioned upon continued subscription payment.  Any downloads received while under subscription remain the permanent property of the subscriber.  These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.  These goods further include self-study web based training services that impart content via audio visual goods described here.',
    name: 'Digital Audio Visual Works - bundle - downloaded with permanent rights and streamed - subscription - with conditional rights'
  },
  {
    id: 'txcd_10804002',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods can be streamed and/or downloaded to a device with access that expires after a stated period of time. These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - bundle - downloaded with limited rights and streamed - non subscription'
  },
  {
    id: 'txcd_10804003',
    type: 'digital',
    description: 'A series of related images which, when shown in succession, impart an impression of motion, together with accompanying sounds, if any. These goods can be streamed and/or downloaded to a device with permanent access granted. These goods include motion pictures, music videos, animations, news and entertainment programs, and live events, but do not include video greeting cards or video or electronic games.',
    name: 'Digital Audio Visual Works - bundle - downloaded with permanent rights and streamed - non subscription'
  },
  {
    id: 'txcd_10804010',
    type: 'digital',
    description: 'Works that result from the fixation of a series of musical, spoken, or other sounds that are transferred electronically. These goods are streamed and/or downloaded to a device with access that is conditioned upon continued subscription payment.  Any downloads received while under subscription remain the permanent property of the subscriber.  These goods include prerecorded or live music, prerecorded or live readings of books or other written materials, prerecorded or live speeches, ringtones, or other sound recordings, but not including audio greeting cards.   These goods further include self-study web based training services that impart content via audio goods described here.  Note the presence of PTC 10301000 (Audiobook), a more granular option for downloaded audiobooks.',
    name: 'Digital Audio Works - bundle - downloaded with permanent rights and streamed - subscription - with conditional rights'
  },
  {
    id: 'txcd_20010001',
    type: 'services',
    description: 'A charge to clean, wash or wax a motor vehicle, other than a self-service coin (or credit card) operated washing station. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Car Washing'
  },
  {
    id: 'txcd_20010003',
    type: 'services',
    description: 'A charge for the cleaning of tangible personal property, other than motor vehicles or clothing.',
    name: 'Cleaning of Tangible Personal Property'
  },
  {
    id: 'txcd_20010004',
    type: 'services',
    description: 'A charge for janitorial services to commercial buildings, including the cleaning of floors, carpets, walls, windows, fixtures, exterior cleaning, etc.',
    name: 'Commercial Cleaning Services'
  },
  {
    id: 'txcd_20010006',
    type: 'services',
    description: 'A charge for custodial services to residential structures, including the cleaning of floors, carpets, walls, windows, appliances, furniture, fixtures, exterior cleaning, etc. No Tangible Personal Property is transferred.',
    name: 'Residential Cleaning Services'
  },
  {
    id: 'txcd_20010007',
    type: 'services',
    description: 'A charge for the service to maintain the proper operation of home or building gutters through cleaning out debris that could otherwise affect the proper water flow through the gutter system.',
    name: 'Gutter Cleaning Services'
  },
  {
    id: 'txcd_20020009',
    type: 'services',
    description: 'A labor charge to install hardware where the installed property isn\'t considered permanently attached to the real property.',
    name: 'Installation of Hardware - Nonpermanent'
  },
  {
    id: 'txcd_20020010',
    type: 'services',
    description: 'A labor charge to install hardware where the installed property is permanently attached to the real property.',
    name: 'Installation of Hardware - Permanent'
  },
  {
    id: 'txcd_20020018',
    type: 'services',
    description: 'A charge separately stated from any sale of the product itself for the installation of tangible personal property. This is a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Installation of Tangible Personal Property'
  },
  {
    id: 'txcd_20030002',
    type: 'services',
    description: 'Services provided by a facility for overnight care of an animal not related to veterinary care.',
    name: 'Pet Boarding'
  },
  {
    id: 'txcd_20030003',
    type: 'services',
    description: 'Grooming services for an animal such as haircuts, bathing, nail trimming, and flea dips.',
    name: 'Pet Grooming'
  },
  {
    id: 'txcd_20030004',
    type: 'services',
    description: 'A charge for providing usage of a parking space.',
    name: 'Parking Services'
  },
  {
    id: 'txcd_20030005',
    type: 'services',
    description: 'Service to train an animal to obey certain commands.',
    name: 'Pet Services - Obedience Training'
  },
  {
    id: 'txcd_20040001',
    type: 'services',
    description: 'Services provided by beauty shops and barber shops, including but not limited to haircutting, hair coloring, shampooing, blow drying, permanents, hair extensions, hair straightening, and hair restorations.',
    name: 'Hairdressing Services'
  },
  {
    id: 'txcd_20040002',
    type: 'services',
    description: 'A charge for personal care services.  Please select a more granular product tax category where appropriate.',
    name: 'Personal Care Services'
  },
  {
    id: 'txcd_20040003',
    type: 'services',
    description: 'A charge for the puncturing or penetration of the skin of a person and the insertion of jewelry or other adornment into the opening.',
    name: 'Body Piercing'
  },
  {
    id: 'txcd_20040004',
    type: 'services',
    description: 'Services that provide non-medical care and supervision for infant to school-age children or senior citizens.',
    name: 'Childcare Services / Adultcare'
  },
  {
    id: 'txcd_20040005',
    type: 'services',
    description: 'A service that arranges introductions, for a fee, for strangers seeking romantic partners or friends. This excludes online dating services.',
    name: 'Dating Services'
  },
  {
    id: 'txcd_20040006',
    type: 'services',
    description: 'Services provided by employment agencies to match employees to employers and employers to employees.',
    name: 'Employment Services'
  },
  {
    id: 'txcd_20040007',
    type: 'services',
    description: 'A charge for the cosmetic beauty treatment for the fingernails and toenails, including filing, cutting, shaping and the application of polish.',
    name: 'Manicure Services'
  },
  {
    id: 'txcd_20040008',
    type: 'services',
    description: 'Services where the use of structured touch, include holding, applying pressure, positioning, and mobilizing soft tissue of the body by manual technique. Note: This does not include medical massage prescribed by a physician.',
    name: 'Massage Services'
  },
  {
    id: 'txcd_20040009',
    type: 'services',
    description: 'Personal services typically with the purpose of improving health, beauty and relaxation through treatments such as hair, massages and facials.',
    name: 'Spa Services'
  },
  {
    id: 'txcd_20040010',
    type: 'services',
    description: 'Services for artificial tanning and skin beautification.',
    name: 'Tanning Services'
  },
  {
    id: 'txcd_20040011',
    type: 'services',
    description: 'A charge for the process that uses needles and colored ink to permanently put a mark or design on a person�s skin. Also applying permanent make-up, such as eyelining and other permanent colors to enhance the skin of the face, lips, eyelids, and eyebrows.',
    name: 'Tattooing Services'
  },
  {
    id: 'txcd_20060000',
    type: 'services',
    description: 'A charge for a service performed by providers considered professionals due to the extensive initial and continuing education and/or training requirements, and the likelihood of state or federal licensing requirements. Choose this product tax code for any professional service offering in which a more detailed code is not available.',
    name: 'Professional Services'
  },
  {
    id: 'txcd_20060001',
    type: 'services',
    description: 'The measurement, processing and communication of financial information about economic entities including, but is not limited to, financial accounting, management accounting, auditing, cost containment and auditing services, taxation and accounting information systems; excluding general bookkeeping service.',
    name: 'Accounting Services'
  },
  {
    id: 'txcd_20060002',
    type: 'services',
    description: 'Services rendered for advertising which do not include the exchange of tangible personal property.',
    name: 'Advertising Services'
  },
  {
    id: 'txcd_20060008',
    type: 'services',
    description: 'Services rendered for the compiling and relating of information regarding the history of financial transactions of a person or entity.',
    name: 'Credit Reporting Services'
  },
  {
    id: 'txcd_20060009',
    type: 'services',
    description: 'A service that allows data to be created, transformed, where the service is not performed online. Select "Website data processing" if the service is performed online and data is accessed by the customer electronically.',
    name: 'Data Processing'
  },
  {
    id: 'txcd_20060010',
    type: 'services',
    description: 'Services performed to collect or adjust past-due debt from borrowers.',
    name: 'Debt Collection Services'
  },
  {
    id: 'txcd_20060013',
    type: 'services',
    description: 'Services provided by a professional trained to apply physical laws and principles of engineering in the design, development, and utilization of machines, materials, instruments, structures, processes, and systems. The services involve any of the following activities: provision of advice, preparation of feasibility studies, preparation of preliminary and final plans and designs, provision of technical services during the construction or installation phase, inspection and evaluation of engineering projects, and related services.',
    name: 'Engineering Services'
  },
  {
    id: 'txcd_20060017',
    type: 'services',
    description: 'Charges for installing, configuring, debugging, modifying, testing, or troubleshooting computer hardware, networks, programs or software. Labor only charge.',
    name: 'Technical Support Services'
  },
  {
    id: 'txcd_20060021',
    type: 'services',
    description: 'Charges associated loss or damage appraisals, inspections, actuarial services, claims adjustment or processing.  Investigations as excluded from this definition.',
    name: 'Insurance Services'
  },
  {
    id: 'txcd_20060022',
    type: 'services',
    description: 'Planning and design of interior spaces, including preparation of layout drawings, furniture arranging, design planning and purchasing of furniture, fixtures, and cabinetry, staging, lighting and sound design.',
    name: 'Interior Decorating'
  },
  {
    id: 'txcd_20060026',
    type: 'services',
    description: 'Services relating to advocating for the passage or defeat of legislation to members or staff of the government.',
    name: 'Lobbying Services'
  },
  {
    id: 'txcd_20060027',
    type: 'services',
    description: 'Services provided by a licensed or registered professional in the medical field.  Examples: Doctor, dentist, nurse, optometrist, etc.',
    name: 'Medical Professional Services'
  },
  {
    id: 'txcd_20060029',
    type: 'services',
    description: 'Services to process employee payroll, including wages, withholding taxes, benefits. etc.',
    name: 'Payroll Services'
  },
  {
    id: 'txcd_20060030',
    type: 'services',
    description: 'Services providing a systematic inquiry, examination, or analysis of people, events or documents through surveillance, background checks, computer searches, fingerprinting, lie detector services, interviews, etc.',
    name: 'Private Investigator Services'
  },
  {
    id: 'txcd_20060035',
    type: 'services',
    description: 'Services which include, but are not limited to, editing, letter writing, proofreading, resume writing, typing or word processing. Doesn\'t include court reporting and stenographic services.',
    name: 'Secretarial/Editing Services'
  },
  {
    id: 'txcd_20060040',
    type: 'digital',
    description: 'Charges to electronically file a tax return on behalf of the taxpayer.  This does not include manual preparation of tax returns.',
    name: 'Electronic Filing Service'
  },
  {
    id: 'txcd_20060042',
    type: 'services',
    description: 'Services relating to providing personnel, on a temporary basis, to perform work or labor under the supervision or control of another.',
    name: 'Temporary Help Services'
  },
  {
    id: 'txcd_20060044',
    type: 'services',
    description: 'A charge for training sessions which provide instruction to the buyer. This includes education sessions or workshops but excludes physical exercise or workouts.',
    name: 'Training'
  },
  {
    id: 'txcd_20060045',
    type: 'services',
    description: 'Live web based training. This does not include video replays of the instruction or course.  This service allows for a level of participation that is substantially similar to an in-person training on the same subject matter.',
    name: 'Training Services - Live Virtual'
  },
  {
    id: 'txcd_20060047',
    type: 'services',
    description: 'Services related to the art and science of designing and building structures for human habitation or use and includes planning, providing preliminary studies, designs, specifications, working drawings and providing for general administration of construction contracts.',
    name: 'Architectural Services'
  },
  {
    id: 'txcd_20060048',
    type: 'services',
    description: 'The provision of expertise or strategic advice that is presented for consideration and decision-making.',
    name: 'Consulting Services'
  },
  {
    id: 'txcd_20060049',
    type: 'services',
    description: 'Medical procedure performed on an individual that is directed at improving the individual\'s appearance and that does not meaningfully promote the proper function of the body or prevent or treat illness or disease.',
    name: 'Cosmetic Medical Procedure'
  },
  {
    id: 'txcd_20060050',
    type: 'digital',
    description: 'Services to track one\'s credit reports and provide notifications of activity with the intent of avoiding fraud.',
    name: 'Credit Monitoring Services'
  },
  {
    id: 'txcd_20060051',
    type: 'digital',
    description: 'A service that allows merchants to accept credit cards as well as send credit card payment details to the credit card network. It then forwards the payment authorization back to the acquiring bank.',
    name: 'Credit Card Processing Services'
  },
  {
    id: 'txcd_20060052',
    type: 'services',
    description: 'Academic classes provided by traditional private education establishments and organizations.',
    name: 'Educational Services'
  },
  {
    id: 'txcd_20060053',
    type: 'services',
    description: 'A charge for an objective visual examination of a house�s systems and physical structure. The charge includes a report of the inspector\'s findings including pictures, analysis, and recommendations.',
    name: 'Home Inspection Services'
  },
  {
    id: 'txcd_20060054',
    type: 'services',
    description: 'Services relating to or concerned with the law. Such services include, but are not limited to, representation by an attorney (or other person, when permitted) in an administrative or legal proceeding, legal drafting, paralegal services, legal research services, arbitration, mediation, and court reporting services.',
    name: 'Legal Services'
  },
  {
    id: 'txcd_20060055',
    type: 'services',
    description: 'Services for consumer research and testing in connection with the development of particular products or services that the client sells to consumers in the regular course of business.',
    name: 'Marketing Services'
  },
  {
    id: 'txcd_20060056',
    type: 'services',
    description: 'Services to provide outreach designed to influence the general public or other groups by promoting the interests of a service recipient.',
    name: 'Public Relations'
  },
  {
    id: 'txcd_20060057',
    type: 'services',
    description: 'Services that include typing, taking shorthand, and taking and transcribing dictation for others for a consideration.',
    name: 'Stenographic Services'
  },
  {
    id: 'txcd_20060058',
    type: 'digital',
    description: 'Self Study web based training, not instructor led. This does not include downloads or streaming of video replays.',
    name: 'Training Services - Self-study Web-based'
  },
  {
    id: 'txcd_20060059',
    type: 'services',
    description: 'Personal or small group teaching, designed to help people who need extra help with their studies.',
    name: 'Tutoring'
  },
  {
    id: 'txcd_20070001',
    type: 'services',
    description: 'A charge for monitoring an electronically controlled alarm system for residential or commercial properties to minimize or prevent loss or damage to life, limb, or property.',
    name: 'Security - Alarm Services'
  },
  {
    id: 'txcd_20070006',
    type: 'services',
    description: 'A charge for services related to the eradication of insects, rodents, and other pests.',
    name: 'Extermination Services'
  },
  {
    id: 'txcd_20070007',
    type: 'services',
    description: 'A charge for services related to the maintenance of grounds.',
    name: 'Landscaping'
  },
  {
    id: 'txcd_20070008',
    type: 'services',
    description: 'A charge for services of general lawn and grounds maintenance, including lawn cutting, weeding, yard clean-up, shrub and tree trimming, periodic seeding or aeration, etc. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Lawn Maintenance Services'
  },
  {
    id: 'txcd_20070010',
    type: 'services',
    description: 'A charge for services related to the painting of surfaces or the application of wallpaper.',
    name: 'Painting & Wallpapering Services'
  },
  {
    id: 'txcd_20070013',
    type: 'services',
    description: 'Services related to protecting persons or their property, preventing the theft of goods, merchandise, or money. Responding to alarm signal device, burglar alarm, television camera, still camera, or a mechanical or electronic device installed or used to prevent or detect burglary, theft, shoplifting, pilferage, losses, or other security measures. Providing management and control of crowds for safety and protection.',
    name: 'Security - Guard Services'
  },
  {
    id: 'txcd_20070016',
    type: 'services',
    description: 'Services mapping, detecting or establishing the relative position of points at, above, or below the surface of the earth. These services may include surveying and mapping of areas above or below the surface of the earth, such as the creation of view easements or segregating rights in parcels of land by creating underground utility easements.',
    name: 'Property Surveying Services'
  },
  {
    id: 'txcd_20080003',
    type: 'services',
    description: 'A charge to repair or restore to operating condition a motor vehicle that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Motor Vehicle Repair'
  },
  {
    id: 'txcd_20080004',
    type: 'services',
    description: 'A charge to repair or restore footwear was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Shoe Repair'
  },
  {
    id: 'txcd_20080005',
    type: 'services',
    description: 'A charge to repair or restore tangible personal property that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Repair of Tangible Personal Property'
  },
  {
    id: 'txcd_20080007',
    type: 'services',
    description: 'A charge to repair or maintain real property including repairs to HVAC, electrical, flooring, etc.',
    name: 'Repairs to Real Property'
  },
  {
    id: 'txcd_20080008',
    type: 'services',
    description: 'A charge to repair or restore to operating condition an aircraft that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential. Commercial aircraft is excluded.',
    name: 'Aircraft Repair'
  },
  {
    id: 'txcd_20080009',
    type: 'services',
    description: 'A charge to repair or restore to operating condition an appliance (dishwasher, washing machine, refrigerator, etc.) that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Appliance Repair'
  },
  {
    id: 'txcd_20080010',
    type: 'services',
    description: 'A charge to repair or restore to operating condition computer hardware that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Computer Repair'
  },
  {
    id: 'txcd_20080011',
    type: 'services',
    description: 'A separately stated labor charge to cover a piece of furniture previously owned by the customer with new fabric coverings. Any materials transferred as part of the service are separately stated.',
    name: 'Furniture Reupholstering'
  },
  {
    id: 'txcd_20080012',
    type: 'services',
    description: 'A charge to repair or restore jewelry that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Jewelry Repair'
  },
  {
    id: 'txcd_20080013',
    type: 'services',
    description: 'A charge to repair or restore to operating condition a machine that was broken, worn, damaged, defective, or malfunctioning. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Machine Repair'
  },
  {
    id: 'txcd_20090001',
    type: 'services',
    description: 'A charge to perform an alteration on a item of clothing by the vendor of the article. The alteration is separately stated from the clothing, but contracted for at the time of the clothing purchase. Alterations could include hemming of a dress, shortening of pants, adjusting the waistline of a garment, etc.',
    name: 'Garment Alterations - in conjunction with purchase of garment'
  },
  {
    id: 'txcd_20090002',
    type: 'services',
    description: 'A charge to perform an alteration on a item of clothing by a service provider other than vendor of the article. The alteration is not linked to the clothing purchase. Alterations could include hemming of a dress, shortening of pants, adjusting the waistline of a garment, etc.',
    name: 'Garment Alterations - separate from purchase of garment'
  },
  {
    id: 'txcd_20090003',
    type: 'services',
    description: 'A charge to have files or documents shredded either onsite or offsite.',
    name: 'Shredding Service'
  },
  {
    id: 'txcd_20090004',
    type: 'services',
    description: 'A charge to a non-commercial customer for the cleaning or renovating clothing by immersion and agitation, spraying, vaporization, or immersion only, in a volatile, commercially moisture-free solvent or by the use of a volatile or inflammable product. This does not include the use of a self-service coin (or credit card) operated cleaning machine.',
    name: 'Dry Cleaning'
  },
  {
    id: 'txcd_20090006',
    type: 'services',
    description: 'A charge to create a finished good from materials supplied by the customer. This is a labor only charge to transform a customer\'s existing property.',
    name: 'Fabrication'
  },
  {
    id: 'txcd_20090010',
    type: 'services',
    description: 'A charge for the wrapping of articles in a box or bag with paper and other decorative additions. The charge is separately stated from the article.  This is not relevant for non-US countries where packaging is added to the price of the product.',
    name: 'Gift Wrapping - in conjunction with purchase of article'
  },
  {
    id: 'txcd_20090012',
    type: 'services',
    description: 'A charge to provide laundry services to clothing. The business customer is the owner of the items being cleaned.',
    name: 'Linen Services - Laundry only'
  },
  {
    id: 'txcd_20090015',
    type: 'services',
    description: 'A charge separately stated from the sale of the product itself that entitles the purchaser to future repair and labor services to return the defective item of tangible personal property to its original state. The warranty contract is mandatory and is required to be purchased on conjunction with the purchased tangible personal property. Motor vehicle warranties are excluded.',
    name: 'Warranty - Mandatory'
  },
  {
    id: 'txcd_20090018',
    type: 'services',
    description: 'A charge separately stated from the sale of the product itself that entitles the purchaser to future repair and labor services to return the defective item of tangible personal property to its original state. The warranty contract is optional to the purchaser. Motor vehicle warranties are excluded.',
    name: 'Warranty - Optional'
  },
  {
    id: 'txcd_20090022',
    type: 'services',
    description: 'A charge separately stated from the sale of the product itself to bring the article to its finished state and in the condition specified by the buyer.',
    name: 'Assembly - in conjunction with final purchase of article'
  },
  {
    id: 'txcd_20090023',
    type: 'services',
    description: 'A charge to assemble goods for a purchaser who will later sell the assembled goods to end consumers.',
    name: 'Assembly - prior to final purchase of article'
  },
  {
    id: 'txcd_20090024',
    type: 'services',
    description: 'A charge to a non-commercial customer for the cleaning or renovating items other than clothing by immersion and agitation, spraying, vaporization, or immersion only, in a volatile, commercially moisture-free solvent or by the use of a volatile or inflammable product. This does not include the use of a self-service coin (or credit card) operated cleaning machine.',
    name: 'Dry Cleaning - other than clothing'
  },
  {
    id: 'txcd_20090025',
    type: 'services',
    description: 'A charge for the wrapping of articles in a box or bag with paper and other decorative additions. The wrapping not linked the purchased of the article(s) and is performed by a party other vendor of the article(s).',
    name: 'Gift Wrapping - separate from purchase of article'
  },
  {
    id: 'txcd_20090026',
    type: 'services',
    description: 'A charge to provide laundry services to linens and the like. This charge is not for clothing items. The business customer is the owner of the items being cleaned.',
    name: 'Linen Services - Laundry only - other than clothing'
  },
  {
    id: 'txcd_20090027',
    type: 'services',
    description: 'A charge to make customer provided meat suitable for human consumption, typically referred to a butcher or slaughter services.',
    name: 'Meat Processing'
  },
  {
    id: 'txcd_20090028',
    type: 'physical',
    description: 'A charge for the printing, imprinting, lithographing, mimeographing, photocopying, and similar reproductions of various articles including mailers, catalogs, letterhead, envelopes, business cards, presentation folders, forms, signage, etc. The end result is the transfer of tangible personal property to the customer.',
    name: 'Printing'
  },
  {
    id: 'txcd_20090029',
    type: 'services',
    description: 'A charge for the printing, imprinting, or lithographing on any article supplied by the customer. The customer owns the article throughout the process. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Printing - customer supplied articles'
  },
  {
    id: 'txcd_20090030',
    type: 'services',
    description: 'A charge to preserve an animal\'s body via mounting or stuffing, for the purpose of display or study. The customer provide the animal. This a labor charge, with any non-separately stated property transferred in performing the service considered inconsequential.',
    name: 'Taxidermy Services'
  },
  {
    id: 'txcd_20090031',
    type: 'services',
    description: 'A charge for engraving services performed on goods owned by the purchaser.  This is a standalone service not done in conjunction with the sale of the goods.',
    name: 'Engraving Services -  separate from purchase of article'
  },
  {
    id: 'txcd_20090032',
    type: 'services',
    description: 'A charge for engraving a good, such as jewelry, in conjunction with the retail purchase of that item. The charge for the engraving services is separately stated from the charge for the good being engraved.',
    name: 'Engraving Services - in conjunction with purchase of article'
  },
  {
    id: 'txcd_20091000',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer where the service provider agrees to repair or replace certain parts on a motor vehicle that has had a failure after the original manufacturer�s warranty has expired. Commonly referred to as extended warranty or protection plan. Contract covers both parts and labor, and is made at the same time as Motor Vehicle purchase but is separately stated.',
    name: 'Optional Extended Warranty Contract on Motor Vehicle'
  },
  {
    id: 'txcd_20091001',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer for prepaid routine maintenance (e.g. oil changes, tire rotations, air filter, spark plugs, transmission and transfer case fluid changes) for a motor vehicle. Contract covers both parts and labor and is made at the same time as Motor Vehicle purchase but is separately stated.',
    name: 'Optional Maintenance Contract on Motor Vehicle'
  },
  {
    id: 'txcd_20091002',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer for prepaid routine maintenance (e.g. oil changes, tire rotations, air filter, spark plugs, transmission and transfer case fluid changes) of a leased motor vehicle. Contract covers both parts and labor.',
    name: 'Optional Extended Warranty Contract on Leased Motor Vehicle'
  },
  {
    id: 'txcd_20091003',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer where the service provider agrees to repair or replace certain parts on a leased motor vehicle that has had a failure after the original manufacturer�s warranty has expired. Commonly referred to as extended warranty or protection plan. Contract covers both parts and labor.',
    name: 'Optional Maintenance Contract on Leased Motor Vehicle'
  },
  {
    id: 'txcd_20091004',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer where the service provider agrees to repair or replace certain parts on a motor vehicle that has had a failure after the original manufacturer�s warranty has expired. Commonly referred to as extended warranty or protection plan. Contract covers both parts and labor and is made after the motor vehicle purchase.',
    name: 'Optional Extended Warranty Contract on Motor Vehicle - After Market'
  },
  {
    id: 'txcd_20091005',
    type: 'services',
    description: 'An agreement between a service contract provider and a customer for prepaid routine maintenance (e.g. oil changes, tire rotations, air filter, spark plugs, transmission and transfer case fluid changes) of a motor vehicle. Contract covers both parts and labor and is made after the motor vehicle purchase.',
    name: 'Optional Maintenance Contract on Motor Vehicle - After Market'
  },
  {
    id: 'txcd_20100001',
    type: 'services',
    description: 'Services under armed private security guard to transport from one place to another any currency, jewels, stocks, bonds, paintings, or other valuables of any kind in a specially equipped motor vehicle that offers a high degree of security.  It is assumed that the transport takes place within one country.',
    name: 'Armored Car Services'
  },
  {
    id: 'txcd_30011000',
    type: 'physical',
    description: 'Apparel and footwear for people made for general use.',
    name: 'Clothing & Footwear'
  },
  {
    id: 'txcd_30011002',
    type: 'physical',
    description: 'The clasp used to fasten a belt, sold separately from the belt.',
    name: 'Belt Buckle'
  },
  {
    id: 'txcd_30011003',
    type: 'physical',
    description: 'A belt is band of flexible material worn around a person\'s waist for supporting clothing.  Suspenders are a pair of straps that pass over the shoulders and fasten to the waistband of a pair of trousers or a skirt at the front and back to hold it up.',
    name: 'Belts or Suspenders'
  },
  {
    id: 'txcd_30011015',
    type: 'physical',
    description: 'A closed tube of fabric worn about the neck for warmth. It can be pulled over mouth and nose to protect against wind, dust, or other particles.',
    name: 'Neck Gaiter'
  },
  {
    id: 'txcd_30011020',
    type: 'physical',
    description: 'A set of standardized clothes worn primarily during attendnce at an educational institution.',
    name: 'School Uniforms'
  },
  {
    id: 'txcd_30011028',
    type: 'physical',
    description: 'Clothing or footwear, such as coats, hats, ear muffs, gloves, etc. worn on the human body made of imitation fur materials.',
    name: 'Fur Clothing - Synthetic'
  },
  {
    id: 'txcd_30011034',
    type: 'physical',
    description: 'A broad strip of cloth that covers the shoulders, neck, or head for warmth or appearance.',
    name: 'Scarves'
  },
  {
    id: 'txcd_30011045',
    type: 'physical',
    description: 'A necktie, or simply a tie, is a piece of cloth worn by men and women for decorative purposes around the neck, resting under the shirt collar and knotted at the throat, and often draped down the chest.',
    name: 'Neckties'
  },
  {
    id: 'txcd_30011100',
    type: 'physical',
    description: 'Clothing designed for, and not normally worn except while attending, a formal function, including tuxedos, bridal gowns, and evening gowns.',
    name: 'Formal or Special Occasion Wear'
  },
  {
    id: 'txcd_30011200',
    type: 'physical',
    description: 'Children\'s clothing and footwear including general purpose articles intended to be worn by a person based on certain size or age requirements.',
    name: 'Children\'s clothing and footwear'
  },
  {
    id: 'txcd_30011201',
    type: 'physical',
    description: 'Clothing or footwear, such as coats, hats, ear muffs, gloves, etc. worn on the human body made of the real pelt of an animal.',
    name: 'Fur Clothing'
  },
  {
    id: 'txcd_30021000',
    type: 'physical',
    description: 'Clothing, footwear, and accessories worn on a person\'s body while participating in recreational or sporting activities, and which are not typical for everyday usage.  Please select a more granular product tax category where appropriate.',
    name: 'Athletic Activity Clothing'
  },
  {
    id: 'txcd_30021001',
    type: 'physical',
    description: 'A band of absorbent material worn around the head or wrist to soak up sweat, especially by participants in physical activity.',
    name: 'Sweat Bands'
  },
  {
    id: 'txcd_30021200',
    type: 'physical',
    description: 'Bathing suits, swim trunks, or bathing costumes meant to be worn in or near water.',
    name: 'Swimsuits'
  },
  {
    id: 'txcd_30030001',
    type: 'physical',
    description: 'Clothing worn while performing an employment role, such as paramedic uniforms, firefighter uniforms, military uniforms, medical attire, law enforcement attire, etc.',
    name: 'Occupational Uniforms'
  },
  {
    id: 'txcd_30031004',
    type: 'physical',
    description: 'Clothing and related covering material that is worn within cleanrooms, which require controlled low levels of environmental contaminants such as dusts, microbes, vapors, and aerosol particles.',
    name: 'Cleanroom Apparel'
  },
  {
    id: 'txcd_30031041',
    type: 'physical',
    description: 'High-visibility warning clothing to signal the presence of the user in places and situations which may cause potential threats to their health and safety.',
    name: 'Reflective Apparel'
  },
  {
    id: 'txcd_30031404',
    type: 'physical',
    description: 'Clothing for human wear and designed as protection of the wearer against injury or disease or as protections against damage or injury of other persons or property but not suitable for general use.',
    name: 'Protective/Safety Clothing'
  },
  {
    id: 'txcd_30031405',
    type: 'physical',
    description: 'Clothing worn by an employee during the course of employment for protection against injury or disease or as protection against damage or injury of other persons or property.  Such clothing items are not suitable for general use.',
    name: 'Protective/Safety Clothing - Occupational Use'
  },
  {
    id: 'txcd_30031406',
    type: 'physical',
    description: 'Footwear for human wear and designed as protection of the wearer against injury or disease or as protections against damage or injury of other persons or property but not suitable for general use.',
    name: 'Protective/Safety Footwear'
  },
  {
    id: 'txcd_30031407',
    type: 'physical',
    description: 'Footwear worn by an employee during the course of employment for protection against injury or disease or as protection against damage or injury of other persons or property.  Such clothing items are not suitable for general use.',
    name: 'Protective/Safety Footwear - Occupational Use'
  },
  {
    id: 'txcd_30031408',
    type: 'physical',
    description: 'Equipment type accessories (such as helmets, eyeshields, facemasks, pads, etc) for human wear and designed as protection of the wearer against injury or disease or as protections against damage or injury of other persons or property but not suitable for general use.',
    name: 'Protective/Safety Equipment'
  },
  {
    id: 'txcd_30031409',
    type: 'physical',
    description: 'Equipment type accessories (such as helmets, eyeshields, facemasks, pads, etc) worn by an employee during the course of employment for protection against injury or disease or as protection against damage or injury of other persons or property.  Such clothing items are not suitable for general use.',
    name: 'Protective/Safety Equipment - Occupational Use'
  },
  {
    id: 'txcd_30040003',
    type: 'physical',
    description: 'A reusable mask made of common fabrics worn over the mouth and nose to act as personal protection from airborne particles or pathogens.',
    name: 'Cloth Face Masks'
  },
  {
    id: 'txcd_30040005',
    type: 'physical',
    description: 'Masks and accessories sold separately to be worn for costume purposes for seasonal or cultural holidays or celebrations.',
    name: 'Costume Masks and Accessories'
  },
  {
    id: 'txcd_30040006',
    type: 'physical',
    description: 'Apparel worn by a person for seasonal or cultural holidays or celebrations, rather than for everday wear.',
    name: 'Costumes'
  },
  {
    id: 'txcd_30051302',
    type: 'physical',
    description: 'Gloves for general use (e.g. leather), but not gloves used for sporting (e.g., baseball, football gloves).',
    name: 'Gloves (general use)'
  },
  {
    id: 'txcd_30060001',
    type: 'physical',
    description: 'Bags including handbags, purses, coin purses, fanny packs / bum bags, and diaper / nappy bags.',
    name: 'Purses and Handbags'
  },
  {
    id: 'txcd_30060004',
    type: 'physical',
    description: 'A small usually square piece of cloth used for usually personal purposes (such as blowing the nose) or as a clothing accessory.',
    name: 'Handkerchiefs'
  },
  {
    id: 'txcd_30060005',
    type: 'physical',
    description: 'Hair notions such as headbands, barrettes, bows, clips, and pins.',
    name: 'Hair Accessories'
  },
  {
    id: 'txcd_30060006',
    type: 'physical',
    description: 'A shaped covering for the head worn for warmth, as a fashion item, or as part of a uniform.',
    name: 'Hats'
  },
  {
    id: 'txcd_30060007',
    type: 'physical',
    description: 'Jewelry such as necklaces, earrings, rings, and more.',
    name: 'Jewelry'
  },
  {
    id: 'txcd_30060010',
    type: 'physical',
    description: 'Articles, although worn on the body, which are not considered clothing or footwear.  Examples include armbands, pocket protectors, shoulder boards, sleep masks, etc.  Please select a more granular product tax category where appropriate.',
    name: 'Non-Clothing Accessories'
  },
  {
    id: 'txcd_30060011',
    type: 'physical',
    description: 'A plastic cap worn in the shower to keep the hair dry.',
    name: 'Shower Caps'
  },
  {
    id: 'txcd_30060013',
    type: 'physical',
    description: 'Umbrellas.',
    name: 'Umbrellas'
  },
  {
    id: 'txcd_30060014',
    type: 'physical',
    description: 'Wigs, Hairpieces, Hair extensions.',
    name: 'Wigs, Hairpieces, Hair extensions'
  },
  {
    id: 'txcd_30060015',
    type: 'physical',
    description: 'Suitcases and baggage typically used for transporting travellers\' belongings.',
    name: 'Luggage'
  },
  {
    id: 'txcd_30060016',
    type: 'physical',
    description: 'A small timepiece worn typically on a strap on one\'s wrist.',
    name: 'Watches'
  },
  {
    id: 'txcd_30060017',
    type: 'physical',
    description: 'Sunglasses with a lens containing no lens powers to correct vision problems.',
    name: 'Sunglasses - Non-prescription'
  },
  {
    id: 'txcd_30060018',
    type: 'physical',
    description: 'A digital wristwatch that provides many other features besides timekeeping. Like a smartphone, a smartwatch has a touchscreen display, which allows you to perform actions by tapping or swiping on the screen. Smartwatches include allow access to apps, similar to apps for smartphones and tablets.',
    name: 'Watches - Smart'
  },
  {
    id: 'txcd_30060019',
    type: 'physical',
    description: 'A flat, rectangular case with a handle, often of leather, for carrying books, papers, etc.',
    name: 'Briefcases'
  },
  {
    id: 'txcd_30060020',
    type: 'physical',
    description: 'A piece of clothing, such as a kitchen apron, that you wear over the front of other clothes to keep the clothes clean.',
    name: 'Aprons'
  },
  {
    id: 'txcd_30060021',
    type: 'physical',
    description: 'A triangular or square piece of cloth tied around the head, face or neck for protective or decorative purposes.',
    name: 'Bandannas'
  },
  {
    id: 'txcd_30060101',
    type: 'physical',
    description: 'A small folding case for carrying paper money, credit cards and other flat objects.',
    name: 'Wallets'
  },
  {
    id: 'txcd_30070001',
    type: 'physical',
    description: 'A bicycle helmet that is NOT marketed and labeled as being intended for youth.',
    name: 'Bicycle Helmets - Adult'
  },
  {
    id: 'txcd_30070002',
    type: 'physical',
    description: 'A bicycle helmet marketed and labeled as being intended for youth.',
    name: 'Bicycle Helmets - Youth'
  },
  {
    id: 'txcd_30070003',
    type: 'physical',
    description: 'Footwear worn exclusively while participating in ballet or tap dancing.',
    name: 'Ballet or Tap Shoes'
  },
  {
    id: 'txcd_30070004',
    type: 'physical',
    description: 'Gloves worn by baseball/softball participants while hitting.',
    name: 'Baseball Batting Gloves'
  },
  {
    id: 'txcd_30070005',
    type: 'physical',
    description: 'Gloves worn while rolling a bowling ball to the support the muscles in the hand and wrist, and to provide extra padding or grip.',
    name: 'Bowling Gloves'
  },
  {
    id: 'txcd_30070006',
    type: 'physical',
    description: 'Footwear uniquely designed to be worn while bowling, having a slick smooth sole meant for gliding.',
    name: 'Bowling Shoes'
  },
  {
    id: 'txcd_30070007',
    type: 'physical',
    description: 'A heavily padded mitten worn in boxing.',
    name: 'Boxing Gloves'
  },
  {
    id: 'txcd_30070008',
    type: 'physical',
    description: 'Athletic footwear having cleats or spikes to improve traction on various playing field surfaces.',
    name: 'Cleated or Spiked Shoes'
  },
  {
    id: 'txcd_30070009',
    type: 'physical',
    description: 'Thin, lightweight gloves designed mainly for wide receivers in football to help with grip while catching.',
    name: 'Football Receiver Gloves'
  },
  {
    id: 'txcd_30070010',
    type: 'physical',
    description: 'A glove worn by golfers to give a firm grip on the handle of the golf club.',
    name: 'Golf Gloves'
  },
  {
    id: 'txcd_30070011',
    type: 'physical',
    description: 'Footwear designed for golf course play, usually spiked (featuring actual cleats on the outsole) or spikeless (featuring a flat outsole with rubber studs or dimples in place of spikes).',
    name: 'Golf Shoes'
  },
  {
    id: 'txcd_30070012',
    type: 'physical',
    description: 'Ice Skates.',
    name: 'Ice Skates'
  },
  {
    id: 'txcd_30070013',
    type: 'physical',
    description: 'A inflatable sleeveless jacket worn to keep a person afloat when in danger of drowning.',
    name: 'Life Vests'
  },
  {
    id: 'txcd_30070014',
    type: 'physical',
    description: 'Clothing apparel/uniforms that are specific to the training and competition of various martial arts.',
    name: 'Martial Arts Attire'
  },
  {
    id: 'txcd_30070015',
    type: 'physical',
    description: 'A helmet designed for used when opertating a motorcycle, atv, snowmobile, etc.',
    name: 'Motorcycle Helmets'
  },
  {
    id: 'txcd_30070017',
    type: 'physical',
    description: 'A protective device for the mouth that covers the teeth and gums to prevent and reduce injury to the teeth, arches, lips and gums.',
    name: 'Mouth Guards'
  },
  {
    id: 'txcd_30070018',
    type: 'physical',
    description: 'Roller Skates or Roller Blades.',
    name: 'Roller Skates or Roller Blades'
  },
  {
    id: 'txcd_30070019',
    type: 'physical',
    description: 'A stiff pad worn typically worn under socks to protect the shins when playing soccer, hockey, and other sports.',
    name: 'Shin Guards'
  },
  {
    id: 'txcd_30070020',
    type: 'physical',
    description: 'A hard protective pad for the shoulders used in certain sports, such as ice hockey and football.',
    name: 'Shoulder Pads for Sports'
  },
  {
    id: 'txcd_30070021',
    type: 'physical',
    description: 'A rigid padded shoe that extends above the ankle, is secured to the foot, and is locked into position in a ski binding.',
    name: 'Ski Boots'
  },
  {
    id: 'txcd_30070022',
    type: 'physical',
    description: 'Athletic uniforms not containing protective padding or gear.',
    name: 'Sport Uniforms'
  },
  {
    id: 'txcd_30070023',
    type: 'physical',
    description: 'Flippers on the foot or leg and made from rubber or plastic, to aid movement through the water in water sports.',
    name: 'Swim Fins'
  },
  {
    id: 'txcd_30070024',
    type: 'physical',
    description: 'Goggles to protect the eyes from material in the water, while swimming.',
    name: 'Swim Goggles'
  },
  {
    id: 'txcd_30070025',
    type: 'physical',
    description: 'A close-fitting garment  covering most of the body worn for warmth in water sports or diving.',
    name: 'Wetsuit'
  },
  {
    id: 'txcd_30070026',
    type: 'physical',
    description: 'An elastic support for the male genitals, worn especially in athletic or other strenuous activity.',
    name: 'Athletic Supporter'
  },
  {
    id: 'txcd_30070028',
    type: 'physical',
    description: 'Vests designed primarily for wear while hunting, but which are also adaptable to everyday use.',
    name: 'Hunting Vests'
  },
  {
    id: 'txcd_30070029',
    type: 'physical',
    description: 'Clothing, footwear, and accessories for wear while hunting, but that are not adaptable to everyday use.',
    name: 'Hunting Activity Clothing'
  },
  {
    id: 'txcd_30071000',
    type: 'physical',
    description: 'Protective gear worn about the human body while participating in athletic, recreational, or sporting activities, and which are not typical for everyday usage.   Please select a more granular product tax category where appropriate.',
    name: 'Sports Activities Equipment'
  },
  {
    id: 'txcd_30080001',
    type: 'physical',
    description: 'Button covers are clothing accessories that slip over simple buttons for stylistic adornment.',
    name: 'Button Covers'
  },
  {
    id: 'txcd_30080002',
    type: 'physical',
    description: 'Items  items used to make or repair clothing, including buttons, clasps, fabric, thread, yarn, snaps, hooks, zippers, sequins, fabric dye, and the like.',
    name: 'Component Clothing Items'
  },
  {
    id: 'txcd_30090005',
    type: 'physical',
    description: 'Rubber boots worn over regular footwear to prevent them from wet or soiled.',
    name: 'Overshoes'
  },
  {
    id: 'txcd_30090009',
    type: 'physical',
    description: 'Over the counter inserts or insoles for in-shoe foot comfort.',
    name: 'Shoe Inserts'
  },
  {
    id: 'txcd_30090010',
    type: 'physical',
    description: 'Shoelaces.',
    name: 'Shoelaces'
  },
  {
    id: 'txcd_31020001',
    type: 'physical',
    description: 'A piece of cloth or plastic fastened around a baby\'s neck to keep their clothes clean while eating.',
    name: 'Bibs'
  },
  {
    id: 'txcd_31020002',
    type: 'physical',
    description: 'Blankets made to stretch to tightly around babies, and may have features like velcro or specially designed shapes or flaps to facilitate swaddle wrapping.',
    name: 'Infant Swaddles'
  },
  {
    id: 'txcd_31020003',
    type: 'physical',
    description: 'A small lightweight blanket used to wrap an infant, eg. after bathing.',
    name: 'Infant Receiving Blankets'
  },
  {
    id: 'txcd_31030001',
    type: 'physical',
    description: 'A skin cream forming a protective barrier to help heal and soothe diaper rash discomfort. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Diaper Cream'
  },
  {
    id: 'txcd_31030003',
    type: 'physical',
    description: 'Non medicated disposable moistened cleansing wipes. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Baby Wipes'
  },
  {
    id: 'txcd_31031202',
    type: 'physical',
    description: 'Disposable diapers for infants.',
    name: 'Infant Diapers'
  },
  {
    id: 'txcd_31031203',
    type: 'physical',
    description: 'Absorbent single use underpants worn by children during potty training as a transition between diapers and cloth underwear.',
    name: 'Disposable Toddler Training Pants'
  },
  {
    id: 'txcd_31031204',
    type: 'physical',
    description: 'One time use diaper liners for capturing solid waste for ease of disposal.',
    name: 'Diaper Liners - Single Use'
  },
  {
    id: 'txcd_31031205',
    type: 'physical',
    description: 'Infant washable/reusable cloth diapers.',
    name: 'Cloth Diapers'
  },
  {
    id: 'txcd_31040001',
    type: 'physical',
    description: 'A bottle with a teat or nipple made for babies to drink from.',
    name: 'Baby Feeding Bottles/Nipples'
  },
  {
    id: 'txcd_31040002',
    type: 'physical',
    description: 'A mechanical device that lactating women use to extract milk from their breasts.',
    name: 'Breast Pumps'
  },
  {
    id: 'txcd_31070001',
    type: 'physical',
    description: 'A portable seat that can be installed and removed from motor vehicles designed to protect infants and children through a certain age or weight (includes booster seats).',
    name: 'Infant/Child Car Seat'
  },
  {
    id: 'txcd_32010001',
    type: 'physical',
    description: 'Devices used by diabetic individuals to monitor sugar levels in the blood, sold without prescription order of a licensed professional (e.g., blood sugar meters, glucose meters or glucometers).  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Blood Glucose Monitoring Devices'
  },
  {
    id: 'txcd_32010002',
    type: 'physical',
    description: 'Single use supplies utilized by diabetics to monitor blood sugar or urine, including skin puncture lancets, test strips for blood glucose monitors, visual read test strips, and urine test strips, sold without prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Diabetic Testing Supplies'
  },
  {
    id: 'txcd_32010003',
    type: 'physical',
    description: 'Devices used by diabetic individuals to monitor sugar levels in the blood, sold under prescription order of a licensed professional (e.g., blood sugar meters, glucose meters or glucometers).  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Blood Glucose Monitoring Devices with Prescription'
  },
  {
    id: 'txcd_32010004',
    type: 'physical',
    description: 'Single use supplies utilized by diabetics to monitor blood sugar or urine, including skin puncture lancets, test strips for blood glucose monitors, visual read test strips, and urine test strips, sold under prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Diabetic Testing Supplies with Prescription'
  },
  {
    id: 'txcd_32020001',
    type: 'physical',
    description: 'A substance that can only be obtained via a prescription of a licensed professional. A drug is a compound, substance, or preparation, and any component thereof, not including food or food ingredients, dietary supplements, or alcoholic beverages, that is: recognized in the official United States pharmacopoeia, official homeopathic pharmacopoeia of the United States, or official national formulary, and supplement to any of them; intended for use in the diagnosis, cure, mitigation, treatment, or prevention of disease; or intended to affect the structure or any function of the body. A drug can be intended for internal (ingestible, implant, injectable) or external (topical) application to the human body.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Prescription Drugs'
  },
  {
    id: 'txcd_32020002',
    type: 'physical',
    description: 'The term drug refers to a compound, substance or preparation intended for use in the diagnosis, cure, mitigation, treatment, or prevention of disease. An over-the-counter drug is a substance that contains a label identifying it as a drug and including a "drug facts" panel or a statement of active ingredients, that can be obtained without a prescription. A drug can be intended for internal (ingestible, implant, injectable) or external (topical) application to the human body.',
    name: 'Drugs - Over the Counter'
  },
  {
    id: 'txcd_32020003',
    type: 'physical',
    description: 'The term drug refers to a compound, substance or preparation intended for use in the diagnosis, cure, mitigation, treatment, or prevention of disease. An over-the-counter drug with prescrption is a substance that contains a label identifying it as a drug and including a "drug facts" panel or a statement of active ingredients, that can be obtained without a prescription, but is sold under prescription order of a licensed professional. A drug can be intended for internal (ingestible, implant, injectable) or external (topical) application to the human body. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Over-the-Counter Drugs with Prescription'
  },
  {
    id: 'txcd_32020004',
    type: 'physical',
    description: 'Synthetic or animal-based insulin used as an injectible drug for diabetes patients, sold under prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Insulin with Prescription'
  },
  {
    id: 'txcd_32020005',
    type: 'physical',
    description: 'Synthetic or animal-based insulin used as an injectible drug for diabetes patients, sold without prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Insulin'
  },
  {
    id: 'txcd_32020006',
    type: 'physical',
    description: 'Medical grade oxygen sold under prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Medical Oxygen with Prescription'
  },
  {
    id: 'txcd_32020011',
    type: 'physical',
    description: 'Medical grade oxygen sold without prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Medical Oxygen'
  },
  {
    id: 'txcd_32030001',
    type: 'physical',
    description: 'Male or female condoms used to prevent pregnancy or exposure to sexually transmitted diseases.',
    name: 'Condoms'
  },
  {
    id: 'txcd_32030002',
    type: 'physical',
    description: 'Male or female condoms and vaginal sponges used to prevent pregnancy and/or exposure to STDs, sold under prescription order of a licensed professional.',
    name: 'Condoms with Prescription'
  },
  {
    id: 'txcd_32030003',
    type: 'physical',
    description: 'Male or female condoms used to prevent pregnancy or exposure to STDs, containing a spermicidal lubricant as indicated by a "drug facts" panel or a statement of active ingredients.',
    name: 'Condoms with Spermicide'
  },
  {
    id: 'txcd_32030004',
    type: 'physical',
    description: 'Male or female condoms and vaginal sponges used to prevent pregnancy and/or exposure to STDs, containing a spermicidal lubricant as indicated by a "drug facts" panel or a statement of active ingredients, sold under prescription order of a licensed professional.',
    name: 'Condoms with Spermicide with Prescription'
  },
  {
    id: 'txcd_32030005',
    type: 'physical',
    description: 'A topical preparation containing a spermicidal lubricant to prevent pregnancy as indicated by a "drug facts" panel or a statement of active ingredients.',
    name: 'Contraceptive Ointments'
  },
  {
    id: 'txcd_32030006',
    type: 'physical',
    description: 'Over-the-Counter emergency contraceptive pills act to prevent pregnancy after intercourse. The contraceptive contains a hormone that prevents ovulation, fertilization, or implantation of an embryo.',
    name: 'Birth Control - Over-the-Counter Oral Contraceptives'
  },
  {
    id: 'txcd_32030007',
    type: 'physical',
    description: 'Over-the-Counter emergency contraceptive pills act to prevent pregnancy after intercourse, sold under prescription order of a licensed professional. The contraceptive contains a hormone that prevents ovulation, fertilization, or implantation of an embryo.',
    name: 'Birth Control - Over-the-Counter Oral Contraceptives with Prescription'
  },
  {
    id: 'txcd_32030008',
    type: 'physical',
    description: 'Barrier based prescription only birth control methods, including the diaphragm and cervical cap that prevent the joining of the sperm and egg, available only under prescription order of a licensed professional.',
    name: 'Birth Control - Prescription non-Oral Contraceptives - Barriers'
  },
  {
    id: 'txcd_32030009',
    type: 'physical',
    description: 'Hormonal based birth control methods other than the oral pill, including intrauterine devices, injections, skin implants, transdermal patches, and vaginal rings that release a continuous dose of hormones to eliminate ovulation and prevent pregnancy, available only under prescription order of a licensed professional.',
    name: 'Birth Control - Prescription non-Oral Contraceptives - Hormonal'
  },
  {
    id: 'txcd_32030010',
    type: 'physical',
    description: 'An oral medication containing hormones effective in altering the menstrual cycle to eliminate ovulation and prevent pregnancy, available only under prescription order of a licensed professional. Other than preventing pregnancy, hormonal birth control can also be used to treat various conditions, such as Polycystic Ovary Syndrome, Endometriosis, Primary Ovarian Insufficiency, etc.',
    name: 'Birth Control - Prescription Oral Contraceptives'
  },
  {
    id: 'txcd_32040002',
    type: 'physical',
    description: 'Vaginal cleaning products include douches and wipes.',
    name: 'Feminine Cleansing Solutions'
  },
  {
    id: 'txcd_32040003',
    type: 'physical',
    description: 'Vaginal cleaning products include douches and wipes with medication such as an antiseptic, containing a "drug facts" panel or a statement of active ingredients.',
    name: 'Feminine Cleansing Solutions - Medicated'
  },
  {
    id: 'txcd_32040004',
    type: 'physical',
    description: 'Over-the-counter antifungal creams, ointments or suppositories to treat yeast infections, containing a "drug facts" panel or a statement of active ingredients.',
    name: 'Feminine Yeast Treatments'
  },
  {
    id: 'txcd_32040005',
    type: 'physical',
    description: 'Feminine hygiene product designed to absorb the menstrual flow.',
    name: 'Tampons/Pads/Sanitary Napkins/Liners'
  },
  {
    id: 'txcd_32050001',
    type: 'physical',
    description: 'Adult Disposable Diapers.',
    name: 'Adult Disposable Diapers'
  },
  {
    id: 'txcd_32050005',
    type: 'physical',
    description: 'Masks designed for one-time use to protect the wearer from contamination of breathable particles. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Disposable Face Masks'
  },
  {
    id: 'txcd_32050006',
    type: 'physical',
    description: 'Soaps, cleaning solutions, shampoo, toothpaste, mouthwash, antiperspirants, suntan lotions, etc.  Please select a more granular category as appropriate.',
    name: 'Grooming and Hygiene Products'
  },
  {
    id: 'txcd_32050007',
    type: 'physical',
    description: 'Soaps, cleaning solutions, shampoo, toothpaste, mouthwash, antiperspirants, suntan lotions, and sunscreens that contain medicinal ingredients as described on the product\'s label.  Please select a more granular category as appropriate.',
    name: 'Grooming and Hygiene Products - Medicated'
  },
  {
    id: 'txcd_32050009',
    type: 'physical',
    description: 'A liquid, gel, foam, or wipe generally used to decrease infectious agents on the hands. Alcohol-based versions typically contain some combination of isopropyl alcohol, ethanol (ethyl alcohol), or n-propanol. Alcohol-free products are generally based on disinfectants, or on antimicrobial agents. These products contain a "drug facts" panel or a statement of active ingredients. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hand Sanitizers'
  },
  {
    id: 'txcd_32050012',
    type: 'physical',
    description: 'Topical medicated solutions for treating skin acne.  These products contain a "drug facts" panel or a statement of active ingredients.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Medicated Acne Treatments'
  },
  {
    id: 'txcd_32050013',
    type: 'physical',
    description: 'Lotions, moisturizers, creams, powders, sprays, etc that promote optimal skin health that do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Skin Care Products'
  },
  {
    id: 'txcd_32050014',
    type: 'physical',
    description: 'Lotions, moisturizers, creams, powders, sprays, etc that promote optimal skin health and contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Skin Care Products - Medicated'
  },
  {
    id: 'txcd_32050015',
    type: 'physical',
    description: 'Over-the-counter nicotine replacement products, including patches, gum, lozenges, sprays and inhalers that contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Smoking Cessation Products'
  },
  {
    id: 'txcd_32050017',
    type: 'physical',
    description: 'Toilet tissue or paper primarily used for the removal of feces.',
    name: 'Toilet Tissue'
  },
  {
    id: 'txcd_32050018',
    type: 'physical',
    description: 'A small brush with a long handle, used for cleaning the teeth.',
    name: 'Toothbrush'
  },
  {
    id: 'txcd_32050019',
    type: 'physical',
    description: 'A thick, soft, moist substance used on a brush for cleaning one\'s teeth.',
    name: 'Toothpaste'
  },
  {
    id: 'txcd_32050020',
    type: 'physical',
    description: 'Baby oil is an inert (typically mineral) oil for the purpose of keeping skin soft and supple.  These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Baby Oil'
  },
  {
    id: 'txcd_32050021',
    type: 'physical',
    description: 'Baby powder is an astringent powder used for preventing diaper rash, as a spray, and for other cosmetic uses. It may be composed of talcum (in which case it is also called talcum powder) or corn starch. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Baby Powder'
  },
  {
    id: 'txcd_32050022',
    type: 'physical',
    description: 'Breath spray is a product sprayed into the mouth and breath strips dissolve in the mouth for the purpose of eliminating halitosis. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Breath Spray/Dissolvable Strips'
  },
  {
    id: 'txcd_32050023',
    type: 'physical',
    description: 'Various surfactant preparations to improve cleaning, enhance the enjoyment of bathing, and serve as a vehicle for cosmetic agents. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Bubble Bath/Bath Salts,Oils,Crystals'
  },
  {
    id: 'txcd_32050024',
    type: 'physical',
    description: 'A hair care product typically applied and rinsed after shampooing that is used to improve the feel, appearance and manageability of hair. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Conditioner - Hair'
  },
  {
    id: 'txcd_32050025',
    type: 'physical',
    description: 'Articles intended to be rubbed, poured, sprinkled, or sprayed on, introduced into, or otherwise applied to the human body or any part thereof for beautifying, promoting attractiveness, or altering the appearance. This category supports only the following items: Acrylic fingernail glue, Acrylic fingernails, Artificial eyelashes, Blush, Bronzer, Body glitter, Concealer, Eyelash glue, Finger/toenail decorations, Finger/toenail polish, Nail polish remover, Hair coloring, Hair mousse/gel, Hair oil, Hair spray, Hair relaxer, Hair wave treatment, Hair wax, Lip gloss, Lip liner, Lipstick, Liquid foundation, Makeup, Mascara, Nail polish remover, Powder foundation, Cologne, Perfume.',
    name: 'Cosmetics - Beautifying'
  },
  {
    id: 'txcd_32050026',
    type: 'physical',
    description: 'A soft, strong, waxed or unwaxed thread for drawing between the teeth to remove food particles and prevent the buildup of plaque.',
    name: 'Dental Floss/Picks'
  },
  {
    id: 'txcd_32050027',
    type: 'physical',
    description: 'Denture adhesives are pastes, powders or adhesive pads that may be placed in/on dentures to help them stay in place. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Denture Creams/Adhesives'
  },
  {
    id: 'txcd_32050028',
    type: 'physical',
    description: 'Personal under-arm deodorants/antiperspirants containing natural ingredients and/or ingredients that are not considered drugs. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Deodorant - Natural or No Active Ingredients'
  },
  {
    id: 'txcd_32050029',
    type: 'physical',
    description: 'Personal under-arm deodorants/antiperspirants. These products do contain a "drug facts" panel or a statement of active ingredients, typically aluminum.',
    name: 'Deodorant/Antiperspirant'
  },
  {
    id: 'txcd_32050030',
    type: 'physical',
    description: 'Topical foams, creams, gels, etc. that prevent hair loss and promote hair regrowth. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Hair Loss Products'
  },
  {
    id: 'txcd_32050031',
    type: 'physical',
    description: 'Depilatories are cosmetic preparations used to remove hair from the skin. Chemical depilatories are available in gel, cream, lotion, aerosol, roll-on, and powder forms. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Hair Removal Products'
  },
  {
    id: 'txcd_32050032',
    type: 'physical',
    description: 'A skin protectorant for the lips. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Lip Balm'
  },
  {
    id: 'txcd_32050033',
    type: 'physical',
    description: 'A medicated skin protectorant for the lips. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Lip Balm - Medicated'
  },
  {
    id: 'txcd_32050034',
    type: 'physical',
    description: 'Cosmetic mouthwash may temporarily control bad breath and leave behind a pleasant taste, but has no chemical or biological application beyond their temporary benefit. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Mouthwash - Cosmetic'
  },
  {
    id: 'txcd_32050035',
    type: 'physical',
    description: 'Therapeutic mouthwash, having active ingredients (such as antiseptic, or flouride) intended to help control or reduce conditions like bad breath, gingivitis, plaque, and tooth decay. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Mouthwash - Therapeutic'
  },
  {
    id: 'txcd_32050036',
    type: 'physical',
    description: 'A hair care product for cleansing the hair/scalp. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Shampoo'
  },
  {
    id: 'txcd_32050037',
    type: 'physical',
    description: 'A hair care product for cleansing the hair/scalp, with anti-dandruff active ingredients. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Shampoo - Medicated'
  },
  {
    id: 'txcd_32050038',
    type: 'physical',
    description: 'A cosmetic foam or gel used for shaving preparation. The purpose of shaving cream is to soften the hair by providing lubrication.  These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Shaving Creams'
  },
  {
    id: 'txcd_32050039',
    type: 'physical',
    description: 'Soaps, body washes, shower gels for personal hygiene containing antibacterial. These products contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Soaps - Antibacterial'
  },
  {
    id: 'txcd_32050040',
    type: 'physical',
    description: 'A lotion, spray, gel, foam, stick or other topical product that absorbs or reflects some of the sun\'s ultraviolet (UV) radiation and thus helps protect against sunburn. Sunscreen contains a "drug facts" label or statement of active ingredients.',
    name: 'Sunscreen'
  },
  {
    id: 'txcd_32050041',
    type: 'physical',
    description: 'Teeth whitening gels, rinse, strips, trays, etc containing bleaching agents. These products do not contain a "drug facts" panel or a statement of active ingredients.',
    name: 'Teeth Whitening Kits'
  },
  {
    id: 'txcd_32060001',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, equipment that: can withstand repeated use; is primarily and customarily used to serve a medical purpose; generally is not useful to a person in the absence of illness or injury; and is not worn in or on the body. Home use means the equipment is sold to an individual for use at home, regardless of where the individual resides. Examples include hospital beds, commode chairs, bed pans, IV poles, etc.',
    name: 'Durable Medical Equipment for Home Use'
  },
  {
    id: 'txcd_32060002',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, equipment that: can withstand repeated use; is primarily and customarily used to serve a medical purpose; generally is not useful to a person in the absence of illness or injury; and is not worn in or on the body. Home use means the equipment is sold to an individual for use at home, regardless of where the individual resides. Examples include hospital beds, commode chairs, bed pans, IV poles, etc.',
    name: 'Durable Medical Equipment for Home Use with Prescription'
  },
  {
    id: 'txcd_32060007',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, nutritional tube feeding equipment including button-style feeding tubes, standard G-tubes, NG-tubes, extension sets, adapters, feeding pumps, feeding pump delivery sets.',
    name: 'Enteral Feeding Equipment for Home Use with Prescription'
  },
  {
    id: 'txcd_32060012',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, a machine used that filters a patient\'s blood to remove excess water and waste products when the kidneys are damaged, dysfunctional, or missing. The kidney dialysis machine is an artificial part which augments the natural functioning of the kidneys.',
    name: 'Kidney Dialysis Equipment for Home Use with Prescription'
  },
  {
    id: 'txcd_32060017',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, a machine used that filters a patient\'s blood to remove excess water and waste products when the kidneys are damaged, dysfunctional, or missing. The kidney dialysis machine is an artificial part which augments the natural functioning of the kidneys.',
    name: 'Kidney Dialysis Equipment for Home Use'
  },
  {
    id: 'txcd_32060018',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, equipment used to administer oxygen directly into the lungs of the patient for the relief of conditions in which the human body experiences an abnormal deficiency or inadequate supply of oxygen. Oxygen equipment means oxygen cylinders, cylinder transport devices, including sheaths and carts, cylinder studs and support devices, regulators, flowmeters, tank wrenches, oxygen concentrators, liquid oxygen base dispensers, liquid oxygen portable dispensers, oxygen tubing, nasal cannulas, face masks, oxygen humidifiers, and oxygen fittings and accessories.',
    name: 'Oxygen Delivery Equipment for Home Use with Prescription'
  },
  {
    id: 'txcd_32060023',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, equipment used to administer oxygen directly into the lungs of the patient for the relief of conditions in which the human body experiences an abnormal deficiency or inadequate supply of oxygen. Oxygen equipment means oxygen cylinders, cylinder transport devices, including sheaths and carts, cylinder studs and support devices, regulators, flowmeters, tank wrenches, oxygen concentrators, liquid oxygen base dispensers, liquid oxygen portable dispensers, oxygen tubing, nasal cannulas, face masks, oxygen humidifiers, and oxygen fittings and accessories.',
    name: 'Oxygen Delivery Equipment for Home Use'
  },
  {
    id: 'txcd_32060024',
    type: 'physical',
    description: 'A heating pad is a pad used for warming of parts of the body in order to manage pain. Types of heating pads include electrical, chemical and hot water bottles. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Heating Pads'
  },
  {
    id: 'txcd_32060025',
    type: 'physical',
    description: 'A reusable pain management supply that includes artificial ice packs, gel packs, heat wraps, etc used for pain relief.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Cold or Hot Therapy Packs - Reusable'
  },
  {
    id: 'txcd_32060026',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, nutritional tube feeding equipment including button-style feeding tubes, standard G-tubes, NG-tubes, extension sets, adapters, feeding pumps, feeding pump delivery sets.',
    name: 'Enteral Feeding Equipment for Home Use'
  },
  {
    id: 'txcd_32070003',
    type: 'physical',
    description: 'An item that is applied directly to or inside a wound to absorb wound drainage, protect healing tissue, maintain a moist or dry wound environment (as appropriate), or prevent bacterial contamination. Examples include bandages, dressings, gauze, medical tape. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Wound Care Supplies - Bandages, Dressings, Gauze'
  },
  {
    id: 'txcd_32070004',
    type: 'physical',
    description: 'A wound care supply is defined as an item that is applied directly to or inside a wound to absorb wound drainage, protect healing tissue, maintain a moist or dry wound environment (as appropriate), or prevent bacterial contamination.  These supplies contain medication such as an antibiotic ointment. They are a labeled with a "drug facts" panel or a statement of active ingredients.Examples include bandages, dressings, gauze, medical tape. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Wound Care Supplies - Bandages, Dressings, Gauze - Medicated'
  },
  {
    id: 'txcd_32070018',
    type: 'physical',
    description: 'A collection of mixed supplies and equipment that is used to give medical treatment, often housed in durable plastic boxes, fabric pouches or in wall mounted cabinets. Qualifying medicinal items (eg. OTC drugs) make up 51% or more of the value of the kit. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'First Aid Kits'
  },
  {
    id: 'txcd_32070019',
    type: 'physical',
    description: 'Single use disposable gloves (latex, nitrile, vinyl, etc) not used for medical purposes.',
    name: 'Gloves - Disposable (non medical use)'
  },
  {
    id: 'txcd_32070020',
    type: 'physical',
    description: 'Single use disposable gloves (latex, nitrile, vinyl, etc) that have an application in a first aid or medical setting. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Gloves - Disposable (medical use)'
  },
  {
    id: 'txcd_32070028',
    type: 'physical',
    description: 'Items for use in cure, treatment or diagnosis of injury, illness, disease or incapacity, and which is consumed during the use.  Please select a more granular category as appropriate.',
    name: 'Medical Supplies - Consumable'
  },
  {
    id: 'txcd_32070029',
    type: 'physical',
    description: 'A collection of mixed supplies and equipment that is used to give medical treatment, often housed in durable plastic boxes, fabric pouches or in wall mounted cabinets. Qualifying medicinal items (eg. OTC drugs) make up 50% or less of the value of the kit. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'First Aid Kits - 50% or Less Medicinal Items'
  },
  {
    id: 'txcd_32070030',
    type: 'physical',
    description: 'Liquid solution for cleaning and disinfecting contact lenses.',
    name: 'Contact Lens Disinfecting Solutions'
  },
  {
    id: 'txcd_32070031',
    type: 'physical',
    description: 'Liquid solution for lubricating/rewetting, but not disinfecting, contact lenses. This solution is applied directly to the eye.',
    name: 'Contact Lens Lubricating Solutions'
  },
  {
    id: 'txcd_32070032',
    type: 'physical',
    description: 'Single use cotton balls or swabs for application of antiseptics and medications and to cleanse scratches, cuts or minor wounds. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Cotton Balls/Swabs - Sterile'
  },
  {
    id: 'txcd_32070033',
    type: 'physical',
    description: 'Single use cotton balls or swabs for multi-purpose use other than applying medicines and cleaning wounds, due to not being sterile. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Cotton Balls/Swabs - Unsterile'
  },
  {
    id: 'txcd_32070034',
    type: 'physical',
    description: 'A single use pain management supply that includes artificial ice packs, gel packs, heat wraps, etc used for pain relief. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Cold or Hot Therapy Packs - Disposable'
  },
  {
    id: 'txcd_32070035',
    type: 'physical',
    description: 'A single use pain management supply that includes artificial ice packs, gel packs, heat wraps, etc used for pain relief.   These products contain a "drug facts" panel or a statement of active ingredients.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Cold or Hot Therapy Packs - Disposable - Medicated'
  },
  {
    id: 'txcd_32070036',
    type: 'physical',
    description: 'Flexible adhesive strips that attach over the bridge of the nose to lift the sides of the nose, opening the nasal passages to provide relief for congestion and snoring. The products are drug free and contain no active drug ingredients.',
    name: 'Nasal Breathing Strips'
  },
  {
    id: 'txcd_32070037',
    type: 'physical',
    description: 'Single use hollow needle commonly used with a syringe to inject insulin into the body by diabetic individuals, sold under prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hypodermic Needles/Syringes with Prescription - Insulin'
  },
  {
    id: 'txcd_32070038',
    type: 'physical',
    description: 'Single use hollow needle commonly used with a syringe to inject insulin into the body by diabetic individuals, sold without prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hypodermic Needles/Syringes - Insulin'
  },
  {
    id: 'txcd_32070039',
    type: 'physical',
    description: 'Hydrogen peroxide is a mild antiseptic used on the skin to prevent infection of minor cuts, scrapes, and burns. It may also be used as a mouth rinse to help remove mucus or to relieve minor mouth irritation (e.g., due to canker/cold sores, gingivitis). These products contain a "drug facts" panel or a statement of active ingredients. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hydrogen Peroxide'
  },
  {
    id: 'txcd_32070040',
    type: 'physical',
    description: 'A multi-purpose skin protectorant and topical ointment. These products contain a "drug facts" panel or a statement of active ingredients. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Petroleum Jelly'
  },
  {
    id: 'txcd_32070041',
    type: 'physical',
    description: 'Liquid drops to be placed inside the ear canal to reduce the symptoms of an ear ache, or to act as an ear drying aid, or to loosen, cleanse, and aid in the removal of ear wax. These products contain a "drug facts" panel or a statement of active ingredients. Examples include Ear Ache, Swimmers\' Ears, and Ear Wax removal drops. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Ear Drops - Medicated'
  },
  {
    id: 'txcd_32070042',
    type: 'physical',
    description: 'A liquid solution typically used as a topical antiseptic. The products contain a "drug facts" panel or a statement of active ingredients. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Isopropyl (Rubbing) Alcohol'
  },
  {
    id: 'txcd_32080001',
    type: 'physical',
    description: 'Aids that can increase, improve, or assist mobility in the bathroom such as raised toilet seat, tub & shower stool, handrails, grab bars, etc.',
    name: 'Bath Aids'
  },
  {
    id: 'txcd_32080003',
    type: 'physical',
    description: 'Equipment which is primarily and customarily used to provide or increase the ability to move from one place to another, sold without a prescription, and which is appropriate for use either in a home or a motor vehicle; Is not generally used by persons with normal mobility; and does not include any motor vehicle or equipment on a motor vehicle normally provided by a motor vehicle manufacturer. Examples include wheelchairs, crutches, canes, walkers, chair lifts, etc.',
    name: 'Mobility Enhancing Equipment'
  },
  {
    id: 'txcd_32080004',
    type: 'physical',
    description: 'Parts for items such as crutch pads, walker tips, cane tips, crutch tips, etc.',
    name: 'Repair & Replacement Parts for Mobility Enhancing Equipment'
  },
  {
    id: 'txcd_32080005',
    type: 'physical',
    description: 'Equipment which is primarily and customarily used to provide or increase the ability to move from one place to another, sold under a prescription, and which is appropriate for use either in a home or a motor vehicle; Is not generally used by persons with normal mobility; and does not include any motor vehicle or equipment on a motor vehicle normally provided by a motor vehicle manufacturer. Examples include wheelchairs, crutches, canes, walkers, chair lifts, etc.',
    name: 'Mobility Enhancing Equipment with Prescription'
  },
  {
    id: 'txcd_32090003',
    type: 'physical',
    description: 'Corrective lenses, including eyeglasses and contact lenses, sold without a prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Corrective Lenses'
  },
  {
    id: 'txcd_32090005',
    type: 'physical',
    description: 'Batteries specifically labeled and designed to operate hearing aid devices, sold without prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hearing Aid Batteries'
  },
  {
    id: 'txcd_32090006',
    type: 'physical',
    description: 'Artificial devices to correct or alleviate hearing deficiencies, sold without prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hearing Aids'
  },
  {
    id: 'txcd_32090007',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, a replacement, corrective, or supportive device, worn on or in the body to: Artificially replace a missing portion of the body; Prevent or correct physical deformity or malfunction; or Support a weak or deformed portion of the body. Worn in or on the body means that the item is implanted or attached so that it becomes part of the body, or is carried by the body and does not hinder the mobility of the individual. Examples include artificial limbs, pacemakers, orthotics, orthopedics, ostomy/colostomy devices, catheters, etc.',
    name: 'Prosthetic Device'
  },
  {
    id: 'txcd_32090008',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, a replacement, corrective, or supportive device, worn on or in the body to: Artificially replace a missing portion of the body; Prevent or correct physical deformity or malfunction; or Support a weak or deformed portion of the body. Worn in or on the body means that the item is implanted or attached so that it becomes part of the body, or is carried by the body and does not hinder the mobility of the individual. Examples include artificial limbs, pacemakers, orthotics, orthopedics, ostomy/colostomy devices, catheters, etc.',
    name: 'Prosthetic Devices with Prescription'
  },
  {
    id: 'txcd_32090013',
    type: 'physical',
    description: 'Corrective lenses, eyeglasses, sold under prescription order of a licensed professional.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Corrective Lenses with Prescription'
  },
  {
    id: 'txcd_32090014',
    type: 'physical',
    description: 'Contact lenses, sold under prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Contact Lenses with Prescription'
  },
  {
    id: 'txcd_32090015',
    type: 'physical',
    description: 'When sold under prescription order of a licensed professional, a replacement, corrective, or supportive device, worn in the mouth, including dentures, orthodontics, crowns, bridges, etc.',
    name: 'Dental Prosthetics with Prescription'
  },
  {
    id: 'txcd_32090016',
    type: 'physical',
    description: 'When sold without prescription order of a licensed professional, a replacement, corrective, or supportive device, worn in the mouth, including dentures, orthodontics, crowns, bridges, etc.',
    name: 'Dental Prosthetics'
  },
  {
    id: 'txcd_32090017',
    type: 'physical',
    description: 'Artificial devices to correct or alleviate hearing deficiencies, sold under prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hearing Aids with Prescription'
  },
  {
    id: 'txcd_32090018',
    type: 'physical',
    description: 'Batteries specifically labeled and designed to operate hearing aid devices, sold under prescription order of a licensed professional. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Hearing Aid Batteries with Prescription'
  },
  {
    id: 'txcd_32100001',
    type: 'physical',
    description: 'At home urine-based tests used to detect pregancy hormone levels.',
    name: 'Pregenacy Testing Kits'
  },
  {
    id: 'txcd_32100002',
    type: 'physical',
    description: 'At home urine-based tests used to detect impending ovulation to assist in pregnancy planning.',
    name: 'Ovulation Testing Kits'
  },
  {
    id: 'txcd_32100003',
    type: 'physical',
    description: 'An at-home infectious disease test kit that can be sold without a prescription.',
    name: 'Infectious Disease Test'
  },
  {
    id: 'txcd_32100004',
    type: 'physical',
    description: 'An at-home infectious disease test kit that can only be sold with a prescription.',
    name: 'Infectious Disease Test - Prescription only'
  },
  {
    id: 'txcd_32100005',
    type: 'physical',
    description: 'At home saliva, cheeek swab or blood drop based tests used to detect various genetic markers in an individual.',
    name: 'DNA Testing Kits'
  },
  {
    id: 'txcd_32100006',
    type: 'physical',
    description: 'One-time use medical thermometers for oral, temporal/forehead, or rectal body temperature diagnostics. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Medical Thermometers - Disposable'
  },
  {
    id: 'txcd_32100007',
    type: 'physical',
    description: 'Multiple use medical thermometers for oral, temporal/forehead, or rectal body temperature diagnostics. This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Medical Thermometers - Reusable'
  },
  {
    id: 'txcd_32100008',
    type: 'physical',
    description: 'Products carrying an Energy Star rating (e.g., light bulbs, appliances, etc.).  Please select a more granular product tax category where appropriate.',
    name: 'Energy Star Products'
  },
  {
    id: 'txcd_32100010',
    type: 'physical',
    description: 'At home digital or manual (aneroid) sphygmomanometers, also known as a blood pressure meter, blood pressure monitor, or blood pressure gauge, are devices used to measure blood pressure, composed of an inflatable cuff to collapse and then release the artery under the cuff in a controlled manner.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Blood Pressure Testing Devices'
  },
  {
    id: 'txcd_32100011',
    type: 'physical',
    description: 'An electronic device that clips onto a patient\'s finger to measure heart rate and oxygen saturation in his or her red blood cells.  This code is intended for sales directly to end consumers that are NOT healthcare providers.',
    name: 'Pulse Oximeter'
  },
  {
    id: 'txcd_32100012',
    type: 'physical',
    description: 'At home urine-based tests used to detect the presense of various drug substances in an individual.',
    name: 'Drug Testing Kits'
  },
  {
    id: 'txcd_32100013',
    type: 'physical',
    description: 'At home blood-prick based tests used to monitor cholesterol levels in an individual.',
    name: 'Cholesterol Testing Kits'
  },
  {
    id: 'txcd_33020001',
    type: 'physical',
    description: 'Ceiling Fans carrying an Energy Star rating.',
    name: 'Ceiling Fans - Energy Star'
  },
  {
    id: 'txcd_33020002',
    type: 'physical',
    description: 'Domestic air conditioning (central or room) systems carrying Energy Star rating.',
    name: 'Air conditioners - Energy Star'
  },
  {
    id: 'txcd_33020003',
    type: 'physical',
    description: 'Domestic gas or oil furnaces carrying an Energy Star rating.',
    name: 'Furnaces - Energy Star'
  },
  {
    id: 'txcd_33020004',
    type: 'physical',
    description: 'Domestic air source heat pumps carrying Energy Star rating.',
    name: 'Heat Pumps - Energy Star'
  },
  {
    id: 'txcd_33020005',
    type: 'physical',
    description: 'Domestic water heater carrying Energy Star rating.',
    name: 'Water Heater - Energy Star'
  },
  {
    id: 'txcd_33020006',
    type: 'physical',
    description: 'Domestic appliance carrying an Energy Star Rating which reduces and maintains the level of humidity in the air.',
    name: 'Dehumidifier - Energy Star'
  },
  {
    id: 'txcd_33020007',
    type: 'physical',
    description: 'Domestic gas or oil boilers for space or water heating carrying an Energy Star rating.',
    name: 'Boilers - Energy Star'
  },
  {
    id: 'txcd_33020008',
    type: 'physical',
    description: 'An in home programmable thermostat, such as a WiFi enabled smart thermostat, carrying an Energy Star rating.',
    name: 'Programmable Wall Thermostat - Energy Star'
  },
  {
    id: 'txcd_33020009',
    type: 'physical',
    description: 'Domestic standard size refrigerators carrying Energy Star rating.',
    name: 'Refrigerators - Energy Star'
  },
  {
    id: 'txcd_33020010',
    type: 'physical',
    description: 'Domestic dish washing appliances carrying Energy Star rating.',
    name: 'Dishwashers - Energy Star'
  },
  {
    id: 'txcd_33020011',
    type: 'physical',
    description: 'Domestic freezers carrying Energy Star rating.',
    name: 'Freezers- Energy Star'
  },
  {
    id: 'txcd_33020012',
    type: 'physical',
    description: 'Domestic clothes washing appliances carrying Energy Star rating.',
    name: 'Clothes Washing Machine - Energy Star'
  },
  {
    id: 'txcd_33020013',
    type: 'physical',
    description: 'Domestic clothes drying appliances carrying Energy Star rating.',
    name: 'Clothes Drying Machine - Energy Star'
  },
  {
    id: 'txcd_33020014',
    type: 'physical',
    description: 'Non-electric can opener.',
    name: 'Can Opener - Manual'
  },
  {
    id: 'txcd_33020200',
    type: 'physical',
    description: 'Water conserving products are for conserving or retaining groundwater; recharging water tables; or decreasing ambient air temperature, and so limiting water evaporation. Examples include soil sufactants, a soaker or drip-irrigation hose, a moisture control for a sprinkler or irrigation system, a rain barrel or an alternative rain and moisture collection system, a permeable ground cover surface that allows water to reach underground basins, aquifers or water collection points.',
    name: 'Water conserving products'
  },
  {
    id: 'txcd_33030001',
    type: 'physical',
    description: 'Single or multi-pack AA, AAA, c, D, 6-volt or 9-volt batteries, excluding automobile or boat batteries.',
    name: 'Alkaline Batteries'
  },
  {
    id: 'txcd_33031001',
    type: 'physical',
    description: 'Mobile phone batteries.',
    name: 'Mobile Phone Batteries'
  },
  {
    id: 'txcd_33080201',
    type: 'physical',
    description: 'Wipes used in the process of eliminating or reducing harmful microorganisms from inanimate objects and surfaces.',
    name: 'Disinfectant Wipes'
  },
  {
    id: 'txcd_33080202',
    type: 'physical',
    description: 'Paper based items for household use designed to be disposed of after single use, including the the following...Facial tissue; Paper bags; Paper freezer wrap; Paper place mats; Paper plates and cups; Paper napkins; Paper straws; Paper tablecloths; Paper towels.',
    name: 'Disposable Household Paper Products'
  },
  {
    id: 'txcd_33080300',
    type: 'physical',
    description: 'Includes blankets, pillows, bed linens/sheets, comforters, towels, wash cloths, shower curtains and bath mats.  Please select a more granular category where appropriate.',
    name: 'Household Linens/Bedding Towels Shower Curtains'
  },
  {
    id: 'txcd_33080301',
    type: 'physical',
    description: 'Bedclothes items including sheets, pillow cases, bedspreads, comforters, blankets, throws, duvet covers, pillow shams, bed skirts, mattress pad, mattress toppers, and pillows.',
    name: 'Bedding'
  },
  {
    id: 'txcd_33080302',
    type: 'physical',
    description: 'A small mat/rug used to cover portion of bathroom floor.',
    name: 'Bath Mats/Rugs'
  },
  {
    id: 'txcd_33080303',
    type: 'physical',
    description: 'Towels used for individual drying of persons, including bath towels, beach towels, wash cloths, hand towels, facetowels, sport towels, etc.',
    name: 'Bath Towels'
  },
  {
    id: 'txcd_33080304',
    type: 'physical',
    description: 'Shower curtain/liner used to keep water from escaping a showering area.',
    name: 'Shower Curtain or Liner'
  },
  {
    id: 'txcd_33080305',
    type: 'physical',
    description: 'A bumper/liner that borders the interior walls/slats of the crib to help protect the baby.',
    name: 'Crib Bumpers/Liners'
  },
  {
    id: 'txcd_33080306',
    type: 'physical',
    description: 'Dish towels used for kitchenware drying.',
    name: 'Dish Towels'
  },
  {
    id: 'txcd_33080400',
    type: 'physical',
    description: 'Yarn for use other than fabricating/repairing clothing.',
    name: 'Yarn - Non-clothing'
  },
  {
    id: 'txcd_33100001',
    type: 'physical',
    description: 'Landscape soil, mulch, compost - residential.',
    name: 'Landscape Soil, Mulch, Compost - Residential'
  },
  {
    id: 'txcd_33100101',
    type: 'physical',
    description: 'Products for use in non-commercial food producing gardens to control insects, weeds, and fungus.',
    name: 'Agricultural Chemicals - Non-commercial gardening'
  },
  {
    id: 'txcd_33100200',
    type: 'physical',
    description: 'Products for use in non-commercial food producing gardens to enhance plant growth.',
    name: 'Agricultural Fertilizer - Non-commercial gardening'
  },
  {
    id: 'txcd_33110001',
    type: 'physical',
    description: 'Standard incandescent light bulbs carrying an Energy Star rating.',
    name: 'Incandescent Light Bulbs - Energy Star'
  },
  {
    id: 'txcd_33110002',
    type: 'physical',
    description: 'Compact Fluorescent light (CFL) bulbs carrying an Energy Star rating.',
    name: 'Compact Fluorescent Light Bulbs - Energy Star'
  },
  {
    id: 'txcd_33110003',
    type: 'physical',
    description: 'Light emitting diode (LED) bulbs carrying an Energy Star rating.',
    name: 'LED Bulbs - Energy Star'
  },
  {
    id: 'txcd_33110004',
    type: 'physical',
    description: 'Portable self-powered or battery powered light sources, including flashlights, lanterns, emergency glow sticks or light sticks.',
    name: 'Portable Light Sources'
  },
  {
    id: 'txcd_33110005',
    type: 'physical',
    description: 'Candles to be used as a light source.',
    name: 'Candles'
  },
  {
    id: 'txcd_33120000',
    type: 'physical',
    description: 'Qualifying items for purposes of certain sales tax holidays such as cell phone batteries, cell phone chargers, and two-way radios.',
    name: 'Storm Preparedness Items'
  },
  {
    id: 'txcd_33120001',
    type: 'physical',
    description: 'Portable self-powered or battery powered radio, two-way radio, weatherband radio.',
    name: 'Portable Radios'
  },
  {
    id: 'txcd_33120002',
    type: 'physical',
    description: 'Non-electric food or beverage cooler.',
    name: 'Food Storage Cooler'
  },
  {
    id: 'txcd_33120003',
    type: 'physical',
    description: 'Smoke Detectors.',
    name: 'Smoke Detectors'
  },
  {
    id: 'txcd_33120004',
    type: 'physical',
    description: 'Carbon Monoxide Detectors.',
    name: 'Carbon Monoxide Detectors'
  },
  {
    id: 'txcd_33120005',
    type: 'physical',
    description: 'Fire Extinguishers.',
    name: 'Fire Extinguishers'
  },
  {
    id: 'txcd_33120006',
    type: 'physical',
    description: 'Portable fuel container.',
    name: 'Portable Fuel Container'
  },
  {
    id: 'txcd_33120007',
    type: 'physical',
    description: 'Non-electric water container to store water for emergency usage.',
    name: 'Water Storage Container'
  },
  {
    id: 'txcd_33120008',
    type: 'physical',
    description: 'Artificial ice, blue ice, ice packs, reusable ice.',
    name: 'Artificial Ice'
  },
  {
    id: 'txcd_33120009',
    type: 'physical',
    description: 'Tarps, plastic sheeting, plastic drop cloths, waterproof sheeting.',
    name: 'Tarpaulins and Weatherproof Sheeting'
  },
  {
    id: 'txcd_33120010',
    type: 'physical',
    description: 'Portable generator used to provide light or communications or power appliances during a power outage.',
    name: 'Portable Generator'
  },
  {
    id: 'txcd_33120011',
    type: 'physical',
    description: 'Power cords used to connect electrical equipment and devices to power sockets.',
    name: 'Power Cords'
  },
  {
    id: 'txcd_33120012',
    type: 'physical',
    description: 'An hand held tool with a bladed head on a handle used for hewing, cleaving, chopping, etc.',
    name: 'Axes/Hatchets'
  },
  {
    id: 'txcd_33120013',
    type: 'physical',
    description: 'Gas-powered chainsaw.',
    name: 'Garden Chainsaw'
  },
  {
    id: 'txcd_33120014',
    type: 'physical',
    description: 'Chainsaw accessories include chains, lubricants, motor oil, chain sharpeners, bars, wrenches, carrying cases, repair parts, safety apparel.',
    name: 'Chainsaw Accessories'
  },
  {
    id: 'txcd_33120015',
    type: 'physical',
    description: 'Plywood, window film, storm shutters, hurricane shutters or other materials specifically designed to protect windows.',
    name: 'Storm Shutters/Window Protection Devices'
  },
  {
    id: 'txcd_33120016',
    type: 'physical',
    description: 'Ladder used for home emergency evacuation.',
    name: 'Emergency/Rescue Ladder'
  },
  {
    id: 'txcd_33120017',
    type: 'physical',
    description: 'Ropes and Cords and similar fastening products.',
    name: 'Ropes and Cords'
  },
  {
    id: 'txcd_33120018',
    type: 'physical',
    description: 'Ground anchor systems and tie down kits for securing property against severe weather.',
    name: 'Ground Anchor Systems and Tie-down Kits'
  },
  {
    id: 'txcd_33120019',
    type: 'physical',
    description: 'Duct Tape.',
    name: 'Duct Tape'
  },
  {
    id: 'txcd_33150001',
    type: 'physical',
    description: 'WaterSense labeled toilets.',
    name: 'Toilets - WaterSense'
  },
  {
    id: 'txcd_33150002',
    type: 'physical',
    description: 'WaterSense labeled urinals.',
    name: 'Urinals - WaterSense'
  },
  {
    id: 'txcd_33150003',
    type: 'physical',
    description: 'WaterSense labeled bathroom sink faucets and accessories.',
    name: 'Bathroom Faucets - WaterSense'
  },
  {
    id: 'txcd_33150004',
    type: 'physical',
    description: 'WaterSense labeled sprinkler body is the exterior shell that connects to the irrigation system piping and houses the spray nozzle that applies water on the landscape.',
    name: 'Spray Water Sprinkler Bodies - WaterSense'
  },
  {
    id: 'txcd_33150005',
    type: 'physical',
    description: 'WaterSense labeled irrigation controllers, which act like a thermostat for your sprinkler system telling it when to turn on and off, use local weather and landscape conditions to tailor watering schedules to actual conditions on the site.',
    name: 'Irrigation Controls - WaterSense'
  },
  {
    id: 'txcd_33150006',
    type: 'physical',
    description: 'WaterSense labeled showerheads.',
    name: 'Showerheads - WaterSense'
  },
  {
    id: 'txcd_34010001',
    type: 'physical',
    description: 'A type of consumer electronic device used to play vinyl recordings.',
    name: 'Audio Turntables'
  },
  {
    id: 'txcd_34010002',
    type: 'physical',
    description: 'Portable audio equipment that plays audio files encoded in MP3 and other audio formats.',
    name: 'Digital Music Players'
  },
  {
    id: 'txcd_34010003',
    type: 'physical',
    description: 'A camera that captures moving images and sound and converts them into electronic signals so that they can be saved on a storage device, such as videotape or a hard drive, or viewed on a monitor.',
    name: 'Video Cameras'
  },
  {
    id: 'txcd_34020001',
    type: 'physical',
    description: 'A camera that captures photographs in digital memory.',
    name: 'Digital Cameras'
  },
  {
    id: 'txcd_34020002',
    type: 'physical',
    description: 'A framed display designed to display preloaded digital images (jpeg or any digital image format). Has slots for flash memory cards and/or an interface for digital photo camera connection.',
    name: 'Digital Picture Frames'
  },
  {
    id: 'txcd_34020003',
    type: 'physical',
    description: 'A portable electronic device for reading digital books and periodicals.',
    name: 'E-Book Readers'
  },
  {
    id: 'txcd_34020004',
    type: 'physical',
    description: 'Wired and bluetooth headphones and earbuds for audio listening.',
    name: 'Headphones/Earbuds'
  },
  {
    id: 'txcd_34020005',
    type: 'physical',
    description: 'Consumer electronics peripherals and accessories.  Please select a more granular product tax category where appropriate.',
    name: 'Consumer Electronics Peripherals/Accessories'
  },
  {
    id: 'txcd_34020006',
    type: 'physical',
    description: 'Televisions.',
    name: 'Televisions'
  },
  {
    id: 'txcd_34020027',
    type: 'physical',
    description: 'Electronic devices bought for personal rather than commercial use.  Please select a more granular product tax category where appropriate.',
    name: 'Consumer Electronics'
  },
  {
    id: 'txcd_34021000',
    type: 'physical',
    description: 'A portable device for connecting to a telecommunications network in order to transmit and receive voice, video, or other data.',
    name: 'Mobile Phones'
  },
  {
    id: 'txcd_34021001',
    type: 'physical',
    description: 'A device for recharging the battery of a mobile phone, especially one consisting of a cable connected to an adaptor which plugs into an electrical socket or usb port.',
    name: 'Mobile Phone Charging Device/cord'
  },
  {
    id: 'txcd_34022000',
    type: 'physical',
    description: 'A specialized computer system designed for interactive video gameplay that typically connects to a television or other monitor.',
    name: 'Video Gaming Console - Fixed'
  },
  {
    id: 'txcd_34022001',
    type: 'physical',
    description: 'A handheld portable electronic device used for playing video games that has its own screen, speakers and controls in one unit.',
    name: 'Video Gaming Console - Portable'
  },
  {
    id: 'txcd_34040001',
    type: 'physical',
    description: 'Typically a lithium-ion remote power source for laptop computers.',
    name: 'Computer Batteries'
  },
  {
    id: 'txcd_34040002',
    type: 'physical',
    description: 'Cables with industry standard connection and termination configurations used to connect various peripherals and equipment to computers.',
    name: 'Computer Cables'
  },
  {
    id: 'txcd_34040003',
    type: 'physical',
    description: 'Storage drives, hard drives, Zip drives, etc.',
    name: 'Computer Drives'
  },
  {
    id: 'txcd_34040004',
    type: 'physical',
    description: 'Computer Keyboards.',
    name: 'Computer Keyboards'
  },
  {
    id: 'txcd_34040005',
    type: 'physical',
    description: 'Computer Microphones.',
    name: 'Computer Microphones'
  },
  {
    id: 'txcd_34040006',
    type: 'physical',
    description: 'Computer Monitor/Displays.',
    name: 'Computer Monitor/Displays'
  },
  {
    id: 'txcd_34040007',
    type: 'physical',
    description: 'Computer Mouse/Pointing Devices.',
    name: 'Computer Mouse/Pointing Devices'
  },
  {
    id: 'txcd_34040008',
    type: 'physical',
    description: 'Computer Printer.',
    name: 'Computer Printer'
  },
  {
    id: 'txcd_34040009',
    type: 'physical',
    description: 'Computer Speakers.',
    name: 'Computer Speakers'
  },
  {
    id: 'txcd_34040010',
    type: 'physical',
    description: 'A docking station is a hardware frame and set of electrical connection interfaces that enable a notebook computer to effectively serve as a desktop computer.',
    name: 'Docking Stations'
  },
  {
    id: 'txcd_34040011',
    type: 'physical',
    description: 'A device that makes possible the transmission of data to or from a computer via telephone or other communication lines.',
    name: 'Modems'
  },
  {
    id: 'txcd_34040012',
    type: 'physical',
    description: 'A motherboard is the physical component in a computer that contains the computer\'s basic circuitry and other components.',
    name: 'Motherboards'
  },
  {
    id: 'txcd_34040013',
    type: 'physical',
    description: 'A port replicator is an attachment for a notebook computer that allows a number of devices such as a printer, large monitor, and keyboard to be simultaneously connected.',
    name: 'Port Replicators'
  },
  {
    id: 'txcd_34040014',
    type: 'physical',
    description: 'A device that connects computer networks to each other, and sends information between networks.  The router, as commonly used in the home, is the hardware that allows communication between your personal computers and other connected device and the internet.',
    name: 'Routers'
  },
  {
    id: 'txcd_34040015',
    type: 'physical',
    description: 'A device that scans documents and images and converts them into digital data for use on a computer.',
    name: 'Scanners'
  },
  {
    id: 'txcd_34040016',
    type: 'physical',
    description: 'A sound card is an expansion component used in computers to receive and send audio.',
    name: 'Sound Cards'
  },
  {
    id: 'txcd_34040017',
    type: 'physical',
    description: 'An expansion card that allows the computer to send graphical information to a video display device such as a monitor, TV, or projector. Video cards are often used by gamers in place of integrated graphics due to their extra processing power and video ram.',
    name: 'Video/Graphics Card'
  },
  {
    id: 'txcd_34040018',
    type: 'physical',
    description: 'A webcam is a video camera that feeds or streams an image or video in real time to or through a computer to a computer network, such as the Internet. Webcams are typically small cameras that sit on a desk, attach to a user\'s monitor, or are built into the hardware.',
    name: 'Web Camera'
  },
  {
    id: 'txcd_35010000',
    type: 'physical',
    description: 'Books or booklets consisting of text or illustrations bound in a stiffer cover than the pages.',
    name: 'Books'
  },
  {
    id: 'txcd_35010001',
    type: 'physical',
    description: 'Children\'s books including picture books, painting, drawing, and activity books.',
    name: 'Books for Children'
  },
  {
    id: 'txcd_35010400',
    type: 'physical',
    description: 'Printed religious books and manuals.',
    name: 'Religious Books'
  },
  {
    id: 'txcd_35010410',
    type: 'physical',
    description: 'The sacred writings of a religion.',
    name: 'Bibles'
  },
  {
    id: 'txcd_35010500',
    type: 'physical',
    description: 'Recognized in the ordinary and usual sense as a "book", except that the product is audio and delivered electronically or on a CD/DVD instead of in a printed format.',
    name: 'Audiobook (Physical Copy)'
  },
  {
    id: 'txcd_35020100',
    type: 'physical',
    description: 'Publications distributed to the public at regular intervals that contains news of general interest.',
    name: 'Newspapers'
  },
  {
    id: 'txcd_35020200',
    type: 'physical',
    description: 'Publications, including magazines, distributed on a periodic basis.',
    name: 'Periodicals'
  },
  {
    id: 'txcd_35020220',
    type: 'physical',
    description: 'Periodicals, printed, sold by subscription.',
    name: 'Magazine by Subscription'
  },
  {
    id: 'txcd_36010001',
    type: 'physical',
    description: 'Flea and tick products for animal use including soaps, cleaning solutions, and shampoos (e.g., flea collars, flea powder, tick and flea soap, and tick sprays).',
    name: 'Flea & Tick Products'
  },
  {
    id: 'txcd_36010002',
    type: 'physical',
    description: 'Drugs or substance for treating animals and intended for use in the diagnosis, cure, mitigation, treatment, or prevention of disease.',
    name: 'Over-the-Counter Drugs for Animal Use Without a Prescription'
  },
  {
    id: 'txcd_36010003',
    type: 'physical',
    description: 'Medical supplies available without a prescription and used in the treatment of companion animals (e.g., first aid items such as bandages and gauze).',
    name: 'Over-the-Counter Medical Supplies for Pets'
  },
  {
    id: 'txcd_36010004',
    type: 'physical',
    description: 'Food for household pets that is consumed for nutritional value. This code is not intended for food related to working farm animals or animals raised for meat or milk production.',
    name: 'Pet Food'
  },
  {
    id: 'txcd_36010005',
    type: 'physical',
    description: 'Pet food products offering additional nutrients or functional ingredients.',
    name: 'Pet Vitamins'
  },
  {
    id: 'txcd_37010000',
    type: 'physical',
    description: 'Personal computers, including laptops, tablets, desktops.',
    name: 'Personal Computers'
  },
  {
    id: 'txcd_37010003',
    type: 'physical',
    description: 'Removable storage media such as compact disks, flash drives, thumb drives, flash memory cards.',
    name: 'Computer Storage Media'
  },
  {
    id: 'txcd_37010036',
    type: 'physical',
    description: 'Ink cartridges for printers.',
    name: 'Printer Ink'
  },
  {
    id: 'txcd_37030002',
    type: 'physical',
    description: 'A globe that could be used by a student in a course of study as a reference and to learn the subject being taught.',
    name: 'Globes - Student'
  },
  {
    id: 'txcd_37030003',
    type: 'physical',
    description: 'A map that could be used by a student in a course of study as a reference and to learn the subject being taught.',
    name: 'Maps - Student'
  },
  {
    id: 'txcd_37040001',
    type: 'physical',
    description: 'Paper suitable for printers.',
    name: 'Printer Paper'
  },
  {
    id: 'txcd_37050001',
    type: 'physical',
    description: 'Books that are required or approved for use in conjunction with a curriculum provided by an educational institution.',
    name: 'School Textbooks'
  },
  {
    id: 'txcd_37060001',
    type: 'physical',
    description: 'Bags designed to carry students\' books during the school day. This category does not include backpacks for traveling, hiking, camping, etc.',
    name: 'Bookbags/Backpacks - Student'
  },
  {
    id: 'txcd_37060002',
    type: 'physical',
    description: 'Typically a small electronic device with a keyboard and visual display used for making used for making mathematical calculations.',
    name: 'Calculators'
  },
  {
    id: 'txcd_37060003',
    type: 'physical',
    description: 'A calendar based notebook to aid in outlining one\'s daily appointments, classes, activities, etc.',
    name: 'Daily Planners'
  },
  {
    id: 'txcd_37060008',
    type: 'physical',
    description: 'Portable locks used by students in a school setting to prevent use, theft, vandalism or harm.',
    name: 'Padlocks - Student'
  },
  {
    id: 'txcd_37060009',
    type: 'physical',
    description: 'An item commonly used by a student in a course of study for artwork.  This category is limited to the following items...clay and glazes, paints, paintbrushes for artwork, sketch and drawing pads, watercolors.',
    name: 'School Art Supplies'
  },
  {
    id: 'txcd_37060010',
    type: 'physical',
    description: 'Reference printed material commonly used by a student in a course of study as a reference and to learn the subject being taught.',
    name: 'Dictionaries/Thesauruses'
  },
  {
    id: 'txcd_37060011',
    type: 'physical',
    description: 'Sheet music used by a student in conjunciton with school activities.',
    name: 'Sheet music - Student'
  },
  {
    id: 'txcd_37060012',
    type: 'physical',
    description: 'An item commonly used by a student in a course of study. This category is limited to the following items...binders, blackboard chalk, cellophane tape, compasses, composition books, crayons, erasers, folders, glue/paste/glue sticks, highlighters, index cards, index card boxes, legal pads, lunch boxes, markers, notebooks, paper (copy, graph, tracing, manila, colored, construction, notebook), pencils, pencil boxes, pencil sharpeners, pens, posterboard, protractors, rulers, scissors, writing tablets.',
    name: 'School Supplies'
  },
  {
    id: 'txcd_37060013',
    type: 'physical',
    description: 'A device that joins pages of paper or similar material by fastening a thin metal staple through the sheets and folding the ends underneath.',
    name: 'Staplers/Staples'
  },
  {
    id: 'txcd_37060035',
    type: 'physical',
    description: 'Pins/tacks to secure papers, pictures, calendars, etc. to bulletin boards, walls, etc.',
    name: 'Push pins/tacks'
  },
  {
    id: 'txcd_37060045',
    type: 'physical',
    description: 'A handheld computer that is capable of plotting graphs, solving simultaneous equations, and performing other tasks with variables.',
    name: 'Graphing Calculators'
  },
  {
    id: 'txcd_37060046',
    type: 'physical',
    description: 'A musical instrument used by a student in conjunciton with school activities.',
    name: 'Musical instruments - Student'
  },
  {
    id: 'txcd_37070001',
    type: 'physical',
    description: 'Prewritten ("canned") software delivered on tangible media.',
    name: 'Canned Software - Tangible Medium'
  },
  {
    id: 'txcd_37070002',
    type: 'physical',
    description: 'Prewritten ("canned") software on tangible media used for non-recreational purposes, such as antivirus, database, educational, financial, word processing, etc. The software is intended for personal use, rather than for consumption in a commercial enterprise. Note: The distinction between business use and personal use for this tax code is relevant only if you are transacting business in the US.',
    name: 'Canned Software - Tangible Medium - Non-recreational'
  },
  {
    id: 'txcd_37071001',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in electronic form.',
    name: 'Software Maintenance Agreement - Optional, Prewritten, Electronic Delivery, Updates Only'
  },
  {
    id: 'txcd_37071002',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in electronic form, as well as non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Optional, Prewritten, Electronic Delivery, Updates and Services'
  },
  {
    id: 'txcd_37071003',
    type: 'services',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to only support sevices, such as consulation or phone support.  The agreements does not include periodic canned software updates, upgrades, or error corrections in any form.',
    name: 'Software Maintenance Agreement - Optional, Prewritten, Services Only'
  },
  {
    id: 'txcd_37071006',
    type: 'physical',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in tangible form.',
    name: 'Software Maintenance Agreement - Optional, Prewritten, Tangible Delivery, Updates Only'
  },
  {
    id: 'txcd_37071007',
    type: 'physical',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in tangible form, as well as non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Optional, Prewritten, Tangible Delivery, Updates and Services'
  },
  {
    id: 'txcd_37071009',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain, from the original seller of the software, periodic custom software updates, upgrades, and error corrections in electronic form.',
    name: 'Software Maintenance Agreement - Optional, Custom, Electronic Delivery, Updates Only'
  },
  {
    id: 'txcd_37071010',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain, from the original seller of the software, periodic custom software updates, upgrades, and error corrections in electronic form, as well as non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Optional, Custom, Electronic Delivery, Updates and Services'
  },
  {
    id: 'txcd_37071011',
    type: 'services',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to only support sevices, such as consulation or phone support.  The agreements does not include periodic custom software updates, upgrades, or error corrections in any form.',
    name: 'Software Maintenance Agreement - Optional, Custom, Services Only'
  },
  {
    id: 'txcd_37071014',
    type: 'services',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain, from the original seller of the software, periodic custom software updates, upgrades, and error corrections in tangible form.',
    name: 'Software Maintenance Agreement - Optional, Custom, Tangible Delivery, Updates Only'
  },
  {
    id: 'txcd_37071015',
    type: 'services',
    description: 'A charge, apart from the charge for the software, for an agreement that is not required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain, from the original seller of the software, periodic custom software updates, upgrades, and error corrections in tangible form, as well as non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Optional, Custom, Tangible Delivery, Updates and Services'
  },
  {
    id: 'txcd_37071101',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in electronic form.  The agreement may or may not also include non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Mandatory, Prewritten, Electronic Delivery'
  },
  {
    id: 'txcd_37071103',
    type: 'physical',
    description: 'A charge, apart from the charge for the software, for an agreement that is required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic canned software updates, upgrades, and error corrections in tangible form.  The agreement may or may not also include non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Mandatory, Prewritten, Tangible Delivery'
  },
  {
    id: 'txcd_37071104',
    type: 'digital',
    description: 'A charge, apart from the charge for the software, for an agreement that is required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic custom software updates, upgrades, and error corrections in electronic form.  The agreement may or may not also include non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Mandatory, Custom, Electronic Delivery'
  },
  {
    id: 'txcd_37071106',
    type: 'services',
    description: 'A charge, apart from the charge for the software, for an agreement that is required to be purchased in order to obtain the software.  The agreement entitles the software user to obtain periodic custom software updates, upgrades, and error corrections in tangible form.  The agreement may or may not also include non-separately stated support sevices, such as consulation or phone support.',
    name: 'Software Maintenance Agreement - Mandatory, Custom, Tangible Delivery'
  },
  {
    id: 'txcd_40010000',
    type: 'physical',
    description: 'Grain, as an important staple food, is the harvested seed of grasses such as wheat, oats, rice, corn, orghum, millet, rye, and barley.',
    name: 'Cereal Grains'
  },
  {
    id: 'txcd_40010001',
    type: 'physical',
    description: 'A dough of flour, eggs, and water made in different shapes and dried or used fresh.',
    name: 'Pasta'
  },
  {
    id: 'txcd_40010002',
    type: 'physical',
    description: 'The starchy grain of ceral grass used as a staple food.',
    name: 'Rice'
  },
  {
    id: 'txcd_40020002',
    type: 'physical',
    description: 'Cooking oils such as olive oil, vegetable oil, corn oil, etc.',
    name: 'Edible Oils'
  },
  {
    id: 'txcd_40020004',
    type: 'physical',
    description: 'A sweet substance especially from the plants sugar cane and sugar beet, used to make food and drinks sweet.  This category also supports artifical sugars such as saccharine, aspartame, sucralose, etc.',
    name: 'Sugar and Sugar Substitutes'
  },
  {
    id: 'txcd_40020005',
    type: 'physical',
    description: 'Flour is a white or brown powder that is made by grinding grain, used chielfy in baking.',
    name: 'Flour'
  },
  {
    id: 'txcd_40020006',
    type: 'physical',
    description: 'A type of plant whose leaves are used in cooking to give flavor to particular dishes, such as basil, oregano, and cilantro, etc.',
    name: 'Herbs'
  },
  {
    id: 'txcd_40020007',
    type: 'physical',
    description: 'Seasonings such as salt, pepper, paprika, chili powder, etc.',
    name: 'Seasonings'
  },
  {
    id: 'txcd_40020008',
    type: 'physical',
    description: 'Any edible dye, pigment, or substance that imparts color when it is added to food or drink.',
    name: 'Food Coloring'
  },
  {
    id: 'txcd_40040000',
    type: 'physical',
    description: 'Food and beverage products sold at retail grocery-type establishments that are intended for consumption off the premises of the vendor.',
    name: 'Food for Non-Immediate Consumption'
  },
  {
    id: 'txcd_40040003',
    type: 'physical',
    description: 'A dark brown powder made from cocoa beans, used to add a chocolate flavor to beverages, as in hot chocolate.',
    name: 'Cocoa Mix'
  },
  {
    id: 'txcd_40040004',
    type: 'physical',
    description: 'Foods and formulas meant for feeding babies and infants.',
    name: 'Baby Food'
  },
  {
    id: 'txcd_40040009',
    type: 'physical',
    description: 'A dark brown powder made from cocoa beans, used to make chocolate and add a chocolate flavor to the baking process.',
    name: 'Baking Cocoa'
  },
  {
    id: 'txcd_40040010',
    type: 'physical',
    description: 'Ice for human consumption.',
    name: 'Ice Cubes'
  },
  {
    id: 'txcd_40040013',
    type: 'physical',
    description: 'The flesh (muscle tissue) of an animal consumed as food.',
    name: 'Meat and Meat Products'
  },
  {
    id: 'txcd_40040016',
    type: 'physical',
    description: 'Nuts and seeds in unroasted, unsalted, unflavored form.',
    name: 'Nuts and Seeds'
  },
  {
    id: 'txcd_40040017',
    type: 'physical',
    description: 'Nuts and seeds that have been salted, spiced, smoked, or roasted.',
    name: 'Nuts and Seeds, Processed'
  },
  {
    id: 'txcd_40040019',
    type: 'physical',
    description: 'Vegetables are parts of plants that are consumed as food.',
    name: 'Vegetables'
  },
  {
    id: 'txcd_40040020',
    type: 'physical',
    description: 'Cracker bread is a thin and crispy flat bread that is often used as an accompaniment to meals in many different cultures, most notably Turkish, Indian, and Mediterranean cuisine.',
    name: 'Cracker Bread'
  },
  {
    id: 'txcd_40040021',
    type: 'physical',
    description: 'Plain Breads include items ordinary loaves, such as sourdough, multigrain, and rye loaves.',
    name: 'Plain Breads'
  },
  {
    id: 'txcd_40040022',
    type: 'physical',
    description: 'Specialty Breads are artisan breads, including cheese bread, olive loaves, date and walnut bread, etc.',
    name: 'Speciality Breads'
  },
  {
    id: 'txcd_40050003',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising between 50% and 75% of the overall value of the bundle.',
    name: 'Food/TPP Bundle - with Food between 50% and 75%'
  },
  {
    id: 'txcd_40050004',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising between 50% and 90% of the overall value of the bundle.',
    name: 'Food/TPP Bundle with Food 50% to 90%'
  },
  {
    id: 'txcd_40050005',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising 90% or more of the overall value of the bundle.',
    name: 'Food/TPP Bundle with Food 90% or more'
  },
  {
    id: 'txcd_40050006',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising less than 50% of the overall value of the bundle.',
    name: 'Food/TPP Bundle with Food less than 50%'
  },
  {
    id: 'txcd_40050007',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising 90% or more of the overall value of the bundle, where all food consists of candy (not containing flour).',
    name: 'Food/TPP Bundle - with Food 90% or more - Food is all Candy'
  },
  {
    id: 'txcd_40050008',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising between 50% and 75% of the overall value of the bundle, where all food consists of candy (not containing flour).',
    name: 'Food/TPP Bundle - with Food between 50% and 75% - Food is all Candy'
  },
  {
    id: 'txcd_40050009',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising between 76% and 89% of the overall value of the bundle.',
    name: 'Food/TPP Bundle - with Food between 76% and 89%'
  },
  {
    id: 'txcd_40050010',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with tangible personal property, with the food comprising between 76% and 89% of the overall value of the bundle, where all food consists of candy (not containing flour).',
    name: 'Food/TPP Bundle - with Food between 76% and 89% - Food is all Candy'
  },
  {
    id: 'txcd_40051001',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with candy, with the candy comprising 10% or less of the overall value of the bundle (food comprises 90% or more).  Note that any candy containing flour should be considered as food (and not candy) when determining bundle percentages.',
    name: 'Food/Candy Bundle - with Candy 10% or less'
  },
  {
    id: 'txcd_40051002',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with candy, with the candy comprising between 11% and 24% of the overall value of the bundle (food comprises 76% to 89%).  Note that any candy containing flour should be considered as food (and not candy) when determining bundle percentages.',
    name: 'Food/Candy Bundle - with Candy 11% to 24%'
  },
  {
    id: 'txcd_40051003',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with candy, with the candy comprising between 25% and 49% of the overall value of the bundle (food comprises 51 to 75%).  Note that any candy containing flour should be considered as food (and not candy) when determining bundle percentages.',
    name: 'Food/Candy Bundle - with Candy 25% to 49%'
  },
  {
    id: 'txcd_40051004',
    type: 'physical',
    description: 'Food bundle or basket containing food staples combined with candy, with the candy comprising 50% or more of the overall value of the bundle (food comprises 50% or less). Note that any candy containing flour should be considered as food (and not candy) when determining bundle percentages.',
    name: 'Food/Candy Bundle - with Candy 50% or more'
  },
  {
    id: 'txcd_40060003',
    type: 'physical',
    description: 'Food and beverage products in a form suited for consumption on the premises of the vendor, described as prepared foods, ready-to-eat foods, or meals.  Always utilize this PTC for heated foods, heated beverages, dispensed beverages, and foods sold with eating utensils.',
    name: 'Food for Immediate Consumption'
  },
  {
    id: 'txcd_40070001',
    type: 'physical',
    description: 'Granola Bars, Cereal Bars, Energy Bars, Protein Bars containing flour.',
    name: 'Granola Bars, Cereal Bars, Energy Bars, Protein Bars Containing Flour'
  },
  {
    id: 'txcd_40070002',
    type: 'physical',
    description: 'Granola Bars, Cereal Bars, Energy Bars, Protein Bars containing no flour.',
    name: 'Granola Bars, Cereal Bars, Energy Bars, Protein Bars Containing No Flour'
  },
  {
    id: 'txcd_40070005',
    type: 'physical',
    description: 'Snack foods including chips, crisps, puffs, curls or sticks, popped corn, brittle pretzels, salted nuts and salted seeds, granola products and snack mixes. This category does not include items such as crackers, cookies, snack cakes or breakfast cereal.',
    name: 'Snack Foods'
  },
  {
    id: 'txcd_40070006',
    type: 'physical',
    description: 'A biscuit is a typically hard, small baked product that would be called a "cookie" In the US.',
    name: 'Biscuits/Cookies - Plain'
  },
  {
    id: 'txcd_40070007',
    type: 'physical',
    description: 'A biscuit is a typically hard, small baked product that would be called a "cookie" In the US.  This cookie is covered or coated in chocolate.',
    name: 'Biscuits/Cookies - Chocolate Coated'
  },
  {
    id: 'txcd_40090001',
    type: 'physical',
    description: 'Tablet, capsule, powder, softgel, gelcap, or liquid intended that contain a vitamin, mineral, herb, other botanical, or amino acid and is labeled as a dietary supplement identifiable by the "supplement facts" box found on the label.',
    name: 'Dietary Supplements'
  },
  {
    id: 'txcd_40090002',
    type: 'physical',
    description: 'Nutritional supplement in powder form, dairy based or plant based, focused on increasing ones intake of protein for various benefits.',
    name: 'Protein Powder'
  },
  {
    id: 'txcd_40090005',
    type: 'physical',
    description: 'A food which intended to be or is represented for special dietary use solely as a food for infants by reason of its simulation of human milk or its suitability as a complete or partial substitute for human milk.',
    name: 'Infant Formula'
  },
  {
    id: 'txcd_40090008',
    type: 'physical',
    description: 'Products in various forms that are intended for ingestion and represented as a conventional food and labeled as a nutritional supplement and identifiable by the "Nutrition Facts" box found on the label.',
    name: 'Nutritional Supplements'
  },
  {
    id: 'txcd_40100001',
    type: 'physical',
    description: 'A preparation of natural or artificial sweeteners in combination with chocolate, fruits, nuts, or other ingredients or flavorings in the form of bars, drops, or pieces.',
    name: 'Candy'
  },
  {
    id: 'txcd_40100002',
    type: 'physical',
    description: 'A preparation of natural or artificial sweeteners in combination with chocolate, fruits, nuts, or other ingredients or flavorings in the form of bars, drops, or pieces and that contains flour as an ingredient.',
    name: 'Candy (Contains Flour)'
  },
  {
    id: 'txcd_40100003',
    type: 'physical',
    description: 'Ice cream, frozen yogurt, or similar, packaged in a container that is one pint or larger. The default behavior of this category assumes off-premises consumption.',
    name: 'Ice Cream, Packaged - One Pint or Greater'
  },
  {
    id: 'txcd_40100004',
    type: 'physical',
    description: 'Ice cream, frozen yogurt, or similar, packaged in a container that is less than one pint. The default behavior of this category assumes off-premises consumption.',
    name: 'Ice Cream, Packaged - Less Than One Pint Container'
  },
  {
    id: 'txcd_40100010',
    type: 'physical',
    description: 'A soft, cohesive substance designed to be chewed without being swallowed.',
    name: 'Chewing Gum'
  },
  {
    id: 'txcd_40100011',
    type: 'physical',
    description: 'A mint -flavored candy intended to freshen the breath.',
    name: 'Breath Mints'
  },
  {
    id: 'txcd_40400005',
    type: 'physical',
    description: 'Dried fruit is fruit from which the majority of the original water content has been removed either naturally, through sun drying, or through the use of specialized dryers or dehydrators.  Common dried fruits are dried fruits sold are raisins, dates, prunes, and figs.',
    name: 'Dried Fruit - Unsweetened'
  },
  {
    id: 'txcd_41020001',
    type: 'physical',
    description: 'Beer is an alcoholic drink that is flavored with hops and consists of yeast-fermented malt. Malt beverage is a type of alcoholic drink in which the grain, or seed, of the barley plant is the main ingredient.  This code represents packaged beverages for non-immediate consumption.',
    name: 'Alcoholic Beverages - Beer/Malt Beverage'
  },
  {
    id: 'txcd_41020002',
    type: 'physical',
    description: 'Alcoholic beverages created in a distillation process, and are known by the common names of vodka, gin, whiskey, rum, tequila, brandy, etc.  This code represents packaged beverages for non-immediate consumption.',
    name: 'Alcoholic Beverages - Spirits'
  },
  {
    id: 'txcd_41020003',
    type: 'physical',
    description: 'An alcoholic beverage created through fermentation of grapes of various varieties.  This code represents packaged beverages for non-immediate consumption.',
    name: 'Alcoholic Beverages - Wine'
  },
  {
    id: 'txcd_41030001',
    type: 'physical',
    description: 'Regular, unsweetened, non carbonated water sold in containers.  Includes waters containing only natural flavors or essences.  Does not include distilled water.',
    name: 'Bottled Water'
  },
  {
    id: 'txcd_41030002',
    type: 'physical',
    description: 'Bottled Water for human consumption, unsweetened, carbonated artifically.  Includes carbonated waters containing only natural flavors or essences.',
    name: 'Bottled Water - Carbonated Artificially'
  },
  {
    id: 'txcd_41030003',
    type: 'physical',
    description: 'Bottled Water for human consumption, unsweetened, carbonated naturally. Includes carbonated waters containing only natural flavors or essences.',
    name: 'Bottled Water - Carbonated Naturally'
  },
  {
    id: 'txcd_41040002',
    type: 'physical',
    description: 'Carbonated non-alcoholic beverages that contain natural or artificial sweeteners, and zero natural fruit or vegetable juice, not including carbonated water. Includes energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Carbonated Soft Drinks - 0% Fruit or Vegetable juice'
  },
  {
    id: 'txcd_41040003',
    type: 'physical',
    description: 'Ready to drink non-carbonated beverage containing tea with natural or artificial sweeteners.',
    name: 'Bottled Tea - Non-Carbonated - Sweetened'
  },
  {
    id: 'txcd_41040004',
    type: 'physical',
    description: 'Bottled water for human consumption, containing natural or artificial sweeteners, non-carbonated.  Waters containing zero calorie natural flavoring should be associated with the \'Bottled Water\' PTC.',
    name: 'Bottled water - Sweetened'
  },
  {
    id: 'txcd_41040005',
    type: 'physical',
    description: 'Bottled water for human consumption, containing natural or artificial sweeteners, carbonated.  Carbonated waters containing zero calorie natural flavoring should be associated with either the the \'Bottled Water - Carbonated Artificially\' PTC or the \'Bottled Water - Carbonated Naturally\' PTC.',
    name: 'Bottled water - Carbonated - Sweetened'
  },
  {
    id: 'txcd_41040008',
    type: 'physical',
    description: 'Non-carbonated, non-alcoholic beverages that contain sweeteners, and zero natural fruit or vegetable juice, such as sweetened cocktail mixes that can be combined with alcohol, but doesn\'t include flavored water.',
    name: 'Non-Carbonated Soft Drinks - 0% Fruit or Vegetable juice'
  },
  {
    id: 'txcd_41040009',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 1 - 9% natural vegetable juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 1 -9% Vegetable juice'
  },
  {
    id: 'txcd_41040010',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 1 - 9% natural fruit juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 1-9% Fruit juice'
  },
  {
    id: 'txcd_41040011',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 10 - 24% natural fruit juice.  This does not include flavored carbonated water.  This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 10-24% Fruit juice'
  },
  {
    id: 'txcd_41040012',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 10 - 24% natural vegetable juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 10-24% Vegetable juice'
  },
  {
    id: 'txcd_41040013',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 100% natural fruit or vegetable juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 100% Fruit or Vegetable juice'
  },
  {
    id: 'txcd_41040014',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 25 - 50% natural fruit juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 25-50% Fruit juice'
  },
  {
    id: 'txcd_41040015',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 25 - 50% natural vegetable juice. This does not flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 25-50% Vegetable juice'
  },
  {
    id: 'txcd_41040016',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 51 - 69% natural fruit juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 51-69% Fruit juice'
  },
  {
    id: 'txcd_41040017',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 51 - 69% natural vegetable juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 51-69% Vegetable juice'
  },
  {
    id: 'txcd_41040018',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 70 - 99% natural fruit juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 70-99% Fruit juice'
  },
  {
    id: 'txcd_41040019',
    type: 'physical',
    description: 'Carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 70 - 99% natural vegetable juice. This does not include flavored carbonated water. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Carbonated - 70-99% Vegetable juice'
  },
  {
    id: 'txcd_41040020',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 1 - 9% natural vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 1 -9% Vegetable juice'
  },
  {
    id: 'txcd_41040021',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 1 - 9% natural fruit juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 1-9% Fruit juice'
  },
  {
    id: 'txcd_41040022',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 10 - 24% natural fruit juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 10-24% Fruit juice'
  },
  {
    id: 'txcd_41040023',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 10 - 24% natural vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 10-24% Vegetable juice'
  },
  {
    id: 'txcd_41040024',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 100% natural fruit or vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 100% Fruit or Vegetable juice'
  },
  {
    id: 'txcd_41040025',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 25 - 50% natural fruit juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 25-50% Fruit juice'
  },
  {
    id: 'txcd_41040026',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 25 - 50% natural vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 25-50% Vegetable juice'
  },
  {
    id: 'txcd_41040027',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 51 - 69% natural fruit juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 51-69% Fruit juice'
  },
  {
    id: 'txcd_41040028',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 51 - 69% natural vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 51-69% Vegetable juice'
  },
  {
    id: 'txcd_41040029',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 70 - 99% natural fruit juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 70-99% Fruit juice'
  },
  {
    id: 'txcd_41040030',
    type: 'physical',
    description: 'Non-carbonated nonalcoholic beverages that contain natural or artificial sweeteners, and 70 - 99% natural vegetable juice. This does not include flavored water. This does include sweetened cocktail mixes that can be combined with alcohol. This does include beverages marketed as energy drinks that carry a Nutrition Facts label and contain a blend of energy enhancing ingredients.',
    name: 'Soft Drinks - Non-Carbonated - 70-99% Vegetable juice'
  },
  {
    id: 'txcd_41050001',
    type: 'physical',
    description: 'Ready to drink coffee based beverage containing milk or milk substitute.',
    name: 'Bottled coffee - Containing Milk or Milk Substitute'
  },
  {
    id: 'txcd_41050002',
    type: 'physical',
    description: 'Ready to drink coffee based beverage not containing milk, containing natural or artificial sweetener.',
    name: 'Bottled Coffee - No Milk - Sweetened'
  },
  {
    id: 'txcd_41050003',
    type: 'physical',
    description: 'Ready to drink coffee based beverage containing neither milk nor natural or artificial sweeteners.',
    name: 'Bottled Coffee - No Milk - Unsweetened'
  },
  {
    id: 'txcd_41050004',
    type: 'physical',
    description: 'Ready to drink carbonated beverage containing tea and without any natural or artificial sweeteners.',
    name: 'Bottled Tea - Carbonated - Unsweetened'
  },
  {
    id: 'txcd_41050005',
    type: 'physical',
    description: 'Ready to drink non-carbonated beverage containing tea without natural or artificial sweeteners.',
    name: 'Bottled Tea - Non-Carbonated - Unsweetened'
  },
  {
    id: 'txcd_41050006',
    type: 'physical',
    description: 'Coffee beans or ground coffee.',
    name: 'Coffee Beans or Ground Coffee'
  },
  {
    id: 'txcd_41050007',
    type: 'physical',
    description: 'Ready to drink carbonated beverage containing tea with natural or artificial sweeteners.',
    name: 'Bottled Tea - Carbonated - Sweetened'
  },
  {
    id: 'txcd_41050008',
    type: 'physical',
    description: 'Tea leaves and tea bags.',
    name: 'Tea Leaves or Tea Bags'
  },
  {
    id: 'txcd_41051001',
    type: 'physical',
    description: 'Carbonated beverages marketed as energy drinks, carrying a Supplement Facts Label, that contain a blend of energy enhancing vitamins, minerals, herbals, stimulants, etc.',
    name: 'Energy Beverages - Carbonated - with Supplement Facts Label'
  },
  {
    id: 'txcd_41051002',
    type: 'physical',
    description: 'Non-carbonated beverages marketed as energy drinks, carrying a Supplement Facts Label, that contain a blend of energy enhancing vitamins, minerals, herbals, stimulants, etc.',
    name: 'Energy Beverages - Non-Carbonated - with Supplement Facts Label'
  },
  {
    id: 'txcd_41052001',
    type: 'physical',
    description: 'Non-alcoholic beer is beer with little or no alcohol content. In the United States, beverages containing less than 0.5% alcohol by volume (ABV) fall under this term.',
    name: 'Food and Beverage - Non-Alcoholic Beer/ Wine'
  },
  {
    id: 'txcd_41053001',
    type: 'physical',
    description: 'Ready to drink beverages, containing milk, formulated and labeled for their nutritional value, such as increased caloric or protein intake.',
    name: 'Nutritional Supplement, Protein Drinks, Shakes - Contains Milk'
  },
  {
    id: 'txcd_41053002',
    type: 'physical',
    description: 'Ready to drink beverages, not containing milk, formulated and labeled for their nutritional value, such as increased caloric or protein intake and containing natural or artificial sweeteners.',
    name: 'Nutritional Supplement, Protein Drink, Shakes - Contains No Milk'
  },
  {
    id: 'txcd_41054001',
    type: 'physical',
    description: 'Powdered mixes to be reconstituted into a drinkable beverage using milk or a milk substitute.',
    name: 'Powdered Drink Mixes - To Be Mixed with Milk'
  },
  {
    id: 'txcd_41054002',
    type: 'physical',
    description: 'Powdered mixes to be reconstituted into a drinkable beverage using water.',
    name: 'Powdered Drink Mixes - To Be Mixed with Water'
  },
  {
    id: 'txcd_41060003',
    type: 'physical',
    description: 'Milk type beverages from non-animal sources, such as soy, rice, almond, coconut, peanut, and hemp.',
    name: 'Milk Substitutes'
  },
  {
    id: 'txcd_41060006',
    type: 'physical',
    description: 'Milk or milk substitutes, or drinks with bases of milk, coffee, unsweetened tea or cocoa.',
    name: 'Milk Coffee Tea Cocoa Beverages'
  },
  {
    id: 'txcd_50021001',
    type: 'services',
    description: 'Charges associated with recurring membership dues allowing access or use of health clubs and fitness clubs.  Outside of the United States, these charges are not applicable for services provided by non-profit sports organizations.',
    name: 'Fitness Centers - Dues and Membership Fees'
  },
  {
    id: 'txcd_50021002',
    type: 'services',
    description: 'Initiation fees are an upfront one-time charge occurring at the time a new member joins a health club or fitness center.  Outside of the United States, these charges are not applicable for services provided by non-profit sports organizations.',
    name: 'Fitness Centers - Initiation Fees'
  },
  {
    id: 'txcd_50021003',
    type: 'services',
    description: 'Charges associated with the service of providing in-person personal fitness training or group excercise classes.  Outside of the United States, these charges are not applicable for services provided by non-profit sports organizations.',
    name: 'Fee for Personal Training/Fitness Classes'
  },
  {
    id: 'txcd_50021101',
    type: 'services',
    description: 'Charges associated with one-time access for use of health clubs and fitness clubs.  Outside of the United States, these charges are not applicable for services provided by non-profit sports organizations.',
    name: 'Fitness Centers - Single Use Access Fees'
  },
  {
    id: 'txcd_50021103',
    type: 'services',
    description: 'Charges associated with the service of providing in-person instruction in an athletic pursuit, eg. golf, tennis, skiing, etc.  Outside of the United States, these charges are not applicable for services provided by non-profit sports organizations.',
    name: 'Fee For Sport Instruction Classes'
  },
  {
    id: 'txcd_90000001',
    type: 'physical',
    description: 'A monetary donation for a cause, in which the donee receives nothing in return.',
    name: 'Cash Donation'
  },
  {
    id: 'txcd_90020001',
    type: 'services',
    description: 'Gratuity, otherwise known as a tip, is money that a customer leaves for an employee or employees that is in addition to and separate from the amount due for the goods or services rendered.',
    name: 'Optional Gratuity'
  },
  {
    id: 'txcd_92010001',
    type: 'physical',
    description: 'A shipping charge for the delivery of physical goods in conjunction with the sale of these goods. This tax category is not appropriate for stand alone transportation charges that are not associated with the sale of the goods being delivered.',
    name: 'Shipping'
  }
];