import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent, DataService, NotificationService } from '@vendure/admin-ui/core';
import { LogoSectionData, defaultLogoSectionData } from '../ui-types';
import { safeJSONParse } from '../utils';

@Component({
  template: `
    <vdr-card>
      <vdr-form-field [label]="'Page Title'">
        <input
          type="text"
          [formControl]="pageTitleControl"
        />
      </vdr-form-field>
      <vdr-form-field [label]="'Display Header Menu'">
        <clr-toggle-wrapper>
            <input
                type="checkbox"
                clrToggle
                [formControl]="displayHeaderMenuControl"
            />
            <label>Display Header Menu</label>
        </clr-toggle-wrapper>
      </vdr-form-field>
      <vdr-form-field [label]="'Display Footer Menu'">
        <clr-toggle-wrapper>
            <input
                type="checkbox"
                clrToggle
                [formControl]="displayFooterMenuControl"
            />
            <label>Display Footer Menu</label>
        </clr-toggle-wrapper>
      </vdr-form-field>
      <vdr-form-field [label]="'Store Logo, recommended height: 40 (optional, used on mobile home-page/non-home-page and on desktop non-home-page'">
        <vdr-dynamic-form-input
          [control]="normalLogoControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
      <vdr-form-field [label]="'Store Simple Logo, recommended height: 40 (optional, used on desktop homepage when StoreNameLogo is not set)'">
        <vdr-dynamic-form-input
          [control]="smallLogoControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
      <vdr-form-field [label]="'Store Name Logo, recommended height: 40 (optional)'">
        <vdr-dynamic-form-input
          [control]="nameLogoControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
      <vdr-form-field [label]="'Store Browser Icon(*.ico), recommended resolution: 32x32 (optional)'">
        <vdr-dynamic-form-input
          [control]="browserIconControl"
          [def]="{
            type: 'relation',
            entity: 'Asset',
            ui: {
              component: 'relation-form-input',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
      <vdr-form-field [label]="'Display Store Name'">
        <clr-toggle-wrapper>
            <input
                type="checkbox"
                clrToggle
                name="enabled"
                [formControl]="displayNameControl"
            />
            <label>{{ 'common.enabled' | translate }}</label>
        </clr-toggle-wrapper>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class LogoSectionFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  logoSectionData: LogoSectionData | null;
  pageTitleControl = new FormControl();
  displayHeaderMenuControl = new FormControl();
  displayFooterMenuControl = new FormControl();
  normalLogoControl = new UntypedFormControl();
  smallLogoControl = new UntypedFormControl();
  nameLogoControl = new UntypedFormControl();
  browserIconControl = new UntypedFormControl();
  displayNameControl = new FormControl();

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.logoSectionData = safeJSONParse<LogoSectionData>(
      this.formControl.value,
      this.notificationService,
      defaultLogoSectionData,
    );
    this.initializeFormControls();
    if (this.logoSectionData?.normalLogoId) {
      this.dataService.product
        .getAsset(this.logoSectionData?.normalLogoId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.normalLogoControl.setValue(asset);
        });
    }
    if (this.logoSectionData?.smallLogoId) {
      this.dataService.product
        .getAsset(this.logoSectionData.smallLogoId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.smallLogoControl.setValue(asset);
        });
    }
    if (this.logoSectionData?.nameLogoId) {
      this.dataService.product
        .getAsset(this.logoSectionData!.nameLogoId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.nameLogoControl.setValue(asset);
        });
    }
    if (this.logoSectionData?.browserIconId) {
      this.dataService.product
        .getAsset(this.logoSectionData!.browserIconId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.browserIconControl.setValue(asset);
        });
    }
  }

  ngAfterViewInit() {
    this.pageTitleControl.valueChanges.subscribe(this.onPageTitleChange);
    this.displayHeaderMenuControl.valueChanges.subscribe(this.onDisplayHeaderMenuChange);
    this.displayFooterMenuControl.valueChanges.subscribe(this.onDisplayFooterMenuChange);
    this.normalLogoControl.valueChanges.subscribe(this.onNormalLogoChange);
    this.smallLogoControl.valueChanges.subscribe(this.onSmallLogoChange);
    this.nameLogoControl.valueChanges.subscribe(this.onNameLogoChange);
    this.browserIconControl.valueChanges.subscribe(this.onBrowserIconChange);
    this.displayNameControl.valueChanges.subscribe(this.onDisplayNameChange);
  }

  private initializeFormControls() {
    this.pageTitleControl.setValue(this.logoSectionData?.pageTitle);
    this.displayHeaderMenuControl.setValue(this.logoSectionData?.displayHeaderMenu);
    this.displayFooterMenuControl.setValue(this.logoSectionData?.displayFooterMenu);
    this.displayNameControl.setValue(this.logoSectionData?.displayName);
  }

  private onPageTitleChange = (value: string) => {
    if (this.logoSectionData?.pageTitle !== value) {
      this.logoSectionData!.pageTitle = value;
      this.updateJsonString();
    }
  };

  private onNormalLogoChange = (value: any) => {
    const normalLogoId = value ? value.id : null;
    if (this.logoSectionData?.normalLogoId !== normalLogoId) {
      this.logoSectionData!.normalLogoId = normalLogoId;
      this.updateJsonString();
    }
  };

  private onSmallLogoChange = (value: any) => {
    const smallLogoId = value ? value.id : null;
    if (this.logoSectionData?.smallLogoId !== smallLogoId) {
      this.logoSectionData!.smallLogoId = smallLogoId;
      this.updateJsonString();
    }
  };

  private onNameLogoChange = (value: any) => {
    const nameLogoId = value ? value.id : null;
    if (this.logoSectionData?.nameLogoId !== nameLogoId) {
      this.logoSectionData!.nameLogoId = nameLogoId;
      this.updateJsonString();
    }
  };

  private onBrowserIconChange = (value: any) => {
    const browserIconId = value ? value.id : null;
    if (this.logoSectionData?.browserIconId !== browserIconId) {
      this.logoSectionData!.browserIconId = browserIconId;
      this.updateJsonString();
    }
  };

  private onDisplayNameChange = (value: boolean) => {
    if (this.logoSectionData?.displayName !== value) {
      this.logoSectionData!.displayName = value;
      this.updateJsonString();
    }
  };

  private onDisplayHeaderMenuChange = (value: boolean) => {
    if (this.logoSectionData?.displayHeaderMenu !== value) {
      this.logoSectionData!.displayHeaderMenu = value;
      this.updateJsonString();
    }
  };

  private onDisplayFooterMenuChange = (value: boolean) => {
    if (this.logoSectionData?.displayFooterMenu !== value) {
      this.logoSectionData!.displayFooterMenu = value;
      this.updateJsonString();
    }
  };

  private updateJsonString() {
    this.formControl.setValue(JSON.stringify(this.logoSectionData));
    this.formControl.markAsDirty();
  }
}
