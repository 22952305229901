import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import {
    SharedModule,
    StringCustomFieldConfig,
    FormInputComponent,
    DataService,
    NotificationService
} from '@vendure/admin-ui/core';

import { Product, Collection, ProductVariant } from '../generated-types';
import {
    BannerButtonData,
    ButtonActionType,
    ButtonNavigationType,
    defaultBannerButtonData
} from '../ui-types';
import { safeJSONParse } from '../utils';

@Component({
    selector: 'banner-button-form',
    template: `
    <vdr-card>
        <div class="form-row">
            <vdr-form-field [label]="'Enabled'" class="form-item">
                <clr-toggle-wrapper>
                    <input
                        type="checkbox"
                        clrToggle
                        name="enabled"
                        [formControl]="enabledControl"
                    />
                    <label>{{ 'common.enabled' | translate }}</label>
                </clr-toggle-wrapper>
            </vdr-form-field>

            <div *ngIf="enabledControl.value" class="form-item">
                <vdr-form-field [label]="'Button Text'">
                    <input type="text" [formControl]="textControl">
                </vdr-form-field>
            </div>
        </div>

        <div class="form-row">
            <div *ngIf="enabledControl.value" class="form-item">
                <vdr-form-field [label]="'Button Text Color'">
                    <input type="color" [formControl]="textColorControl">
                </vdr-form-field>
            </div>

            <div *ngIf="enabledControl.value" class="form-item">
                <vdr-form-field [label]="'Background Color'">
                    <input type="color" [formControl]="backgroundColorControl">
                </vdr-form-field>
            </div>
        </div>

        <div class="form-row">
            <div *ngIf="enabledControl.value" class="form-item">
                <vdr-form-field [label]="'Type'">
                    <select [formControl]="actionTypeControl">
                        <option value="navigate-to">Navigate To</option>
                        <option value="add-variant-to-cart">Add Variant to Shopping Cart</option>
                    </select>
                </vdr-form-field>
            </div>

            <div *ngIf="enabledControl.value && actionTypeControl.value === 'add-variant-to-cart'" class="form-item">
                <vdr-form-field [label]="'Select a variant to add to shopping cart'">
                    <vdr-dynamic-form-input
                        [control]="selectedVariantControl"
                        [def]="{
                            type: 'relation',
                            entity: 'ProductVariant',
                            ui: {
                                component: 'relation-form-input'
                            }
                        }"
                    >
                    </vdr-dynamic-form-input>
                </vdr-form-field>
            </div>

            <div *ngIf="enabledControl.value && actionTypeControl.value === 'navigate-to'" class="form-item">
                <vdr-form-field [label]="'Navigation To Type'">
                    <select [formControl]="navigateTypeControl">
                        <option value="navigate-to-product">Product</option>
                        <option value="navigate-to-collection">Collection</option>
                        <option value="navigate-to-page">Page</option>
                        <option value="navigate-to-website">Website</option>
                    </select>
                </vdr-form-field>
            </div>

            <div *ngIf="
                    enabledControl.value && actionTypeControl.value === 'navigate-to' &&
                    navigateTypeControl.value === 'navigate-to-product'
                "class="form-item">
                <vdr-form-field [label]="'To Product'">
                    <vdr-dynamic-form-input
                        [control]="targetProductControl"
                        [def]="{
                            type: 'relation',
                            entity: 'Product',
                            ui: {
                                component: 'relation-form-input'
                            }
                        }"
                    >
                    </vdr-dynamic-form-input>
                </vdr-form-field>
            </div>

            <div *ngIf="enabledControl.value && actionTypeControl.value === 'navigate-to' &&
                    navigateTypeControl.value === 'navigate-to-collection'"
                class="form-item">
                <vdr-form-field [label]="'To Collection'">
                    <vdr-dynamic-form-input
                        [control]="targetCollectionControl"
                        [def]="{
                            type: 'relation',
                            entity: 'Collection',
                            ui: {
                                component: 'relation-collection-selector'
                            }
                        }"
                    >
                    </vdr-dynamic-form-input>
                </vdr-form-field>
            </div>

            <div *ngIf="
                    enabledControl.value && actionTypeControl.value === 'navigate-to' &&
                    (
                        navigateTypeControl.value === 'navigate-to-page' ||
                        navigateTypeControl.value === 'navigate-to-website'
                    )
                "class="form-item">
                <vdr-form-field [label]="'To Page or Website'">
                    <input type="text" [formControl]="targetPageControl">
                </vdr-form-field>
            </div>
        </div>

        <div *ngIf="
                enabledControl.value &&
                actionTypeControl.value === 'navigate-to'
            "class="form-row">
            <div class="form-item">
                <vdr-form-field [label]="'Page Section ID'">
                    <input type="text" [formControl]="targetSectionControl">
                </vdr-form-field>
            </div>
        </div>
    </vdr-card>
    `,
    styleUrls: ['../styles.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class BannerButtonInputComponent
    implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
    readonly: boolean;
    config: StringCustomFieldConfig;
    formControl: FormControl;

    enabledControl = new FormControl();
    textControl = new FormControl();
    textColorControl = new FormControl();
    backgroundColorControl = new FormControl();
    actionTypeControl = new FormControl();
    selectedVariantControl = new UntypedFormControl();
    navigateTypeControl = new FormControl();
    targetProductControl = new UntypedFormControl();
    targetCollectionControl = new UntypedFormControl();
    targetPageControl = new FormControl();
    targetSectionControl = new FormControl();

    bannerButtonData: BannerButtonData | null;

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService
    ) {}

    ngOnInit() {
        this.bannerButtonData = safeJSONParse<BannerButtonData>(
            this.formControl.value,
            this.notificationService,
            defaultBannerButtonData
        );
        this.initializeFormControls();

        // Load entities based on IDs
        if (
            this.bannerButtonData?.actionType === ButtonActionType.AddVariantToCart &&
            this.bannerButtonData?.selectedVariantId
        ) {
            this.dataService.product
                .getProductVariant(this.bannerButtonData.selectedVariantId.toString())
                .mapSingle(data => data.productVariant)
                .subscribe(variant => {
                    this.selectedVariantControl.setValue(variant);
                });
        }

        if (
            this.bannerButtonData?.actionType === ButtonActionType.NavigateTo &&
            this.bannerButtonData?.navigateType === ButtonNavigationType.NavigateToProduct &&
            this.bannerButtonData?.targetProductId
        ) {
            this.dataService.product
                .getProduct(this.bannerButtonData.targetProductId.toString())
                .mapSingle(data => data.product)
                .subscribe(product => {
                    this.targetProductControl.setValue(product);
                });
        }

        if (
            this.bannerButtonData?.actionType === ButtonActionType.NavigateTo &&
            this.bannerButtonData?.navigateType === ButtonNavigationType.NavigateToCollection &&
            this.bannerButtonData?.targetCollectionId
        ) {
            this.dataService.collection.getCollections({
                filter: {
                    id: { eq: this.bannerButtonData.targetCollectionId.toString() },
                },
            })
            .mapSingle(data => data.collections?.items[0])
            .subscribe(collection => {
                this.targetCollectionControl.setValue(collection);
            });
        }
    }

    ngAfterViewInit() {
        this.enabledControl.valueChanges.subscribe(this.onEnabledChange);
        this.textControl.valueChanges.subscribe(this.onTextChange);
        this.textColorControl.valueChanges.subscribe(this.onTextColorChange);
        this.backgroundColorControl.valueChanges.subscribe(this.onBackgroundColorChange);
        this.actionTypeControl.valueChanges.subscribe(this.onActionTypeChange);
        this.selectedVariantControl.valueChanges.subscribe(this.onSelectedVariantChange);
        this.navigateTypeControl.valueChanges.subscribe(this.onNavigateTypeChange);
        this.targetProductControl.valueChanges.subscribe(this.onTargetProductChange);
        this.targetCollectionControl.valueChanges.subscribe(this.onTargetCollectionChange);
        this.targetPageControl.valueChanges.subscribe(this.onTargetPage);
        this.targetSectionControl.valueChanges.subscribe(this.onTargetSectionChange);
    }

    private initializeFormControls() {
        this.enabledControl.setValue(this.bannerButtonData?.enabled);
        this.textControl.setValue(this.bannerButtonData?.text);
        this.textColorControl.setValue(this.bannerButtonData?.textColor);
        this.backgroundColorControl.setValue(this.bannerButtonData?.backgroundColor);
        this.actionTypeControl.setValue(this.bannerButtonData?.actionType);
        this.navigateTypeControl.setValue(this.bannerButtonData?.navigateType);
        this.targetPageControl.setValue(this.bannerButtonData?.targetPage);
        this.targetSectionControl.setValue(this.bannerButtonData?.targetPageSectionId ?? '');
    }

    private onEnabledChange = (value: boolean) => {
        if (this.bannerButtonData?.enabled !== value) {
            this.bannerButtonData!.enabled = value;
            if (value === false) {
                this.bannerButtonData = defaultBannerButtonData;
            }
            this.updateJsonString();
        }
    };

    private onTextChange = (value: string) => {
        if (this.bannerButtonData?.text !== value) {
            this.bannerButtonData!.text = value;
            this.updateJsonString();
        }
    };

    private onTextColorChange = (value: string) => {
        if (this.bannerButtonData?.textColor !== value) {
            this.bannerButtonData!.textColor = value;
            this.updateJsonString();
        }
    };

    private onBackgroundColorChange = (value: string) => {
        if (this.bannerButtonData?.backgroundColor !== value) {
            this.bannerButtonData!.backgroundColor = value;
            this.updateJsonString();
        }
    };

    private onActionTypeChange = (value: ButtonActionType) => {
        if (this.bannerButtonData?.actionType !== value) {
            this.bannerButtonData!.actionType = value;
            if (value === ButtonActionType.AddVariantToCart) {
                this.bannerButtonData!.targetCollectionId = null;
                this.bannerButtonData!.targetProductId = null;
                this.bannerButtonData!.targetPage = '';
                this.bannerButtonData!.targetPageSectionId = '';
            } else if (value === ButtonActionType.NavigateTo) {
                this.bannerButtonData!.selectedVariantId = null;
            }
            this.updateJsonString();
        }
    };

    private onSelectedVariantChange = (value: ProductVariant) => {
        if (this.bannerButtonData?.actionType !== ButtonActionType.AddVariantToCart) {
            return;
        }
        const selectedVariantId = value ? value.id : '';
        if (this.bannerButtonData?.selectedVariantId !== selectedVariantId) {
            this.bannerButtonData!.selectedVariantId = selectedVariantId;
            this.updateJsonString();
        }
    };

    private onNavigateTypeChange = (value: ButtonNavigationType) => {
        if (this.bannerButtonData?.navigateType !== value) {
            this.bannerButtonData!.navigateType = value;
            if (value === ButtonNavigationType.NavigateToProduct) {
                this.bannerButtonData!.targetCollectionId = null;
                this.bannerButtonData!.targetPage = '';
                this.bannerButtonData!.targetPageSectionId = '';
            } else if (value === ButtonNavigationType.NavigateToCollection) {
                this.bannerButtonData!.targetProductId = null;
                this.bannerButtonData!.targetPage = '';
                this.bannerButtonData!.targetPageSectionId = '';
            } else if (value === ButtonNavigationType.NavigateToPage) {
                this.bannerButtonData!.targetProductId = null;
                this.bannerButtonData!.targetCollectionId = null;
            } else if (value === ButtonNavigationType.NavigateToWebsite) {
                this.bannerButtonData!.targetProductId = null;
                this.bannerButtonData!.targetCollectionId = null;
            }
            this.updateJsonString();
        }
    };

    private onTargetProductChange = (value: Product) => {
        if (
            this.bannerButtonData?.actionType !== ButtonActionType.NavigateTo ||
            this.bannerButtonData?.navigateType !== ButtonNavigationType.NavigateToProduct
        ) {
            return;
        }
        const targetProductId = value ? value.id : null;
        if (this.bannerButtonData?.targetProductId !== targetProductId) {
            this.bannerButtonData!.targetProductId = targetProductId;
            this.updateJsonString();
        }
    };

    private onTargetCollectionChange = (value: Collection) => {
        if (
            this.bannerButtonData?.actionType !== ButtonActionType.NavigateTo ||
            this.bannerButtonData?.navigateType !== ButtonNavigationType.NavigateToCollection
        ) {
            return;
        }
        const targetCollectionId = value ? value.id : null;
        if (this.bannerButtonData?.targetCollectionId !== targetCollectionId) {
            this.bannerButtonData!.targetCollectionId = targetCollectionId;
            this.updateJsonString();
        }
    };

    private onTargetPage = (value: string) => {
        if (this.bannerButtonData?.targetPage !== value) {
            this.bannerButtonData!.targetPage = value;
            this.updateJsonString();
        }
    };

    private onTargetSectionChange = (value: string) => {
        if (this.bannerButtonData?.targetPageSectionId !== value) {
            this.bannerButtonData!.targetPageSectionId = value;
            this.updateJsonString();
        }
    };

    private updateJsonString() {
        const jsonString = JSON.stringify(this.bannerButtonData);
        this.formControl.setValue(jsonString);
        this.formControl.markAsDirty();
    }
}
