// create-store-site-form.component.ts
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '@vendure/admin-ui/core';
import { Dialog } from '@vendure/admin-ui/core';

@Component({
    selector: 'vdr-create-store-site-dialog',
    template: `
    <form [formGroup]="form">
        <vdr-form-field label="Name">
            <input type="text" formControlName="name" />
        </vdr-form-field>
        <vdr-form-field label="Identifier">
            <input type="text" formControlName="identifier" />
        </vdr-form-field>
    </form>
    <ng-template vdrDialogButtons>
        <button type="button" class="btn" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="submit()" [disabled]="form.invalid">Create</button>
    </ng-template>
    `,
    standalone: true,
    imports: [SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateStoreSiteDialogComponent implements Dialog<{ name: string; identifier: string }> {
  form: FormGroup;
  resolveWith: (result?: { name: string; identifier: string }) => void;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      identifier: ['', Validators.required],
    });
  }

  cancel() {
    this.resolveWith();
  }

  submit() {
    if (this.form.valid) {
      this.resolveWith(this.form.value);
    }
  }
}