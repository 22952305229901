import { registerPageTab } from '@vendure/admin-ui/core';
import { AddVideoTabComponent } from './components/add-video-tab/add-video-tab.component';

export default [
    registerPageTab({
        location: 'asset-list',
        tab: 'Add video',
        route: 'add-video',
        component: AddVideoTabComponent,
    }),
];

