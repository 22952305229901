import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../../common/ui/utils';

// Define the interface for policy content data
interface PolicyContentData {
  type: string;
  title: string;
  path: string;
  content: string;
}

@Component({
    selector: 'policy-content-form',
    template: `
    <vdr-card>
      <!-- Policy Type -->
      <vdr-form-field [label]="'Policy Type'">
        <select [formControl]="typeControl">
          <option value="return-policy">Return Policy</option>
          <option value="shipping-policy">Shipping Policy</option>
          <option value="payment-policy">Payment Policy</option>
          <option value="term-of-service">Term of Service</option>
          <option value="privacy-policy">Privacy Policy</option>
          <option value="cookie-policy">Cookie Policy</option>
          <option value="ca-transparency-supply-chains-act">California Transparency in Supply Chains ACT</option>
          <option value="accessibility">Accessibility</option>
          <option value="disclaimer">Disclaimer</option>
          <option value="copyright-notice">Copyright Notice</option>
          <option value="other">Other Policy</option>
        </select>
      </vdr-form-field>

      <!-- URL Path (editable if 'other', read-only otherwise) -->
      <vdr-form-field [label]="'Policy Path (URL)'" *ngIf="isPolicyType">
        <input *ngIf="typeControl.value === 'other'; else readonlyInput" type="text" [formControl]="pathControl">
        <ng-template #readonlyInput>
            <input *ngIf="typeControl.value !== 'other'" type="text" [formControl]="pathControl" readonly>
        </ng-template>
      </vdr-form-field>

      <!-- Policy Title -->
      <vdr-form-field [label]="'Policy Title'" *ngIf="isPolicyType">
        <input type="text" [formControl]="titleControl">
      </vdr-form-field>

      <!-- Policy Content -->
      <vdr-form-field>
        <vdr-rich-text-editor
          [label]="'Policy Content'"
          [formControl]="contentControl">
        </vdr-rich-text-editor>
      </vdr-form-field>
    </vdr-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class PolicyContentFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
    @Input() readonly: boolean;
    @Input() formControl: FormControl;
    @Input() config: any;

    // Form controls
    typeControl = new FormControl();
    titleControl = new FormControl();
    pathControl = new FormControl();
    contentControl = new FormControl();

    policyContentData: PolicyContentData | null;

    get isPolicyType(): boolean {
        return this.typeControl.value !== 'disclaimer' && this.typeControl.value !== 'copyright-notice';
    }

    constructor(private notificationService: NotificationService) {}

    ngOnInit() {
        const defaultPolicyContentData: PolicyContentData = {
            type: '',
            title: '',
            path: '',
            content: '',
        };
        this.policyContentData = safeJSONParse<PolicyContentData>(
            this.formControl.value,
            this.notificationService,
            defaultPolicyContentData,
        );
        this.initializeFormControls();
    }

    ngAfterViewInit() {
        // Subscribe to form control value changes and update policyContentData accordingly
        this.typeControl.valueChanges.subscribe(this.onTypeChange);
        this.titleControl.valueChanges.subscribe(this.onTitleChange);
        this.pathControl.valueChanges.subscribe(this.onPathChange);
        this.contentControl.valueChanges.subscribe(this.onContentChange);
    }

    private initializeFormControls() {
        // Set initial values for the form controls from the policyContentData
        this.typeControl.setValue(this.policyContentData?.type);
        this.titleControl.setValue(this.policyContentData?.title);
        this.pathControl.setValue(this.policyContentData?.path);
        this.contentControl.setValue(this.policyContentData?.content);
    }

    // Handlers for value changes
    private onTypeChange = (value: string) => {
        if (this.policyContentData?.type !== value) {
            this.policyContentData!.type = value;

            // Set path based on policy type
            if (value === 'return-policy') {
                this.pathControl.setValue('return');
            } else if (value === 'shipping-policy') {
                this.pathControl.setValue('shipping');
            } else if (value === 'payment-policy') {
                this.pathControl.setValue('payment');
            } else if (value === 'term-of-service') {
                this.pathControl.setValue('terms');
            } else if (value === 'privacy-policy') {
                this.pathControl.setValue('privacy');
            } else if (value === 'cookie-policy') {
                this.pathControl.setValue('cookie');
            } else if (value === 'ca-transparency-supply-chains-act') {
                this.pathControl.setValue('ca-transparency-supply-chains-act');
            } else if (value === 'accessibility') {
                this.pathControl.setValue('accessibility');
            } else if (value === 'disclaimer' || value === 'copyright-notice') {
                this.pathControl.setValue('');
            } else if (value === 'other') {
                this.pathControl.setValue(this.policyContentData?.path);
            }

            this.updateJsonString();
        }
    };

    private onTitleChange = (value: string) => {
        if (this.policyContentData?.title !== value) {
            this.policyContentData!.title = value;
            this.updateJsonString();
        }
    };

    private onPathChange = (value: string) => {
        if (this.policyContentData?.path !== value) {
            this.policyContentData!.path = value;
            this.updateJsonString();
        }
    };

    private onContentChange = (value: string) => {
        if (this.policyContentData?.content !== value) {
            this.policyContentData!.content = value;
            this.updateJsonString();
        }
    };

    // Synchronize the JSON string with the policyContentData and update formControl
    private updateJsonString() {
        const jsonString = JSON.stringify(this.policyContentData);
        this.formControl.setValue(jsonString);
        this.formControl.markAsDirty();
    }
}
