import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SharedModule, FormInputComponent } from '@vendure/admin-ui/core';
import { LanguageCode } from '../generated-types';
import { DefaultFormComponentConfig } from '@vendure/common/lib/shared-types';
import { Observable } from 'rxjs';

@Component({
  template: `
    <select [formControl]="formControl" [vdrDisabled]="readonly">
      <option *ngIf="config.nullable" [ngValue]="null"></option>
      <option *ngFor="let option of options;trackBy:trackByFn" [ngValue]="option.value">
          {{ option.label || option.value }}
      </option>
    </select>
  `,
  standalone: true,
  imports: [SharedModule],
  styles: [`
  select {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 220px;
  }
  `]
})
export class FulfillmentSelectFormInputComponent implements FormInputComponent, OnInit {
  readonly: boolean;
  config: DefaultFormComponentConfig<'fulfillment-select-form-input'>;
  formControl: UntypedFormControl;
  uiLanguage$: Observable<LanguageCode>;

  constructor() {}

  ngOnInit() { }

  get options() {
    return this.config.ui?.options || this.config.options;
  }

  trackByFn(index: number, item: any) {
      return item.value;
  }
}
