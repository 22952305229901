import { NotificationService } from '@vendure/admin-ui/core';

export function removeDuplicates<T>(array: T[]): T[] {
    const unique = new Set(array);
    return [...unique];
}

export function safeJSONParse<T>(
    jsonString: string,
    notificationService: NotificationService,
    defaultValue?: T
): T | null {
    if (!jsonString) return defaultValue || null;
    try {
        const parsed = JSON.parse(jsonString);

        if (defaultValue) {
            if(typeof parsed === 'object' && parsed !== null) {
                const filteredParsed = Object.keys(defaultValue).reduce((result, key) => {
                    if (key in parsed) {
                        result[key] = (parsed as Record<string, unknown>)[key];
                    }
                    return result;
                }, {} as Partial<T>);
                return Object.assign({}, defaultValue, filteredParsed) as T;
            } else {
                return defaultValue;
            }
        } else {
            return parsed as T;
        }
    } catch (e) {
        notificationService.error(`Failed to parse JSON ${jsonString} - error ${e}`);
        return defaultValue || null;
    }
}
