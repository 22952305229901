import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, FormControl } from '@angular/forms';
import { SharedModule, StringCustomFieldConfig, FormInputComponent, DynamicFormInputComponent, NotificationService, DataService } from '@vendure/admin-ui/core';
import { ID, Asset } from '@vendure/core';
import { Collection } from '../../common/ui/generated-types';
import { forkJoin, Observable, of } from 'rxjs';
import { safeJSONParse } from '../../common/ui/utils';
import { CollectionGroupData, defaultCollectionGroupData } from '../../common/ui/ui-types';

@Component({
  selector: 'vdr-collection-group-input',
  template: `
    <vdr-card>
      <div>
        <!-- Group Name -->
        <vdr-form-field [label]="'Group Name'">
          <input type="text" [formControl]="groupNameControl">
        </vdr-form-field>

        <!-- Title -->
        <vdr-form-field [label]="'Title'">
          <input type="text" [formControl]="titleControl">
        </vdr-form-field>

        <!-- Subtitle -->
        <vdr-form-field [label]="'Subtitle'">
          <input type="text" [formControl]="subtitleControl">
        </vdr-form-field>

        <!-- Description -->
        <vdr-form-field>
          <vdr-rich-text-editor
            [label]="'Description'"
            [formControl]="descriptionControl">
          </vdr-rich-text-editor>
        </vdr-form-field>

        <!-- Background Asset -->
        <vdr-form-field [label]="'Background Asset'">
          <vdr-dynamic-form-input #backgroundAssetInput
            [control]="backgroundAssetControl"
            [def]="{
              type: 'relation',
              entity: 'Asset',
              ui: {
                component: 'relation-form-input',
              }
            }"
          >
          </vdr-dynamic-form-input>
        </vdr-form-field>

        <!-- Featured Assets -->
        <vdr-form-field [label]="'Featured Assets'">
          <vdr-dynamic-form-input #featuredAssetsInput
            [control]="featuredAssetsControl"
            [def]="{
              list: true,
              type: 'relation',
              entity: 'Asset',
              ui: {
                component: 'relation-form-input',
              }
            }"
          >
          </vdr-dynamic-form-input>
        </vdr-form-field>
      </div>

      <!-- Collection List -->
      <vdr-form-field [label]="'Collection List'">
        <vdr-dynamic-form-input #collectionListInput
          [control]="collectionListControl"
          [def]="{
            list: true,
            type: 'relation',
            entity: 'Collection',
            ui: {
              component: 'relation-collection-selector',
            }
          }"
        >
        </vdr-dynamic-form-input>
      </vdr-form-field>
    </vdr-card>
  `,
  standalone: true,
  imports: [SharedModule],
})
export class CollectionGroupInputComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  @ViewChild('collectionListInput') collectionListInput: DynamicFormInputComponent;
  @ViewChild('featuredAssetsInput') featuredAssetsInput: DynamicFormInputComponent;
  @ViewChild('backgroundAssetInput') backgroundAssetInput: DynamicFormInputComponent;
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // Form controls
  collectionListControl = new UntypedFormControl();
  featuredAssetsControl = new UntypedFormControl();
  backgroundAssetControl = new UntypedFormControl();
  groupNameControl = new FormControl();
  titleControl = new FormControl();
  subtitleControl = new FormControl();
  descriptionControl = new FormControl();

  collectionGroupData: CollectionGroupData | null;

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.collectionGroupData = safeJSONParse<CollectionGroupData>(
      this.formControl.value,
      this.notificationService,
      defaultCollectionGroupData,
    );
    this.initializeFormControls();
    if (this.collectionGroupData?.backgroundAssetId) {
      this.dataService.product
        .getAsset(this.collectionGroupData.backgroundAssetId.toString())
        .mapSingle((data) => data.asset)
        .subscribe((asset) => {
          this.backgroundAssetControl.setValue(asset);
        });
    }
    if (this.collectionGroupData && this.collectionGroupData?.featuredAssetIds?.length > 0) {
      this.loadAssetsByIds(this.collectionGroupData.featuredAssetIds).subscribe((assets) => {
        this.featuredAssetsControl.setValue(assets);
        if (this.featuredAssetsInput) {
          this.featuredAssetsInput.writeValue(assets);
        }
      });
    } else {
      this.featuredAssetsControl.setValue([]);
    }
    if (this.collectionGroupData && this.collectionGroupData?.collectionIds?.length > 0) {
      this.loadCollectionsByIds(this.collectionGroupData.collectionIds).subscribe((collections) => {
        this.collectionListControl.setValue(collections);
        if (this.collectionListInput) {
          this.collectionListInput.writeValue(collections);
        }
      });
    } else {
      this.collectionListControl.setValue([]);
    }
  }

  ngAfterViewInit() {
    // Subscribe to value changes
    this.groupNameControl.valueChanges.subscribe(this.onGroupNameChange);
    this.titleControl.valueChanges.subscribe(this.onTitleChange);
    this.subtitleControl.valueChanges.subscribe(this.onSubtitleChange);
    this.descriptionControl.valueChanges.subscribe(this.onDescriptionChange);
    this.backgroundAssetControl.valueChanges.subscribe(this.onBackgroundAssetChange);

    // Handle featured assets and collections using DynamicFormInputComponent
    if (this.featuredAssetsInput) {
      this.featuredAssetsInput.registerOnChange(this.onFeaturedAssetsChange);
    }
    if (this.collectionListInput) {
      this.collectionListInput.registerOnChange(this.onCollectionsChange);
    }
  }

  // Initialize form controls with collectionGroupData values
  private initializeFormControls() {
    this.groupNameControl.setValue(this.collectionGroupData?.groupName);
    this.titleControl.setValue(this.collectionGroupData?.title);
    this.subtitleControl.setValue(this.collectionGroupData?.subtitle);
    this.descriptionControl.setValue(this.collectionGroupData?.description);
  }

  private loadAssetsByIds(ids: ID[]): Observable<Asset[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    }
    return forkJoin(ids.map((id) => this.dataService.product.getAsset(id.toString()).mapSingle((data) => data.asset))) as Observable<Asset[]>;
  }

  private loadCollectionsByIds(ids: ID[]): Observable<Collection[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    }
    return forkJoin(ids.map((id) => this.dataService.collection.getCollections({
      filter: {
        id: { eq: id.toString() }
      },
    }).mapSingle((data) => data.collections?.items[0]))) as Observable<Collection[]>;
  }

  private areArraysEqual(array1: ID[], array2: ID[]): boolean {
    return (
      array1?.length === array2?.length && array1?.every((value) => array2?.includes(value))
    );
  }

  // Value change handlers for form controls
  private onGroupNameChange = (value: string) => {
    if (this.collectionGroupData?.groupName !== value) {
      this.collectionGroupData!.groupName = value;
      this.updateJsonString();
    }
  }

  private onTitleChange = (value: string) => {
    if (this.collectionGroupData?.title !== value) {
      this.collectionGroupData!.title = value;
      this.updateJsonString();
    }
  }

  private onSubtitleChange = (value: string) => {
    if (this.collectionGroupData?.subtitle !== value) {
      this.collectionGroupData!.subtitle = value;
      this.updateJsonString();
    }
  }

  private onDescriptionChange = (value: string) => {
    if (this.collectionGroupData?.description !== value) {
      this.collectionGroupData!.description = value;
      this.updateJsonString();
    }
  }

  private onBackgroundAssetChange = (value: Asset) => {
    const backgroundAssetId = value ? value.id : null;
    if (this.collectionGroupData?.backgroundAssetId !== backgroundAssetId) {
      this.collectionGroupData!.backgroundAssetId = backgroundAssetId;
      this.updateJsonString();
    }
  }

  private onFeaturedAssetsChange = (featuredAssets: Asset[]) => {
    const featuredAssetIds = featuredAssets.map((asset) => asset.id);
    if (!this.areArraysEqual(this.collectionGroupData?.featuredAssetIds||[], featuredAssetIds)) {
      this.collectionGroupData!.featuredAssetIds = featuredAssetIds;
      this.updateJsonString();
    }
  }

  private onCollectionsChange = (collections: Collection[]) => {
    const collectionIds = collections.map((collection) => collection.id);
    if (!this.areArraysEqual(this.collectionGroupData?.collectionIds||[], collectionIds)) {
      this.collectionGroupData!.collectionIds = collectionIds;
      this.updateJsonString();
    }
  };

  // Update JSON string and form control value
  private updateJsonString() {
    const jsonString = JSON.stringify(this.collectionGroupData);
    this.formControl.setValue(jsonString);
    this.formControl.markAsDirty();
  }
}
