import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import {
    SharedModule,
    CustomDetailComponent,
    GetProductVariantDetailQuery,
} from '@vendure/admin-ui/core';


@Component({
    template: `
    <vdr-card title="Variant Channels" class="channel-card">
        <div *ngIf="entity$ | async as productVariant">
            <ul>
                <li *ngFor="let channel of productVariant?.channels">
                    <strong>{{ channel.code }}</strong>
                </li>
            </ul>
        </div>
    </vdr-card>
    `,
    standalone: true,
    imports: [SharedModule],
    styles: [`
        .channel-card {
            margin-bottom: 20px;
        }
    `]
})

export class ProductVariantChannelsComponent implements CustomDetailComponent {
    entity$: Observable<NonNullable<GetProductVariantDetailQuery['productVariant']>>;
    detailForm: FormGroup;
}
