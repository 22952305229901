import { Component, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dialog, SharedModule, DataService, NotificationService } from '@vendure/admin-ui/core';
import { CREATE_VIMEO_ASSET } from '../../ui-types';

@Component({
  selector: 'add-vimeo-video',
  templateUrl: './add-vimeo-video.component.html',
  styleUrls: ['./add-vimeo-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [SharedModule],
})
export class AddVimeoVideoComponent implements Dialog<{ title: string; shareLink: string; }> {
  vimeoForm: FormGroup;
  resolveWith: (result?: { title: string; shareLink: string; }) => void;
  inFlight = false;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private notificationService: NotificationService
  ) {
    this.vimeoForm = this.formBuilder.group({
      videoUrl: ['', [Validators.required, Validators.pattern(/^https:\/\/(www\.)?vimeo\.com\/\d+$/)]],
    });
  }

  onCancel(): void {
    this.resolveWith();
  }

  onSave(): void {
    if (this.vimeoForm.valid && !this.inFlight) {
      this.inFlight = true;
      const { videoUrl } = this.vimeoForm.value;
      this.dataService.mutate(CREATE_VIMEO_ASSET, {
        videoUrl
      }).subscribe(
        (result: any) => {
          console.log('[Create Vimeo Video] result:', result);
          this.notificationService.success('Created new Vimeo video');
          this.inFlight = false;
          this.resolveWith(result.createVimeoAsset);
        },
        (err: any) => {
          this.inFlight = false;
          this.notificationService.error('Failed to create Vimeo video');
          console.error('Error creating Vimeo video:', err);
        }
      );
    }
  }
}
