import { registerHistoryEntryComponent, registerFormInputComponent, addNavMenuItem } from '@vendure/admin-ui/core';
import { ReturnRequestEntryComponent } from './components/return-request-history-entry.component';
import { Permission } from './gql/generated';
import { ReturnCancelPolicyInputComponent } from './components/return-cancel-policy.component';

export default [
    registerHistoryEntryComponent({
        type: 'RETURN_REQUEST',
        component: ReturnRequestEntryComponent,
    }),
    addNavMenuItem({
        id: 'returns',
        label: 'Return Requests',
        routerLink: ['/extensions/returns'],
        icon: 'bundle',
        requiresPermission: Permission.UpdateOrder
    },
    'sales'),
    registerFormInputComponent('return-cancel-policy-form', ReturnCancelPolicyInputComponent),
];