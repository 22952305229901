import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringCustomFieldConfig, SharedModule, FormInputComponent, NotificationService } from '@vendure/admin-ui/core';
import { safeJSONParse } from '../../common/ui/utils';

// Define an interface for the color configuration object
interface ColorConfig {
  mainColor: string;
  secondaryColor: string;
  headerBackgroundColor: string;
  headerFontColor: string;
  footerBackgroundColor: string;
  footerFontColor: string;
}

@Component({
  template: `
    <vdr-card>
      <div class="form-row">
        <!-- Main Color -->
        <vdr-form-field [label]="'Main Color'" class="form-item">
          <input type="color" [formControl]="mainColorControl">
        </vdr-form-field>

        <!-- Secondary Color -->
        <vdr-form-field [label]="'Secondary Color'" class="form-item">
          <input type="color" [formControl]="secondaryColorControl">
        </vdr-form-field>
      </div>

      <div class="form-row">
        <!-- Header Background Color -->
        <vdr-form-field [label]="'Header Background Color'" class="form-item">
          <input type="color" [formControl]="headerBackgroundColorControl">
        </vdr-form-field>

        <!-- Header Font Color -->
        <vdr-form-field [label]="'Header Font Color'" class="form-item">
          <input type="color" [formControl]="headerFontColorControl">
        </vdr-form-field>
      </div>

      <div class="form-row">
        <!-- Footer Background Color -->
        <vdr-form-field [label]="'Footer Background Color'" class="form-item">
          <input type="color" [formControl]="footerBackgroundColorControl">
        </vdr-form-field>

        <!-- Footer Font Color -->
        <vdr-form-field [label]="'Footer Font Color'" class="form-item">
          <input type="color" [formControl]="footerFontColorControl">
        </vdr-form-field>
      </div>
    </vdr-card>
  `,
  styleUrls: ['../../common/ui/styles.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class ColorConfigFormComponent implements FormInputComponent<StringCustomFieldConfig>, OnInit, AfterViewInit {
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;

  // JSON string and object
  jsonString: string;
  colorConfig: ColorConfig | null;

  // Form controls for each color field
  mainColorControl = new FormControl();
  secondaryColorControl = new FormControl();
  headerBackgroundColorControl = new FormControl();
  headerFontColorControl = new FormControl();
  footerBackgroundColorControl = new FormControl();
  footerFontColorControl = new FormControl();

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    // Default color values
    const defaultColorConfig: ColorConfig = {
      mainColor: '#ffffff',
      secondaryColor: '#cccccc',
      headerBackgroundColor: '#ffffff',
      headerFontColor: '#000000',
      footerBackgroundColor: '#ffffff',
      footerFontColor: '#000000',
    };
    this.colorConfig = safeJSONParse<ColorConfig>(
      this.formControl.value,
      this.notificationService,
      defaultColorConfig,
    );
    // Initialize form controls with values from colorConfig
    this.initializeFormControls();
  }

  ngAfterViewInit() {
    // Subscribe to form control value changes and update colorConfig accordingly
    this.mainColorControl.valueChanges.subscribe(this.onMainColorChange);
    this.secondaryColorControl.valueChanges.subscribe(this.onSecondaryColorChange);
    this.headerBackgroundColorControl.valueChanges.subscribe(this.onHeaderBackgroundColorChange);
    this.headerFontColorControl.valueChanges.subscribe(this.onHeaderFontColorChange);
    this.footerBackgroundColorControl.valueChanges.subscribe(this.onFooterBackgroundColorChange);
    this.footerFontColorControl.valueChanges.subscribe(this.onFooterFontColorChange);
  }

  // Initialize form controls with values from colorConfig
  private initializeFormControls() {
    this.mainColorControl.setValue(this.colorConfig?.mainColor);
    this.secondaryColorControl.setValue(this.colorConfig?.secondaryColor);
    this.headerBackgroundColorControl.setValue(this.colorConfig?.headerBackgroundColor);
    this.headerFontColorControl.setValue(this.colorConfig?.headerFontColor);
    this.footerBackgroundColorControl.setValue(this.colorConfig?.footerBackgroundColor);
    this.footerFontColorControl.setValue(this.colorConfig?.footerFontColor);
  }

  // Handlers for value changes
  private onMainColorChange = (value: string) => {
    if (this.colorConfig?.mainColor !== value) {
      this.colorConfig!.mainColor = value;
      this.updateJsonString();
    }
  };

  private onSecondaryColorChange = (value: string) => {
    if (this.colorConfig?.secondaryColor !== value) {
      this.colorConfig!.secondaryColor = value;
      this.updateJsonString();
    }
  };

  private onHeaderBackgroundColorChange = (value: string) => {
    if (this.colorConfig?.headerBackgroundColor !== value) {
      this.colorConfig!.headerBackgroundColor = value;
      this.updateJsonString();
    }
  };

  private onHeaderFontColorChange = (value: string) => {
    if (this.colorConfig?.headerFontColor !== value) {
      this.colorConfig!.headerFontColor = value;
      this.updateJsonString();
    }
  };

  private onFooterBackgroundColorChange = (value: string) => {
    if (this.colorConfig?.footerBackgroundColor !== value) {
      this.colorConfig!.footerBackgroundColor = value;
      this.updateJsonString();
    }
  };

  private onFooterFontColorChange = (value: string) => {
    if (this.colorConfig?.footerFontColor !== value) {
      this.colorConfig!.footerFontColor = value;
      this.updateJsonString();
    }
  };

  // Update the JSON string and mark the form as dirty
  private updateJsonString() {
    this.jsonString = JSON.stringify(this.colorConfig);
    this.formControl.setValue(this.jsonString);
    this.formControl.markAsDirty();
  }
}
