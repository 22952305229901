import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TAX_CODES } from '../tax_codes_constant';
import { IntCustomFieldConfig, SharedModule, FormInputComponent } from '@vendure/admin-ui/core';

@Component({
  selector: 'tax-code-input',
  template: `
    <form [formGroup]="form" (ngSubmit)="verifyTaxCode()">
      <div>
        <!--div class="search-inputs">
          <label for="typeFilter">Type</label>
          <select id="typeFilter" formControlName="typeFilter" (change)="searchTaxCodes()">
            <option value="physical">Physical</option>
            <option value="digital">Digital</option>
            <option value="services">Services</option>
          </select>
        </div-->
        <div>
          <label for="searchText">Please input a keyword(at least 3 letters) to find your Tax Code</label>
          <input id="searchText" formControlName="searchText" (input)="searchTaxCodes()" type="text" />
        </div>
      </div>
      <div *ngIf="filteredTaxCodes.length > 0">
        <ul>
          <li *ngFor="let code of filteredTaxCodes" (click)="selectTaxCode(code)">
            <strong>{{ code.id }}</strong> - [{{code.name}}] - {{ code.description }}
          </li>
        </ul>
      </div>
      <div *ngIf="!formControl.value || (filteredTaxCodes.length === 0 && form.get('searchText')?.value.length >= 3 && !taxCodeSelected) ">
        Defaulting tax-code:"Physical Goods" (txcd_99999999).
        <ul>
          <li (click)="selectTaxCode(defaultTaxCode)">
            <strong>{{ defaultTaxCode.id }}</strong> - [{{defaultTaxCode.name}}] - {{ defaultTaxCode.description }}
          </li>
        </ul>
      </div>
      <div>
        <label for="taxCode">Selected Tax Code</label>
        <div class="tax-code-display">
          <input id="taxCode" formControlName="taxCode" type="text" readonly />
          <button type="button" class="border remove-button" (click)="clearTaxCode()">
            remove
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div *ngIf="taxCodeInfo">
      <table>
        <tr>
          <th>Tax Code</th>
          <td>{{ taxCodeInfo.id }}</td>
        </tr>
        <tr>
          <th>Category Name</th>
          <td>{{ taxCodeInfo.name }}</td>
        </tr>
        <tr>
          <th>Use This Tax Code For</th>
          <td>{{ taxCodeInfo.description }}</td>
        </tr>
        <tr>
          <th>Category Type</th>
          <td>{{ taxCodeInfo.type }}</td>
        </tr>
      </table>
    </div>
  `,
  standalone: true,
  imports: [SharedModule],
  styles: [`
    .error-message {
      color: red;
    }
    table {
      margin-top: 10px;
      border-collapse: collapse;
      width: 100%;
    }
    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }
    th {
      background-color: #f2f2f2;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      cursor: pointer;
      padding: 5px;
      border: 1px solid #ddd;
      margin-bottom: 5px;
    }

    li:hover {
      background-color: #f0f0f0;
    }

    .tax-code-display {
      display: flex;
      align-items: center;
    }

    .remove-button {
      background: white;
      border: 2px solid;
      color: black;
      cursor: pointer;
      margin-left: 10px;
    }

    .remove-button:hover {
      background: grey;
      color: white;
    }

    .remove-button:focus {
      outline: none;
    }

    .search-container {
      display: flex;
      flex-direction: column;
    }

    .search-inputs {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    #searchText {
      flex: 1;
      margin-right: 10px;
    }

    #typeFilter {
      flex: 0;
    }
    `]
})
export class TaxCodeInputComponent implements FormInputComponent<IntCustomFieldConfig>, OnInit {
  readonly: boolean;
  config: IntCustomFieldConfig;
  formControl: FormControl;
  taxCodeInfo: any;
  form: FormGroup;
  filteredTaxCodes: any[] = [];
  defaultTaxCode = {
    id: 'txcd_99999999',
    description: 'A physical good that can be moved or touched. Also known as tangible personal property.',
    name: 'Physical Goods',
    type: 'physical'
  };
  errorMessage: string = '';
  taxCodeSelected: boolean = false;
  selectedType: string = 'physical';

  ngOnInit(): void {
    this.form = new FormGroup({
      searchText: new FormControl(''),
      taxCode: new FormControl(this.formControl.value, Validators.required),
      //typeFilter: new FormControl('physical')
    });

    if (this.formControl.value) {
      this.verifyTaxCode();
    } else {
      this.errorMessage = 'Please input a valid tax code.';
    }
  }

  getFormControl(key: string): FormControl {
    return this.form.get(key) as FormControl;
  }

  searchTaxCodes() {
    this.taxCodeSelected = false;
    const searchText = this.form.get('searchText')?.value.toLowerCase();
    //const typeFilter = this.form.get('typeFilter')?.value;
    if (!searchText || searchText.length < 3) {
      this.filteredTaxCodes = [];
      return;
    }
    this.filteredTaxCodes = TAX_CODES.filter(tc =>
      (tc.description.toLowerCase().includes(searchText) || tc.name.toLowerCase().includes(searchText))
    );
  }

  selectTaxCode(code: any) {
    this.taxCodeSelected = true;
    this.form.get('taxCode')?.setValue(code.id);
    this.filteredTaxCodes = [];
    this.verifyTaxCode();
  }

  clearTaxCode() {
    this.form.get('taxCode')?.setValue('');
    this.taxCodeInfo = null;
    this.errorMessage = 'Please input a valid tax code.';
    this.taxCodeSelected = false;
    this.formControl.setValue('');
    this.formControl.markAsDirty();
  }

  verifyTaxCode() {
    this.errorMessage = '';
    this.taxCodeInfo = null;
    const taxCode = this.form.get('taxCode')?.value;

    if (!taxCode) {
      this.errorMessage = 'Tax code is required';
      return;
    }

    const retrieved_tax_code = TAX_CODES.find(tc => tc.id === taxCode);

    if (!retrieved_tax_code) {
      this.errorMessage = `Invalid tax code: ${taxCode}`;
      return;
    } else {
      this.taxCodeInfo = retrieved_tax_code;
      if(this.taxCodeSelected) {
        this.formControl.setValue(taxCode);
        this.formControl.markAsDirty();
      }
    }
  }
}