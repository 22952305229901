import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomFieldControl, DataService, SharedModule } from '@vendure/admin-ui/core';
import { Observable } from 'rxjs';
import { Collection } from '@vendure/core';

@Component({
    selector: 'relation-collection-selector',
    template: `
    <div class="flex items-center">
        <div *ngIf="formControl.value as collection" class="mr-2">
            <a [routerLink]="['/catalog', 'collections', collection.id]">
                <vdr-chip>{{ collection.name }}</vdr-chip>
            </a>
        </div>
        <select class="mt-1" [formControl]="formControl">
            <option [ngValue]="null">Select a collection...</option>
            <option *ngFor="let item of collections$ | async" [ngValue]="item">
                {{ item.name }} {{ item.parent ? '(Child of ' + item.parent.name + ')' : '' }}
            </option>
        </select>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class RelationCollectionSelectorComponent implements OnInit, CustomFieldControl {
    @Input() readonly: boolean;
    @Input() formControl: FormControl;
    @Input() config: any;

    collections$: Observable<Collection[]>;

    constructor(private dataService: DataService) {}

    ngOnInit() {
        this.collections$ = this.dataService.collection
            .getCollections()
            .mapSingle((data: any) => data?.collections.items);
    }
}